<template>
  <main class="main-content content bgc-grey-200">
    <!-- ログインチェック -->
    <component-login-checker
      ref="loginChecker"
      @onCheckLoginContinueSuccess="onCheckLoginSuccess"
      @onCheckLoginContinueError="onCheckLoginError"
    />
    <component-login-checker-local
      ref="loginCheckerForLocal"
      @onCheckLoginContinueSuccess="onCheckLoginSuccess"
      @onCheckLoginContinueError="onCheckLoginError"
    />
    <!-- ダイアログの定義 -->
    <component-dialog-confirm
      ref="dlg_confirm"
      @onClickYes="onDialogConfirmYes"
      @onClickNo ="onDialogConfirmNo"
    />
    <component-dialog-period-selection
      ref="dlg_period_selection"
      @onClickYes="onDialogPeriodSelectionYes"
    />
    <component-dialog-message
      ref="dlg_message"
      @onClickYes="onDialogMessageYes"
      @onClickNo ="onDialogMessageNo"
    />

    <!-- 画面項目 -->
    <div class="useful">
      <!-- タイトル行 -->
      <div class="row">
        <!-- タイトル -->
        <div class="d-flex flex-row bd-highlight mb-3 align-items-center">
          <img :src="logo.logo_url" alt="Boxロゴ" class="img-fluid c-img display:flex p-2 bd-highlight" />
          <div class="p-2 bd-highlight">
            <h4 class="c-grey-900 mt-4 display:flex" data-e2e="page_title_name">アクセス権一括編集</h4>
          </div>
        </div>

        <!-- 戻る -->
        <div class="col-12 col-lg-auto ml-xl-auto d-flex align-items-center">
          <router-link role="button" id="ButtonReturn" to="/box/AccessList" class="btn btn-outline-info btn-sm btn-width bg-white">
            アクセス権一覧へ戻る
          </router-link>
        </div>
      </div>

      <!-- エラーメッセージ -->
      <div>
        <h4 style="color: #CC0000 ; padding-top: 8px; padding-left: 20px;">
          <div v-for="msg in messages" :key="msg">
            {{ msg }}
          </div>
        </h4>
      </div>

      <!-- デバッグ用：フォルダID、フォルダ名 -->
      <!-- <div class="textbox mb-4" data-e2e="textbox_block">
        <input v-model="args.id" type="text" size="35" />
      </div> -->

      <!-- 説明文と制限事項 -->
      <div class="mt-3">
        <div class="alert alert-secondary" role="alert">
          <p data-e2e="role_policy_1">・所有者/共同所有者権限があるフォルダのアクセス権を一括変更できます。</p>
          <p data-e2e="role_policy_2">・コラボ期限が近いユーザのアクセス権を任意の期間で一括延長できます。</p>
        </div>
      </div>

      <!-- コマンド（ボタン） -->
      <div class="card border-0 m-0 mb-3 mb-30px">
        <div class="card-body">
          <div class="row align-items-start align-items-lg-stretch flex-lg-nowrap">

            <!-- プルダウン「権限選択」-->
            <div class="col-12 col-lg-auto">
              <select class="form-control-sm" v-model="auth_info.selected"
                :disabled="disables.PulldownBulkEdit"
              >
                <template v-for="(arr, cnt) in auth_info.items" :value="arr">
                  <option v-for="(val, key, idx) in arr" :key="idx" :value="val"
                  >{{ key }}</option>
                  <hr :key="cnt" v-if="cnt != (auth_info.items.length - 1)"/>
                </template>
              </select>
            </div>

            <!-- 一括編集 -->
            <div class="col-12 col-lg-auto">
              <a
                id="ButtonBulkEdit"
                class="btn btn-info link-btn btn-sm"
                target="_blank"
                role="button"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-3" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                v-on:click="onClickButtonBulkEdit()"
              >一括編集</a>
            </div>

            <!-- 権限延長 -->
            <div class="col-12 col-lg-auto">
              <a
                id="ButtonExtensionOfAuthority"
                class="btn btn-info link-btn btn-sm"
                target="_blank"
                role="button"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-3" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                v-on:click="onClickButtonExtensionOfAuthority()"
              >権限延長</a>
            </div>

          </div>
        </div>
      </div>

      <!-- 処理状況一覧 -->
      <div class="row">
        <div class="col-md-12">
          <div class="bgc-white bd bdrs-3 p-20 mB-20">

            <!-- class="table table-striped table-bordered table-hover text-break" -->
            <table
              id="dataTable"
              class="table table-striped table-bordered table-hover text-break"
              aria-hidden="true"
            >
              <thead>
                <tr class="thead-light">
                  <th scope="col" v-for="(item, idx) in this.csv_info.columns" :key="idx" :value="val"
                    :hidden="item.visible != 'true'"
                  >{{ item.name }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(rec, r_idx) in csv_info.rows" :key="r_idx" >
                  <td v-for="(fld, c_idx) in rec" :key="c_idx"
                    :hidden="csv_info.columns[c_idx].visible != 'true'"
                  >
                    <div v-if="csv_info.columns[c_idx].type == 'checkbox'">
                      <input type="checkbox" v-on:change="onChangeGridRowSelect(r_idx)" v-bind:checked="fld == 'true'"/>
                    </div>
                    <div v-else>{{ fld }}</div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <!-- 処理状況一覧 -->

    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';
import * as $ from 'jquery';

import LoginChecker         from '@/pages/box/common/LoginChecker.vue';
import LoginCheckerForLocal from '@/pages/box/common/LoginCheckerForLocal.vue';

import DialogConfirm         from '@/pages/box/dialogs/DialogConfirm.vue';
import DialogMessage         from '@/pages/box/dialogs/DialogMessage.vue';
import DialogPeriodSelection from '@/pages/box/dialogs/DialogPeriodSelection.vue';


export const IS_DELETE = -1;    //pulldownのdelete

export const DialogCall = {
  BulkEdit:   1, //ボタン『一括編集』
  Authority:  2, //ボタン『権限延長』
};

export const ValidateType = {
  SUCCESS: 0,   //成功
  ERR: 1,       //エラー
  WRN: 2,       //警告
};

export default {
  name: 'BoxAccessListEditAuthority',
  mixins: [apiMixin, tooltipMixin],

  components: {
    'component-login-checker': LoginChecker,
    'component-login-checker-local': LoginCheckerForLocal,
    'component-dialog-confirm': DialogConfirm,
    'component-dialog-message': DialogMessage,
    'component-dialog-period-selection': DialogPeriodSelection,
  },

  data() {
    return {
      args: {
        id: this.$route.query.value,
      },
      isLocal: false,
      logo: [],
      messages: [],
 
      auth_info: {
        selected: 0,
        items: [],
      },
 
      data_table: null,
      csv_info: null,

      disables: {
        PulldownBulkEdit: null,
      },
      result_validate: null,

      dlg_confirm_args: {
        title: '確認',
        btn: {
          call: -1,
          caption: {
            yes: 'はい',
            no:  'いいえ',
          },
          active: 'yes',
          messages: [],
        },
        event_exec: {
          no: false,
        },
      },
    }
  },

  mounted() {
    this.checkLogin();
    this.getAuthInfo();
    this.getLogoUrl();
    this.getCSV();
  },

  methods: {
    //-----
    //内部メソッド
    //-----
    //オブジェクトのクローン
    cloneObject: function(target) {
      return JSON.parse(JSON.stringify(target));
    },
    //ログインチェック
    checkLogin: async function() {
      // console.log('AccessList>>checkLogin');
      this.isLocal = await this.getApi(`box/isLocal`);

      if (this.isLocal) {
        // console.log('AccessList>>isLocal: local');
        this.$refs.loginCheckerForLocal.checkLogin();
      } else {
        // console.log('AccessList>>isLocal: other');
        this.$refs.loginChecker.checkLogin();
      }
    },
    // redirectToSelf: function() {
    //   //DataTableを更新しても一覧が正常に更新できないため
    //   //自分自身にリダイレクトして画面を再描画させる
    //   this.$router.go({path: this.$router.currentRoute.path, force: true});
    // },
    redirectToAccessList: function() {
      this.$router.push('AccessList');
    },
    // 表示するロゴのURLを取得
    getLogoUrl: async function () {
      // API共通処理のGET用メソッドを実行
      this.logo = (await this.getApi(`box/getLogoURL`)) || [];
      if (this.logo.length === 0) {
        this.$router.push({ path: '/404' });
      }
    },
    // プルダウン『権限』の一覧
    getAuthInfo: async function() {
      this.auth_info = (await this.getApi('box/getAuthInfoForAccessListEditAuthority')) || [];
    },
    //データテーブルの作成
    // ※コンポーネント化不可（正常に描画されない）
    createDataTable: function(deleteRow = false) {
      if (this.data_table != null) {
        this.data_table.state.clear();
        this.data_table.destroy();
        if (deleteRow) {
          $("#dataTable > tbody > tr").remove();
        }
      }

      let html_menu
        = '<div class="form-inline mb-3">'
        + '<select class="form-control">'
        + '<option>10</option>'
        + '<option>25</option>'
        + '<option>50</option>'
        + '<option>100</option>'
        + '</select>'
        + '<span class="my-1 ml-2">件表示'  //</span>を追加すると行間が広がるため削除している
        + '</div>'
      ;

      let html_search
        = '<div class="input-group js-date-search" data-e2e="list_search">'
        + '_INPUT_'
        + '<div class="input-group-append">'
        + '<span class="input-group-text" >'
        + '<svg class="bi" width="26" height="20" fill="currentColor">'
        + '<use xlink:href="/img/bootstrap-icons.svg#search"/>'
        + '</svg>'
        + '</span>'
        + '</div>'
        + '</div>'
      ;

      setTimeout(() =>
        this.data_table = $('#dataTable').DataTable({
          destroy: true,
          order: [],
          autowidth: false,

          oLanguage: {
            sEmptyTable: '現在処理状況が登録されていません',
            sInfo: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
            sInfoEmpty: ' 0 件中 0 から 0 まで表示',
            sInfoFiltered: '（全 _MAX_ 件より抽出）',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu: html_menu,
            sLoadingRecords: '読み込み中...',
            sProcessing: '処理中...',
            sSearch: html_search,
            sSearchPlaceholder: 'コンテンツ内検索',
            sZeroRecords: '登録された処理状況が0件です。',
            oPaginate: {
              sFirst:    '先頭',
              sLast:     '最終',
              sNext:     '次',
              sPrevious: '前',
            },
          },
          // this.csv_info.columns をもとにカラムの設定ができない
          // 仕方ないので直書きしている
          // ※columnDefs に変数を指定しても設定値が無視される
          // ※列幅に何を指定しても無視される
          columnDefs: [
            {                               //チェックボックス『選択』
              targets: [0],
              width: '1%',
              searchable: false,
              orderable: false,
              className: 'text-center',
            },
            { targets: [1], width: '84%' },  //パス
            { targets: [2], width: '5%' },  //項目名
            { targets: [3], width: '1%'  },  //権限
            { targets: [4], width: '2%'  },  //コラボレータ名
            { targets: [5], width: '5%' },  //コラボレータアドレス
            { targets: [6], width: '1%'  },  //コラボレータ種別
            { targets: [7], width: '1%'  },  //コラボレーション有効期限
            { targets: [8], width: '0px', visible: false }, //項目ID
          ],
        })
        ,0
      );
    },
    getCSV: async function() {
      //データ構造
      this.csv_info = {
        columns: [
          {'name': '選択'                    ,'type': 'checkbox' ,'visible': 'true'  },
          {'name': 'パス'                    ,'type': 'text'     ,'visible': 'true'  },
          {'name': '項目名'                  ,'type': 'text'     ,'visible': 'true'  },
          {'name': '権限'                    ,'type': 'text'     ,'visible': 'true'  },
          {'name': 'コラボレータ名'          ,'type': 'text'     ,'visible': 'true'  },
          {'name': 'コラボレータアドレス'    ,'type': 'text'     ,'visible': 'true'  },
          {'name': 'コラボレータ種別'        ,'type': 'text'     ,'visible': 'true'  },
          {'name': 'コラボレーション有効期限','type': 'text'     ,'visible': 'true'  },
          {'name': '項目ID'                  ,'type': 'text'     ,'visible': 'false' },
        ],
        rows: [],
      };
      
      //axios の仕様で大量の配列が戻ってくると
      //データ型付きの文字列に自動変換されてしまう（回避策なし）、
      //このため、api 側は文字列で返却し、vue で2次元配列に変換している
      let csv = await this.postApi(`box/getCSVForAccessListEditAuthority`, {id: this.args.id});
      //console.log('◆◆' + JSON.stringify(csv));

      let fld = null;
      for (let rec of csv.data.split("\n")) {
        fld = rec.split("\t");
        this.csv_info.rows.push(fld);
      }
      //console.log('★★' + this.csv_info);

      this.createDataTable(true);
    },
    //選択された行を取得
    selectedRows: function() {
      let ret_value = this.csv_info.rows.filter((rec) => rec[0] == 'true');
      return ret_value;
    },
    //バリデーション：画面側
    validate: function(target) {
      this.messages = [];

      if (target.length < 1) {
        this.messages.push('選択してください。');
        return false;
      }

      return true;
    },
    //バリデーション：一括変更（同期で実行）
    execValidateBulkEdit: async function(id, selected, target) { // eslint-disable-line
      let param = {
        record_id: id,  //AccessList で選択したDBのレコードID
        type: selected, //変更後の権限（プルダウンで選択した値）
        csv: target,    //変更対象の一覧
      }
      let ret_validate = (await this.postApi('box/validateBulkEditForAccessListEditAuthority', param));
      return ret_validate.data;

      //   //正常
      //   let ret_value_s = {   // eslint-disable-line
      //     type: ValidateType.SUCCESS,
      //     items: [],
      //   }
      //   //エラー
      //   let ret_value_e = {   // eslint-disable-line
      //     type: ValidateType.ERR,
      //     items: [
      //       {
      //         id: 11,
      //         messages: [
      //         //   'フォルダ「xxx」にコラボレーションを',
      //         //   '操作する権限がありません。',
      //           'フォルダ「xxx」にコラボレーションを操作する権限がありません。',
      //         ],
      //       },
      //     ],
      //   };
      //   //警告
      //   let ret_value_w = {   // eslint-disable-line
      //     type: ValidateType.WRN,
      //     items: [
      //       {
      //         id: 1,
      //         messages: [
      //           'ユーザ「sample@box.com」の権限が',
      //           '変更されていたので編集できませんでした。'
      //         ],
      //       },
      //       {
      //         id: 2,
      //         messages: [
      //           'ユーザ「sample@box.com」の権限が変更されていたので編集できませんでした。',
      //         ],
      //       },
      //       {
      //         id: 3,
      //         messages: [
      //           'コラボレーションは親フォルダ「xxx」から',
      //           '継承されているため、親フォルダのロールが',
      //           '変更されます。'
      //         ],
      //       },
      //       {
      //         id: 4,
      //         messages: [
      //           'コラボレーションは親フォルダ「xxx」から継承されているため、親フォルダのロールが変更されます。',
      //         ],
      //       },
      //     ]
      //   };
      //   return ret_value_s;
      //   return ret_value_e;
      //   return ret_value_w;
    },
    execValidateExtensionOfAuthority: async function(id, period, target) { // eslint-disable-line
      let param = {
        record_id: id,  //AccessList で選択したDBのレコードID
        period: period, //期間延長の値（ダイアログで選択した値）
        csv: target,    //変更対象の一覧
      };
      //console.log('◆◆' + JSON.stringify(param));
      let ret_validate = (await this.postApi('box/validateExtensionOfAuthorityForAccessListEditAuthority', param));
      return ret_validate.data;

    //   //正常
    //   let ret_value_s = {   // eslint-disable-line
    //     type: ValidateType.SUCCESS,
    //     items: [],
    //   }
    //   //エラー
    //   let ret_value_e = {   // eslint-disable-line
    //     type: ValidateType.ERR,
    //     items: [
    //       {
    //         id: 11,
    //         messages: [
    //         //   'フォルダ「xxx」にコラボレーションを',
    //         //   '操作する権限がありません。',
    //           'フォルダ「xxx」にコラボレーションを操作する権限がありません。',
    //         ],
    //       },
    //     ],
    //   };
    //   let ret_value_w = {   // eslint-disable-line
    //     type: ValidateType.WRN,
    //     items: [
    //       { id: 1, messages: ['権限延長できません１',], },
    //       { id: 2, messages: ['権限延長できません２',], },
    //       { id: 3, messages: ['権限延長できません３',], },
    //     ]
    //   };
    //   // let ret_value = ret_value_s;
    //   // let ret_value = ret_value_e;
    //   let ret_value = ret_value_w;
    //   console.log('★★★' + JSON.stringify(ret_value));
    //   return ret_value;
    },
    //処理：一括変更（非同期で実行）
    execBulkEdit: function(id, selected, target) { // eslint-disable-line
      let ids = [];
      if (this.result_validate.type != ValidateType.SUCCESS) {
        for (let item of this.result_validate.items) {
          ids.push(item.id);
        }
      }
      let param = {
        record_id: id,
        type: selected,
        csv: target,
        ids: ids,
      }
      this.csv_info = this.postApi(`box/bulkEditForAccessListEditAuthority`, param);

      this.redirectToAccessList();
    },
    //処理：権限延長（非同期で実行）
    execExtensionOfAuthority: function(id, period, target) { // eslint-disable-line
      let ids = [];
      if (this.result_validate.type != ValidateType.SUCCESS) {
        for (let item of this.result_validate.items) {
          ids.push(item.id);
        }
      }
      let param = {
        record_id: id,
        period: period,
        csv: target,
        ids: ids,
      };
      this.csv_info = this.postApi(`box/extensionOfAuthorityForAccessListEditAuthority`, param);

      this.redirectToAccessList();
    },
    //バリデーションメッセージの削除
    deleteValidateMessages: function() {
      //バリデーションが正常終了している場合
      // → 何もしない
      if (this.result_validate.type == ValidateType.SUCCESS) {
        return;
      }
     
      //警告 or エラーの場合
      // → バリデーションメッセージを削除
      let ids = [];
      for (let item of this.result_validate.items) {
        ids.push(item.id);
      }
      this.postApi(`box/deleteValidateMessagesForAccessListEditAuthority`, {ids: ids});
    },
    //確認ダイアログを表示する
    dlgConfirmShow: function(call) {
      let dlg_args = this.cloneObject(this.dlg_confirm_args);
      dlg_args.call = call;             //呼び出し元
      dlg_args.event_exec.no = true;    //キャンセルされたときのイベントを実行する

      switch(dlg_args.call) {
      case DialogCall.BulkEdit: {//『一括変更』からの呼び出し
          let kwd = (this.auth_info.selected == IS_DELETE) ? '削除' : '編集';
          dlg_args.messages = ['コラボレータの権限を' + kwd + 'してよろしいですか？',];
          break;
        }
      case DialogCall.Authority: {//『権限延長』からの呼び出し
          dlg_args.messages = ['コラボレータの権限を延長してよろしいですか？',];
          break;
        }
      }

      this.$refs.dlg_confirm.show(dlg_args);
    },
    disableControl(name, disabled) {
      //Linkボタンの場合、vuejs の :disabled="変数名" が無視されてしまい、
      //活性／非活性ができないので getElementById で対応
      if (disabled == true) {
        document.getElementById(name).classList.add("disabled");
        return;
      }
      document.getElementById(name).classList.remove("disabled");
    },
    //==============================
    //イベントハンドラ
    //==============================
    //ログインチェックコールバック
    onCheckLoginSuccess: function() {
      return;
    },
    onCheckLoginError: function() {
      return;
    },
    //グリッドのチェクボックス
    onChangeGridRowSelect:function(idx) {
      //トグルスイッチ
      const toggle = {'true': 'false', 'false': 'true'}; 
      this.csv_info.rows[idx][0] = toggle[this.csv_info.rows[idx][0]];
    },
    //------------------------------
    // ボタン『一括編集』
    //------------------------------
    onClickButtonBulkEdit: async function() {
      //ログインチェック
      if (this.isLocal) {
        this.$refs.loginCheckerForLocal.checkLoginContinue();
      } else {
        this.$refs.loginChecker.checkLoginContinue();
      }

      //バリデーション
      let target = this.selectedRows();
      if (!this.validate(target)) {
        return;
      }

      //コントロール非活性
      this.disables.PulldownBulkEdit = true;
      this.disableControl('ButtonBulkEdit', true);
      this.disableControl('ButtonExtensionOfAuthority', true);
      this.disableControl('ButtonReturn', true);
      try {
        //チェック開始
        this.messages = ['一括変更チェック中：権限の変更(または削除)可能かを確認中です。終了するまでお待ちください。'];
        this.result_validate = await this.execValidateBulkEdit(this.args.id, this.auth_info.selected, target);
        //console.log('★★★★★★ result_validate' + this.result_validate);
        this.messages = [];

      } finally {
        //コントロール活性
        this.disables.PulldownBulkEdit = false;
        this.disableControl('ButtonBulkEdit', false);
        this.disableControl('ButtonExtensionOfAuthority', false);
        this.disableControl('ButtonReturn', false);
      }
      //正常終了：ダイアログ『実行確認』表示
      if (this.result_validate.type == ValidateType.SUCCESS) {
        this.dlgConfirmShow(DialogCall.BulkEdit);
        return;
      }

      //エラー、警告 → ダイアログ『メッセージ』表示
      let msgs = [];
      for (let item of this.result_validate.items) {
        msgs.push(item.messages);
      }

      let dlg_args = {
        title: 'ユーザ権限の確認',
        call: DialogCall.BulkEdit,

        type: this.result_validate.type,
        messages: msgs,
        btn: {
          caption: {
            yes: 'ＯＫ',
            no: ((this.result_validate.type == ValidateType.ERR) ? null : 'キャンセル'),
          },
          active: 'yes',
        }
      };
      this.$refs.dlg_message.show(dlg_args);
    },
    //------------------------------
    // ボタン『権限延長』
    //------------------------------
    onClickButtonExtensionOfAuthority: function() {
      //ログインチェック
      if (this.isLocal) {
        this.$refs.loginCheckerForLocal.checkLoginContinue();
      } else {
        this.$refs.loginChecker.checkLoginContinue();
      }

      //バリデーション
      let target = this.selectedRows();
      if (!this.validate(target)) {
        return;
      }

      //ダイアログ『期間選択』表示
      let dlg_args = {
        title: '延長期間の選択',
        btn: {
          caption: {
            yes: 'ＯＫ',
            no:  'キャンセル',
          },
          active: 'yes',
        },
      };
      this.$refs.dlg_period_selection.show(dlg_args);
    },
    //ダイアログ『期間選択』の call back
    onDialogPeriodSelectionYes: async function(args) {
      //ダイアログ『期間選択』の選択値を取得
      this.selected_period = args.selected;

      let target = this.selectedRows();
      
      //コントロール非活性
      this.disables.PulldownBulkEdit = true;
      this.disableControl('ButtonBulkEdit', true);
      this.disableControl('ButtonExtensionOfAuthority', true);
      this.disableControl('ButtonReturn', true);
      try {
        //チェック開始
        this.messages = ['一括変更チェック中：期間の変更が可能かを確認中です。終了するまでお待ちください。'];
        this.result_validate = await this.execValidateExtensionOfAuthority(this.args.id, this.selected_period, target);
        //console.log('★★★★★★ result_validate' + this.result_validate);
        this.messages = [];

      } finally {
        //コントロール活性
        this.disables.PulldownBulkEdit = false;
        this.disableControl('ButtonBulkEdit', false);
        this.disableControl('ButtonExtensionOfAuthority', false);
        this.disableControl('ButtonReturn', false);
      }

      if (this.result_validate.type == ValidateType.SUCCESS) {
        this.dlgConfirmShow(DialogCall.Authority);
        return;
      }

      //エラー、警告 → ダイアログ『メッセージ』表示
      let msgs = [];
      for (let item of this.result_validate.items) {
        msgs.push(item.messages);
      }

      let dlg_args = {
        title: '期間延長の確認',
        call: DialogCall.Authority,

        type: this.result_validate.type,
        messages: msgs,
        btn: {
          caption: {
            yes: 'ＯＫ',
            no: ((this.result_validate.type == ValidateType.ERR) ? null : 'キャンセル'),
          },
          active: 'yes',
        }
      };
      this.$refs.dlg_message.show(dlg_args);
    },
    //------------------------------
    //ダイアログ『メッセージ表示』からのコールバック
    //------------------------------
    onDialogMessageYes: function(args) {
      //エラーの場合 → 何もしない
      if (this.result_validate.type == ValidateType.ERR) {
        return;
      }
      //警告の場合 → 確認ダイアログ
      this.dlgConfirmShow(args.call);
    },
    onDialogMessageNo: function(args) { // eslint-disable-line
      this.deleteValidateMessages();
    },
    //------------------------------
    //ダイアログ『実行確認』からのコールバック
    //------------------------------
    //ボタン『OK』
    onDialogConfirmYes: function(args) {
        //選択されたレコードを取得
        let id = this.args.id;
        let target = this.selectedRows();

        switch(args.call) {
        case DialogCall.BulkEdit:  { this.execBulkEdit(id, this.auth_info.selected, target);  break; }
        case DialogCall.Authority: { this.execExtensionOfAuthority(id, this.selected_period, target); break; }
        }
    },
    //ボタン『キャンセル』
    onDialogConfirmNo: function(args) { // eslint-disable-line
      this.deleteValidateMessages();
    },
  }
};
</script>

<style scoped>
.traning {
  background-color: #2c8fff;
  color: white;
}

.alert-secondary {
  padding-left: 10px;
}

.alert-secondary p {
  padding-left:1em;
  text-indent:-1em;
  margin: 0;
}
</style>

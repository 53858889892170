<template>
  <div>
    <main class="main-content bgc-grey-200 layers top-container">
      <!-- ロゴ -->
      <img class="w-25" src="/images/symphonict-logo-bk-y.png" alt="symphonict" />
      <h2 class="c-grey-900 font-weight-bold ml-5">サービスポータル</h2>

      <div class="masonry-item col-md-6">
        <div class="d-flex justify-content-start mt-4">
          <h4 class="c-grey-900">パスワード再設定</h4>
        </div>

        <div class="bgc-white p-20 bd card">
          <div class="layers">
            <h6 class="c-grey-900 mb-4">本人確認</h6>
          </div>

          <!-- 送信成功メッセージ -->
          <div class="alert alert-success" role="alert" v-if="successMessage">
            {{ successMessage }}
          </div>

          <div class="layers">
            <p>本人確認を行います。 メールアドレスを入力し、「送信する」ボタンを押してください。</p>
          </div>

          <form>
            <!-- メールアドレス -->
            <div class="form-group layers">
              <input
                :class="'form-control w-75 h-75' + (errors && errors.email ? ' is-invalid' : '')"
                type="email"
                id="email"
                placeholder="メールアドレス"
                v-model="user.email"
                ref="email"
              />
              <div class="invalid-feedback w-75">{{ errors.email }}</div>
              <!-- 存在しないエラー -->
              <div class="failedMessage w-75" v-if="failedMessage">
                {{ failedMessage }}
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <router-link class="btn btn-outline-info mr-3 btn-sm btn-width" to="/" role="button">戻る</router-link>
              <button v-if="!isLoading" type="button" class="btn btn-info btn-sm" @click="submit()">
                送信する
              </button>
              <button v-show="isLoading" class="btn btn-info btn-sm" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                送信する…
              </button>
            </div>
          </form>
          <div class="alert alert-secondary" role="alert" style="margin-top:10px;text-align:center;">
            <span class="alert-heading">※「送信する」ボタンを押しても、案内メールを受信しない場合は<a href="https://symphonict.nesic.co.jp/portal/inquiry-form/" target="_blank" rel="noopener">こちら</a>からお問い合わせください。</span>
          </div>
        </div>
      </div>
    </main>

    <!-- フッター -->
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import { apiMixin } from '@/mixins/api.js';

export default {
  name: 'PasswordRecovery',
  mixins: [apiMixin],

  components: {
    Footer,
  },
  data() {
    return {
      constants: [],
      resetLink: '',
      errors: null,
      isLoading: false,
      user: null,
      successMessage: null,
      failedMessage: null,
      emailRegex: null,
      emailMaxLength: null,
    };
  },
  beforeCreate() {},
  created() {
    this.initialization();
    this.getConstant();
  },
  beforeMount() {},
  mounted() {
    this.$refs.email.focus();
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    initialization() {
      this.user = {
        email: '',
      };

      this.errors = {
        email: '',
      };
    },
    // 定数情報を取得
    async getConstant() {
      // API共通処理のGET用メソッドを実行
      this.constants = (await this.getApi('constant')) || [];

      for (const i in this.constants) {
        // email最大桁数
        if (this.constants[i].key == 'emailMaxLength') {
          this.emailMaxLength = Number(this.constants[i].value);
        }
        // email正規表現
        if (this.constants[i].key == 'emailRegex') {
          this.emailRegex = this.constants[i].value;
        }
      }
    },
    // パスワードリセットリンクの送信
    async submit() {
      // バリデーション
      if (this.validater()) {
        await this.postUserEmail();
      } 
    },
    // 入力内容の検証
    validater() {
      this.errors = {
        email: '',
      };
      this.failedMessage = null;
      this.successMessage = null;

      let result = true;

      // メールアドレス
      if (this.user.email.length === 0) {
        result = false;
        this.errors.email = 'メールアドレスを入力してください。';
      } else if (this.user.email.length > this.emailMaxLength) {
        result = false;
        this.errors.email = 'メールアドレスは' + this.emailMaxLength + '文字以内で入力してください。';
      } else if (!RegExp(this.emailRegex).test(this.user.email)) {
        result = false;
        this.errors.email = 'メールアドレスを正しい形式で入力してください。';
      }
      return result;
    },
    // パスワードリセットリンクを送信する
    async postUserEmail() {
      this.isLoading = true;
      // API共通処理（apiMixin）のPOST用メソッドを実行
      const response = (await this.postApi('password/email', this.user)) || { result: false };
      this.isLoading = false;
      if (response.result) {
        this.initialization();
        this.successMessage = 'パスワードリセットメールを送信しました';
      } else if (response.message) {
        this.failedMessage = 'パスワードリセットメールを送信しました';
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
/* 画面全体 */

.top-container {
  width: 100%;
  min-height: calc(100vh - 150px);
}

/* バリデーションエラー */
.failedMessage {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}

.btn-width {
  width: 96px;
}
</style>

<template>
  <div class="wrapper d-flex flex-column h-100 mt-5">
    <main class="d-flex my-auto">
      <div class="container">
        <div class="card mx-auto my-0 mw-640px">
          <div class="card-header text-center bg-gray-100 py-25px py-md-45px">
            <div class="display-3 font-weight-bold mb-0 font-family-futura line-height-8">
              <span class="d-block text-80 text-md-105">404</span>
            </div>
            <p class="font-weight-bold text-14px text-md-20px mb-0px mb-md-10px">ERROR</p>
            <p class="font-weight-bold text-18px text-md-24px mb-0">Page Not Found</p>
          </div>
          <div class="card-body py-25px py-md-40px">
            <h5 class="text-center font-weight-bold text-14px text-md-18px mb-5px mb-md-15px">
              ページが見つかりません
            </h5>
            <p class="card-text text-center text-12px text-md-14px mb-10px mb-md-25px">
              お探しのページは、削除されたか、<br />
              ファイル名が間違っている可能性があります。
            </p>
            <p class="mb-0 text-center">
              <router-link to="/" type="button" class="btn btn-sm btn-info btn-icon-left">
                <!-- svg画像 -->
                <svg class="bi" fill="currentColor">
                  <use xlink:href="/img/bootstrap-icons.svg#house"></use>
                </svg>
                TOPに戻る
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </main>
    <footer class="py-3 py-md-5">
      <div class="container text-center">
        <picture>
          <source media="(min-width: 768px)" srcset="/img/logo_vertical_black.svg" />
          <img
            class="w-200px w-md-100px mw-100 mx-auto mb-10px"
            src="/img/logo_vertical_side_black.svg"
            alt="symphonict"
          />
        </picture>
        <small class="d-block text-muted mb-0 text-10px text-md-12px"
          >© NEC Networks & System Integration Corporation.</small
        >
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: '404',
  beforeCreate() {
    /* GA4 Googleアナリティクス 2021/10 */
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    // 基本値のセット
    var GtagSetArray = {
      'user_id': 'U' + this.$store.getters['auth/id'],
      'user_properties': {
        'company_id': 'C' + this.$store.getters['auth/companyId'],
        'role': 'R' + this.$store.getters['auth/role']
      }
    };

    // 内部アクセス切り替え
    if (window.location.hostname == 'portal-stag.symphonict.com') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_stag';
    } else if (window.location.hostname == 'portal-dev.symphonict.com') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_dev';
    } else if (window.location.hostname == 'localhost') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_local';
    }

    // 送信
    gtag('config', 'G-Y9CV60BH7R', GtagSetArray);
    /* end GA4 Googleアナリティクス 2021/10 */
  },
};
</script>

<template>
  <footer>
    <div v-if="`${this.id}` != ''" class="d-flex align-items-center justify-content-center footer-area-content w-100">
      <div class="d-flex align-items-center copy-right mb-3 footer-fontsize">
        <span style="color: black;"
          >※記載されている会社名、サービス名、商品名などは、各社の商標または登録商標です。</span
        >
      </div>
    </div>

    <div
      v-if="`${this.id}` == ''"
      class="d-flex flex-column align-items-center justify-content-center border-top footer-area-main w-100 footer-height footer-fontsize"
    >
      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
        <img class="img-fluid footer-img mr-2" src="/images/symphonict-logo-w-y.png" />
        <img class="img-fluid footer-img ml-2" src="/images/nesic-logo-w.png" />
      </div>
      <div class="dummy"></div>
      <div class="d-flex flex-column align-items-center copy-right mb-3">
        <span style="color: white;">※記載されている会社名、サービス名、商品名などは、各社の商標または登録商標です。</span>
        <span style="color: white;">©NEC Networks & System Integration Corporation 2020.All rights reserved. </span>
      </div>
    </div>

  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {};
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {},
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
  },
};
</script>

<style scoped>
/* フッター */

.footer-area-main {
  background-color: #000;
}
.footer-area-content {
  background-color: #f2f3f5;
}
.footer-fontsize {
  font-size: 14px;
}
.footer-height {
  height: 150px;
}

/* フッター画像 */

.footer-img {
  width: 40%;
}
</style>

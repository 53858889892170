<template>
    <div></div>
</template>

<script>
//
// local と dev 環境では処理の流れが違うので注意
//
import { apiMixin } from '@/mixins/api.js';
import axios from 'axios';

export default {
    name: 'LoginCheckerForLocal',
    mixins: [apiMixin],

    methods: {
        //-------------------
        //内部メソッド
        //-------------------
        // formatDate: function(date){
        //     let H = ('0'   + date.getHours()).slice(-2);
        //     let M = ('0'   + date.getMinutes()).slice(-2);
        //     let S = ('0'   + date.getSeconds()).slice(-2);
        //     let F = ('000' + date.getMilliseconds()).slice(-3);
        //     return H + ':' + M + ':' + S + '.' + F;
        // },
        // execute: async function(url, onSuccess = null) {
        //     await axios
        //         .get(url, {})
        //         .then((response) => {
        //             const result = response;
        //             console.log('[Local] execute>>success:' + response);

        //             if (onSuccess != null) {
        //                 this.$emit(onSuccess, result);
        //             }
        //         })
        //         .catch((err) => {
        //             console.log('[Local] execute>>error:' + err);
        //             console.log('[Local] execute>>error:' + err.request.responseURL);
        //             console.log('[Local] execute>>error');

        //             const url_temp = err.request.responseURL;
        //             this.redirectTo(url_temp);
        //         });
        // },
    
        //-------------------
        //外部I/F
        //-------------------
        //指定された時間（ミリ秒）スリープさせてから callbackFunction を実行する
        sleep: async function(interval, callbackFunction = null) {
            const sleeper = (st, delay, callback) => new Promise((resolve) => {

                setTimeout(() => {
                    resolve({
                        'delay': delay, 
                        'st': st,
                        'en': new Date(),
                        'callback': callback,
                    });
                }, delay);

            }).then((args) => {
                if (args.callback === null) {
                    return;
                }
                args.callback(args);
            });

            await sleeper(new Date(), interval, callbackFunction);
        },
        //リダイレクトさせる
        redirectTo(url) {
            window.top.location.href = url;
        },
        //すでにログインされているかをチェック
        // ログインされている   ⇒ メニュー画面へ
        // ログインされていない ⇒ ログイン画面へ
        AlreadyLoggedIn: async function(code) {
            // console.log('[Local] AlreadyLoggedIn>>start');
            if (typeof code !== 'undefined') {
                return;
            }

            const url = '/api/box/loginCheckToMenu';
            await axios
                .get(url, {
                    crossDomain: true,
                    xhrFields: { withCredentials: true },
                })
                .then((response) => {   // eslint-disable-line
                    // console.log('[Local] AlreadyLoggedIn>>success:' + response);
                })
                .catch((err) => {
                    // console.log('[Local] AlreadyLoggedIn>>error:' + err);
                    // console.log('[Local] AlreadyLoggedIn>>error:' + err.request.responseURL);
                    // console.log('[Local] AlreadyLoggedIn>>error');
                    this.redirectTo(err.request.responseURL);
                });
        },
        //画面表示時
        createSessionFromDB: async function(code) {
            // console.log('[Local] createSessionFromDB>>start');

            //DBのレコード番号の指定がない場合
            if (typeof code == 'undefined') {
                //セッションの存在チェック
                this.checkLogin();
                return;
            }

            //DBのレコード番号の指定がある場合、DBからセッション作成
            const url = '/api/box/createSessionFromDB?value=' + code;
            await axios
                .get(url, {
                    crossDomain: true,
                    xhrFields: { withCredentials: true },
                })
                .then((response) => {   // eslint-disable-line
                    // console.log('[Local] fromDB>>success:' + response);
                })
                .catch((err) => {
                    const r_url = err.request.responseURL;
                    // console.log('[Local] fromDB>>error:' + err.request.status);
                    // console.log('[Local] fromDB>>error:' + r_url);
                    // console.log('[Local] fromDB>>error');

                    if (r_url.indexOf('box/login') > -1) {
                        this.redirectTo(r_url);
                    }
                });
        },
        checkLogin: async function() {
            const url = '/api/box/loginCheckToLogin';
            await axios.get(url, {
                crossDomain: true,
                xhrFields: { withCredentials: true },
            })
            .then((response) => {   // eslint-disable-line
                // console.log('[Local] toLogin>>success:' + response);
            })
            .catch((err) => {
                const r_url = err.request.responseURL;
                console.log('[Local] toLogin>>error:' + err.request.status);
                console.log('[Local] toLogin>>error:' + r_url);
                console.log('[Local] toLogin>>error');

                if (r_url.indexOf('box/loginCheckToLogin') > -1) {
                    return;
                }
                this.redirectTo(r_url);
            });
        },
        //ログインチェック（ログインチェック後、処理を継続する）
        checkLoginContinue: async function() {
            const url = '/api/box/loginCheckToLogin';
            await axios
                .get(url, {
                    crossDomain: true,
                    xhrFields: { withCredentials: true },
                })
                .then((response) => {
                    const r_url = response.request.responseURL;
                    // console.log('[Local] continue>>success:' + response.status);
                    // console.log('[Local] continue>>success:' + r_url);
                    // console.log('[Local] continue>>success');

                    const result = response;
                    if (r_url.indexOf('box/loginCheckToLogin') > -1) {
                        this.$emit('onCheckLoginContinueSuccess', result);
                        return;
                    }
                    this.redirectTo(r_url);
                })
                .catch((err) => {
                    const r_url = err.request.responseURL;
                    // console.log('[Local] continue>>error:' + err.request.status);
                    // console.log('[Local] continue>>error:' + r_url);
                    // console.log('[Local] continue>>error');

                    this.$emit('onCheckLoginContinueError', err);
                    this.redirectTo(r_url);
                });
      },
    },
}
</script>
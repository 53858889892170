<template>
  <div hidden>box（iframe）から親画面へ遷移する</div>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';

export default {
  name: 'BoxCloseLoginIframe',
  mixins: [apiMixin],

  beforeCreate() {
    //コードが指定された場合
    var code = this.$route.query.value;
    if (code != null) {
      // BoxのメニューができたらBoxのメニューに変更する
      window.top.location.href = '/box/OptionMenu?value=' + code;
    }

    //エラーの場合
    var eroor_code = this.$route.query.error;
    if (eroor_code != null) {
      window.top.location.href = '/box/Login?code=' + eroor_code;
    }
  },
};
</script>

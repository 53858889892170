var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main-content content bgc-grey-200"},[_vm._m(0),(_vm.user)?_c('div',{staticClass:"masonry-item card"},[_c('div',{staticClass:"bgc-white p-20 bd"},[_c('div',{staticClass:"form-group d-flex align-items-center"},[_vm._m(1),_c('div',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.user.company_kanji)+" ")])]),_c('div',{staticClass:"form-group d-flex align-items-center"},[_vm._m(2),_c('div',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.user.department_kanji)+" ")])]),_c('div',{staticClass:"form-group d-flex align-items-center"},[_vm._m(3),_c('div',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")])]),_c('div',{staticClass:"form-group d-flex align-items-center"},[_vm._m(4),_c('div',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])]),_c('div',{staticClass:"form-group d-flex align-items-center"},[_vm._m(5),_c('div',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.user.role)+" ")])]),_c('div',{staticClass:"form-group d-flex align-items-top"},[_vm._m(6),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"flex-row bd-highlight"},_vm._l((_vm.user.apps),function(app){return _c('span',{key:app.id},[(app.id != 13)?_c('img',{staticClass:"img-width",attrs:{"src":app.logo_url,"alt":app.name}}):_vm._e()])}),0)])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"c-grey-900 mt-3",attrs:{"data-e2e":"page_title_name"}},[_vm._v("プロフィール")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-sm-3 col-form-label",attrs:{"for":"name"}},[_c('span',{staticClass:"decoration font-weight-bold",attrs:{"id":"name"}},[_vm._v("会社")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-sm-3 col-form-label",attrs:{"for":"name"}},[_c('span',{staticClass:"decoration font-weight-bold",attrs:{"id":"name"}},[_vm._v("部署")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-sm-3 col-form-label",attrs:{"for":"name"}},[_c('span',{staticClass:"decoration font-weight-bold",attrs:{"id":"name"}},[_vm._v("名前")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-sm-3 col-form-label",attrs:{"for":"email"}},[_c('span',{staticClass:"decoration font-weight-bold",attrs:{"id":"email"}},[_vm._v("メールアドレス")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-sm-3 col-form-label",attrs:{"for":"role"}},[_c('span',{staticClass:"decoration font-weight-bold",attrs:{"id":"role"}},[_vm._v("権限")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-sm-3 col-form-label",attrs:{"for":"cloudService"}},[_c('span',{staticClass:"decoration font-weight-bold",attrs:{"id":"cloudService"}},[_vm._v("アプリ")])])
}]

export { render, staticRenderFns }
<template>
    <main class="main-content content bgc-grey-200">
        <!-- ログインチェック -->
        <component-login-checker
            ref="loginChecker"
        />
        <component-login-checker-local
            ref="loginCheckerForLocal"
        />
        <!-- ダウンローダー -->
        <component-downloader
            ref="downloader"
            @onDownloadCSVError="onDownloadCSVError"
        />
        <!-- ダイアログの定義 -->
        <component-dialog-confirm
            ref="dlg_confirm"
            @onClickYes="onConfirmSelectYes"
        />

        <!-- 画面項目 -->
        <div class="useful">
            <!-- タイトル行 -->
            <div class="row">
                <!-- タイトル -->
                <div class="d-flex flex-row bd-highlight mb-3 align-items-center">
                    <img :src="logo.logo_url" alt="Boxロゴ" class="img-fluid c-img display:flex p-2 bd-highlight" />
                    <div class="p-2 bd-highlight">
                        <h4 class="c-grey-900 mt-4 display:flex" data-e2e="page_title_name">ログの取得</h4>
                    </div>
                </div>
                <!-- 戻る -->
                <div class="col-12 col-lg-auto ml-xl-auto d-flex align-items-center">
                    <router-link role="button" id="ButtonReturn" to="/box/OptionMenu" class="btn btn-outline-info btn-sm btn-width bg-white">
                        オプション機能選択へ戻る
                    </router-link>
                </div>
            </div> <!-- row -->

            <!-- エラーメッセージ -->
            <div>
                <h4 style="color: #CC0000 ; padding-top: 8px; padding-left: 20px;">
                    <div v-for="msg in messages" :key="msg">
                        {{ msg }}
                    </div>
                </h4>
            </div>

            <!-- 説明文と制限事項 -->
            <div class="mt-3">
                <div class="alert alert-secondary" role="alert">
                    <p data-e2e="role_policy_1">・各種オプション機能の実行ログをダウンロードできます。</p>
                    <p data-e2e="role_policy_2">・データ取得状況やエラー発生時の詳細内容がご確認いただけます。</p>
                </div>
            </div>

            <!-- コマンド -->
            <div class="card border-0 m-0 mb-20px p-10px">
                <div class="card-body">
                    <div class="row align-items-start align-items-lg-stretch flex-lg-nowrap">
                        <input type="date" id="day_start" 
                            v-model="days.start.value"
                            v-bind:min="days.start.min"
                            v-bind:max="days.start.max"
                        />
                        <div class="ml-15px mr-15px pt-5px" >～</div>
                        <input type="date" id="day_end" class="mr-20px"
                            v-model="days.end.value"
                            v-bind:min="days.end.min"
                            v-bind:max="days.end.max"
                        />
                        <a
                            id="ButtonExecute"
                            class="btn btn-info link-btn btn-sm"
                            target="_blank"
                            role="button"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-3" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                            v-on:click="onClickButtonExecute()"
                        >実行する
                        </a>

                        <div class="col-12 d-lg-none p-0">
                            <hr />
                        </div>

                        <!-- 更新ボタン -->
                        <ReloadButton ref="ReloadButton" />

                    </div> <!-- row -->
                </div> <!-- card-body -->
            </div> <!-- card -->

            <!-- ログの一覧 -->
            <div class="row">
                <div class="col-md-12">
                    <div class="bgc-white bd bdrs-3 p-20 mB-20">

                        <table
                            id="dataTable"
                            class="table table-striped table-bordered table-hover text-break"
                            aria-hidden="true"
                        >
                            <thead>
                                <tr class="thead-light">
                                    <th scope="col">処理状況</th>
                                    <th scope="col">ログの取得期間</th>
                                    <th scope="col">作成開始</th>
                                    <th scope="col">作成終了</th>
                                    <th scope="col">ダウンロード</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(item, idx) in log_list" :key="idx" :value="item">
                                    <!--処理状況-->
                                    <td>{{item.status}}</td>
                                    <!-- ログの取得期間 -->
                                    <td>{{item.period}}</td>
                                    <!-- 作成開始 -->
                                    <td>{{item.creation_start}}</td>
                                    <!-- 作成終了 -->
                                    <td>{{item.creation_end}}</td>
                                    <!-- ダウンロード -->
                                    <td>
                                        <div
                                            v-bind:hidden="item.can_download == false"
                                            class="td-n c-red-500 cH-blue-500 fsz-md"
                                            style="cursor: pointer"
                                            v-on:click="onClickButtonDownload(idx, item)"
                                        >
                                            <svg class="bi" width="26" height="26" fill="currentColor">
                                                <use xlink:href="/img/bootstrap-icons.svg#download"/>
                                            </svg>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div> <!-- col-md -->
            </div> <!-- row -->

        </div> <!-- useful -->
    </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';
import * as $ from 'jquery';
import axios from 'axios';

import LoginChecker         from '@/pages/box/common/LoginChecker.vue';
import LoginCheckerForLocal from '@/pages/box/common/LoginCheckerForLocal.vue';
import Downloader           from '@/pages/box/common/DownloaderForAxios.vue';
import ReloadButton         from '@/pages/box/common/ReloadButton.vue';

import DialogConfirm from '@/pages/box/dialogs/DialogConfirm.vue';

export default {
    name: 'BoxOperationLog',
    mixins: [apiMixin, tooltipMixin],

    components: {
        'component-login-checker': LoginChecker,
        'component-login-checker-local': LoginCheckerForLocal,
        'component-downloader': Downloader,
        'component-dialog-confirm': DialogConfirm,
        'ReloadButton':ReloadButton,
    },

    data() {
        return {
            isLocal: false,
            messages: [],

            days: {
                start: { value: '', min: '', max: '', },
                end:   { value: '', min: '', max: '', },
            },
            
            log_list: [],

            data_table: null,

            logo: [],
        }
    },

    mounted() {
        this.checkLogin();
        this.getLogoUrl();
        this.initialize();
        this.getLogList();
    },
    updated() {
        this.initTooltip();
    },

    methods: {
        //-----
        //内部メソッド
        //-----
        checkLogin: async function() {
            // console.log('OperationLog>>checkLogin');
            this.isLocal = await this.getApi(`box/isLocal`);

            if (this.isLocal) {
                // console.log('OperationLog>>isLocal: local');
                this.$refs.loginCheckerForLocal.checkLogin();
            } else {
                // console.log('OperationLog>>isLocal: other');
                this.$refs.loginChecker.checkLogin();
            }
        },
        //日付をISO形式（yyyy-mm-dd）にフォーマットして返す
        formatDayISO: function(day) {
            let yy = day.getFullYear();
            let mm = day.getMonth() + 1;
            let dd = day.getDate();
            let result = [
                ('0000' + yy).slice(-4),
                ('0' + mm).slice(-2),
                ('0' + dd).slice(-2),
            ].join('-');

            return result;
        },
        initialize: function() {
            //カレンダーの選択範囲を設定：下限
            let day_ago = new Date();
            day_ago.setDate(day_ago.getDate() - 364);  //今日を含めて 365日(365 から 1日引く)
            this.days.start.min = this.formatDayISO(day_ago);
            this.days.end.min   = this.formatDayISO(day_ago);
            //カレンダーの選択範囲を設定：上限
            let today = new Date();
            this.days.start.max = this.formatDayISO(today);
            this.days.end.max   = this.formatDayISO(today);
        },
        getLogList: async function() {
            // console.log('OperationLog>>getLogList');

            this.log_list = await this.getApi(
                `box/getCsvStatusLogPeriods`
            );
            this.createDataTable();
        },
        createDataTable: function(deleteRow = false) {
            if (this.data_table != null) {
                this.data_table.state.clear();
                this.data_table.destroy();
                if (deleteRow) {
                    $("#dataTable > tbody > tr").remove();
                }
            }

            let html_menu
                = '<div class="form-inline mb-3">'
                + '<select class="form-control">'
                + '<option>10</option>'
                + '<option>25</option>'
                + '<option>50</option>'
                + '<option>100</option>'
                + '</select>'
                + '<span class="my-1 ml-2">件表示'  //</span>を追加すると行間が広がるため削除している
                + '</div>'
            ;
            const html_search
                = '<div class="input-group js-date-search" data-e2e="list_search">'
                + '_INPUT_'
                + '<div class="input-group-append">'
                + '<span class="input-group-text" >'
                + '<svg class="bi" width="26" height="20" fill="currentColor">'
                + '<use xlink:href="/img/bootstrap-icons.svg#search"/>'
                + '</svg>'
                + '</span>'
                + '</div>'
                + '</div>'
            ;

            setTimeout(() =>
                this.data_table = $('#dataTable').DataTable({
                    destroy: true,
                    order: [],
                    autowidth: false,
                    oLanguage: {
                        sEmptyTable: '現在、ログの一覧が登録されていません',
                        sInfo: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
                        sInfoEmpty: ' 0 件中 0 から 0 まで表示',
                        sInfoFiltered: '（全 _MAX_ 件より抽出）',
                        sInfoPostFix: '',
                        sInfoThousands: ',',
                        sLengthMenu: html_menu,
                        sLoadingRecords: '読み込み中...',
                        sProcessing: '処理中...',
                        sSearch: html_search,
                        sSearchPlaceholder: 'コンテンツ内検索',
                        sZeroRecords: 'ログの一覧が0件です。',
                        oPaginate: {
                            sFirst: '先頭',
                            sLast:  '最終',
                            sNext:  '次',
                            sPrevious: '前',
                        },
                    },

                    columnDefs: [
                        { targets: [0], width:  '5%' },     //処理状況
                        { targets: [1], width: '40%' },     //ログの取得期間
                        { targets: [2], width: '25%' },     //作成開始
                        { targets: [3], width: '25%' },     //作成終了
                        {                                   //ダウンロード
                            targets: [4],
                            width: '5px',
                            orderable: false,
                            className: 'edit-button-col text-center',
                        },
                    ],
                })
                ,0
            );
        },
        validateDate: function() {
            // console.log('OperationLog>>validateDate');
            // console.log('OperationLog>>validation (input)sta:[' + this.days.start.value + '] end:[' + this.days.end.value);

            //未入力
            if (this.days.start.value == '') {
                this.messages.push('開始日を入力してください');
            }
            if (this.days.end.value == '') {
                this.messages.push('終了日を入力してください');
            }
            let day_sta = new Date(this.days.start.value);
            let day_end = new Date(this.days.end.value);

            //入力範囲
            let min_sta = new Date(this.days.start.min);
            let min_end = new Date(this.days.end.min);
            if (day_sta < min_sta) {
                this.messages.push('開始日は ' + this.days.start.min.replaceAll('-', '/') + ' 以降を指定してください');
            }
            if (day_end < min_end) {
                this.messages.push('終了日は ' + this.days.end.min.replaceAll('-', '/') + ' 以降を指定してください');
            }

            let max_sta = new Date(this.days.start.max);
            let max_end = new Date(this.days.end.max);
            if (day_sta > max_sta) {
                this.messages.push('開始日に未来日は指定できません');
            }
            if (day_end > max_end) {
                this.messages.push('終了日に未来日は指定できません');
            }

            //関連
            if (day_sta > day_end) {
                this.messages.push('開始日は終了日以前を入力してください');
            }
        },
        //（入力チェック）OK:true、NG:false
        validation: function() {
            // console.log('OperationLog>>validation');

            //メッセージクリア
            this.messages = [];

            //日付チェック
            this.validateDate();

            //結果
            return (this.messages.length < 1);
        },
        executeSave: function() {
            // console.log('OperationLog>>executeSave');

            axios.post("/api/box/createOperationLog", {
                date_start: this.days.start.value,
                date_end:   this.days.end.value,
            }).then((res) => {  // eslint-disable-line
                // console.log('OperationLog>>res:' + res);
                this.$refs.ReloadButton.redirectSelf();
            }).catch((err) => {
                // console.log('OperationLog>>err:' + err);
                if (err.request.responseURL.toLowerCase().indexOf('box/login') > -1) {
                    this.$refs.loginChecker.redirectTo(err.request.responseURL);
                    return;
                }
                this.messages.push('一覧の作成に失敗しました');
            });
        },
        // 表示するロゴのURLを取得
        getLogoUrl: async function () {
            // API共通処理のGET用メソッドを実行
            this.logo = (await this.getApi(`box/getLogoURL`)) || [];
            if (this.logo.length === 0) {
                this.$router.push({ path: '/404' });
            }
        },
        //-----
        //イベントハンドラ
        //-----
        //ボタン『実行する』
        onClickButtonExecute: function() {
            // console.log('OperationLog>>onClickButtonExecute');

            //エラーがある場合は中断
            if (!this.validation()) {
                return;
            }

            //確認ダイアログ
            let dlg_args_save = {
                title: '実行確認',
                messages: [
                    'ログの取得を実行しますか？'
                ],
                btn: {
                    caption: {
                        yes: 'はい',
                        no:  'いいえ',
                    },
                    active: 'yes',
                },
            };
            this.$refs.dlg_confirm.show(dlg_args_save);
        },
        //確認ダイアログで『はい』選択時（コールバック）
        onConfirmSelectYes: function(args) {    // eslint-disable-line
            // console.log('OperationLog>>onConfirmSelectYes call:' + args.call);
            // console.log('OperationLog>>onConfirmSelectYes idx:' + args.idx);

            //保存処理実行
            this.executeSave();
        },
        //ボタン『ダウンロード』
        onClickButtonDownload: function(idx, item) {    // eslint-disable-line
            // console.log('OperationLog>>onClickButtonDownload idx' + idx);
            // console.log('OperationLog>>onClickButtonDownload idx' + item);
            const download_args = {
                target_id: item.id,
                option: 4,
                name: item.period,
                mine_type: 'text/csv',
                default_filename: 'OperationLog.csv',
            }
            this.$refs.downloader.downloadCSV(download_args);
        },
        //ダウンロードに失敗（コールバック）
        onDownloadCSVError: function(err) {     // eslint-disable-line
            // console.log('onDownloadCSVError' + err);
            this.messages.push('ファイルのダウンロードに失敗しました。');
        }
    },
};
</script>

<style>
.alert-secondary {
    padding-left: 10px;
}
.alert-secondary p {
    padding-left: 1em;
    text-indent: -1em;
    margin: 0;
}
.day-separator {
    vertical-align: text-center;
}
</style>
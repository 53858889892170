const state = {
    loading: [],
    navbar: true,
    accordion: false,
};

const mutations = {
    pushLoading(state) {
        state.loading.push(true);
    },
    popLoading(state) {
        state.loading.pop();
    },
    setNavbar(state, flag) {
        state.navbar = flag;
    },
    setAccordion(state, flag) {
        state.accordion = flag;
    },
};

const getters = {
    isLoading: state => state.loading.length > 0,
    accordion: state => (state.accordion ? state.accordion : ''),
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
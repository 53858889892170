<template>
  <!-- <div :class="navbar ? '' : 'is-collapsed'"> -->
  <div>
    <!-- メニュー -->
    <Menu />

    <!-- ページ -->
    <!-- <div class="page-container"> -->
    <div class="main-panel">
      <!-- ヘッダー -->
      <Header />

      <!-- コンテンツ -->
      <RouterView />

      <!-- フッター -->
      <Footer />
    </div>

    <!-- </div> -->
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Index',
  components: {
    Menu,
    Header,
    Footer,
  },
  data() {
    return {};
  },
  beforeCreate() {
    /* GA4 Googleアナリティクス 2021/10 */
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    // 基本値のセット
    var GtagSetArray = {
      'user_id': 'U' + this.$store.getters['auth/id'],
      'user_properties': {
        'company_id': 'C' + this.$store.getters['auth/companyId'],
        'role': 'R' + this.$store.getters['auth/role']
      }
    };

    // 内部アクセス切り替え
    if (window.location.hostname == 'portal-stag.symphonict.com') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_stag';
    } else if (window.location.hostname == 'portal-dev.symphonict.com') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_dev';
    } else if (window.location.hostname == 'localhost') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_local';
    }

    // 送信
    gtag('config', 'G-Y9CV60BH7R', GtagSetArray);
    /* end GA4 Googleアナリティクス 2021/10 */
  },
  created() {
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {},
  computed: {
    navbar() {
      return this.$store.state.view.navbar;
    },
  },
};
</script>

<style>
/* メインコンテンツ */

.main-content {
  min-height: calc(100vh - 60px);
  width: 100%;
  padding-top: 30px;
}

@media (min-width: 768px) {
  /* .main-panel {
      width: 100%;
      padding-top: 30px;
  } */

  .content-panel {
    width: 100%;
    padding-top: 30px;
  }

  .js-set-sidebar-minimize {
    width: 60px;
  }
}
</style>

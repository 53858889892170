<template>
  <main class="main-content content bgc-grey-200">
    <!-- ログインチェック -->
    <component-login-checker
      ref="loginChecker"
      @onCheckLoginContinueSuccess="onShowDialogFileList"
      @onCheckLoginContinueError="onShowDialogFileListError"
    />
    <component-login-checker-local
      ref="loginCheckerForLocal"
      @onCheckLoginContinueSuccess="onShowDialogFileList"
      @onCheckLoginContinueError="onShowDialogFileListError"
    />
    <!-- ダイアログの定義 -->
    <component-dialog-file-list
      ref="dlg_filelist"
      @onClickExecute="onSelectedFolderId"
    />
    <component-downloader
      ref="downloader"
      @onDownloadCSVError="onDownloadCSVError"
    />

    <!-- 画面項目 -->
    <div class="useful">
      <!-- タイトル行 -->
      <div class="row">
        <!-- タイトル -->
        <div class="d-flex flex-row mb-3 align-items-center bd-highlight">
            <img :src="logo.logo_url" alt="Boxロゴ" class="img-fluid c-img display:flex p-2 bd-highlight"/>
          <div class="p-2 bd-highlight">
            <h4 class="c-grey-900 mt-4 display:flex" data-e2e="page_title_name">メタデータ一覧取得</h4>
          </div>
        </div>

        <!-- 戻る -->
        <div class="col-12 col-lg-auto ml-xl-auto d-flex align-items-center">
          <router-link role="button" id="ButtonReturn" to="/box/OptionMenu" class="btn btn-outline-info btn-sm btn-width bg-white">
            オプション機能選択へ戻る
          </router-link>
        </div>
      </div>

      <!-- エラーメッセージ -->
      <div>
        <h4 style="color: #CC0000 ; padding-top: 8px; padding-left: 20px;">
          <div v-for="msg in messages" :key="msg">
            {{ msg }}
          </div>
        </h4>
      </div>

      <!-- デバッグ用：フォルダID、フォルダ名 -->
<!-- 
      <h5 class="c-grey-900 mt-5 mb-3">対象フォルダ名</h5>
      <div class="textbox mb-4" data-e2e="textbox_block">
        <input v-model="folder.name" type="text" size="35" />
        <input v-model="folder.id" type="text" size="35" />
      </div>
 -->
 
      <!-- 説明文と制限事項 -->
      <div class="mt-3">
        <div class="alert alert-secondary" role="alert">
          <p data-e2e="role_policy_1">・所有者/共同所有者権限があるフォルダ内のメタデータ一覧をダウンロードできます。</p>
          <p data-e2e="role_policy_2">・取得範囲は選択したフォルダの3階層下までとなります。</p>
          <p data-e2e="role_policy_3">・こちらの画面には30日間リスト表示されますが、それ以降は削除されますのでご注意ください。</p>
        </div>
      </div>

      <!-- コマンド -->
      <div class="card border-0 m-0 mb-3 mb-30px">
        <div class="card-body">
          <div class="row align-items-start align-items-lg-stretch flex-lg-nowrap">

            <!-- メタデータ一覧作成 -->
            <div class="col-12 col-lg-auto">
              <a
                id="ButtonCreate"
                class="btn btn-info link-btn w-60 btn-sm"
                target="_blank"
                role="button"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-3" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                v-on:click="onClickButtonCreate()"
              >メタデータ一覧作成
              </a>
              <span id="ButtonCreateComment">※実行後メタデータ情報が多い場合はリスト反映に時間を要する可能性があります</span>
            </div>

            <div class="col-12 d-lg-none p-0">
              <hr />
            </div>

            <!-- 更新ボタン -->
            <ReloadButton ref="ReloadButton" id="ButtonReload" />
          </div>
        </div>
      </div>

      <!-- 処理状況一覧 -->
      <div class="row">
        <div class="col-md-12">
          <div class="bgc-white bd bdrs-3 p-20 mB-20">

            <table
              id="dataTable"
              class="table table-striped table-bordered table-hover text-break"
              aria-hidden="true"
            >
              <thead>
                <tr class="thead-light">
                  <th scope="col">処理状況</th>
                  <th scope="col">Boxのフォルダパス</th>
                  <th scope="col">フォルダID</th>
                  <th scope="col">作成開始</th>
                  <th scope="col">作成終了</th>
                  <th scope="col">ダウンロード</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in process_status" :key="item.id" data-e2e="process-status">
                  <td >{{item.creation_status}}</td>
                  <td >{{item.target_folder_name}}</td>
                  <td >{{item.target_folder_id}}</td>
                  <td >{{item.creation_started_at}}</td>
                  <td >{{item.creation_ended_at}}</td>
                  <td v-if="item.can_download === false"></td>
                  <td v-if="item.can_download === true">
                    <div
                      class="td-n c-red-500 cH-blue-500 fsz-md"
                      style="cursor: pointer"
                      v-on:click="onClickButtonDownload(item)"
                    >
                      <svg class="bi" width="26" height="26" fill="currentColor">
                        <use xlink:href="/img/bootstrap-icons.svg#download"/>
                      </svg>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <!-- 処理状況一覧 -->

    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';
import * as $ from 'jquery';
import axios from 'axios';

import LoginChecker         from '@/pages/box/common/LoginChecker.vue';
import LoginCheckerForLocal from '@/pages/box/common/LoginCheckerForLocal.vue';
import Downloader           from '@/pages/box/common/DownloaderForAxios.vue';
import ReloadButton         from '@/pages/box/common/ReloadButton.vue';

import DialogFileList from '@/pages/box/dialogs/DialogFileList.vue';

export default {
  name: 'BoxMetaDataList',
  mixins: [apiMixin, tooltipMixin],

  components: {
    'component-login-checker': LoginChecker,
    'component-login-checker-local': LoginCheckerForLocal,
    'component-dialog-file-list': DialogFileList,
    'component-downloader': Downloader,
    'ReloadButton':ReloadButton,
  },

  data() {
    return {
      isLocal: false,
      folder: {
        id:   '0',  //デフォオルトのフォルダID（0:ルート）
        name: '',
      },
      logo: [],
      messages: [],
      process_status: [],
    }
  },

  mounted() {
    this.checkLogin();
    this.getLogoUrl();
    this.getProcessStatus();
  },

  updated() {
    this.initTooltip();
  },

  methods: {
    //-----
    //内部メソッド
    //-----
    // checkLogin: async function(code) {
    checkLogin: async function() {
      // console.log('MetaDataList>>checkLogin');
      this.isLocal = await this.getApi(`box/isLocal`);

      if (this.isLocal) {
        // console.log('MetaDataList>>isLocal: local');
        this.$refs.loginCheckerForLocal.checkLogin();
      } else {
        // console.log('MetaDataList>>isLocal: other');
        this.$refs.loginChecker.checkLogin();
      }
    },
    // redirectSelf: function() {
    //   //DataTableを更新しても一覧が正常に更新できないため
    //   //自分自身にリダイレクトして画面を再描画させる
    //   this.$router.go({path: this.$router.currentRoute.path, force: true});
    // },
    // 表示するロゴのURLを取得
    getLogoUrl: async function () {
      // API共通処理のGET用メソッドを実行
      this.logo = (await this.getApi(`box/getLogoURL`)) || [];
      if (this.logo.length === 0) {
        this.$router.push({ path: '/404' });
      }
    },
    getProcessStatus: async function() {
      this.process_status = await this.getApi(
        `box/getBoxCSVStatusForMetaDataList`
      );

      setTimeout(() => 
        $('#dataTable').DataTable({
          destroy: true,
          order: [],
          autowidth: false,
          oLanguage: {
            sEmptyTable: '現在処理状況が登録されていません',
            sInfo: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
            sInfoEmpty: ' 0 件中 0 から 0 まで表示',
            sInfoFiltered: '（全 _MAX_ 件より抽出）',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu:
              '<div class="form-inline mb-3"><select class="form-control"><option>10</option><option>25</option><option>50</option><option>100</option></select>' +
              '<span class="my-1 ml-2">' +
              '件表示' +
              '</span></div>',
            sLoadingRecords: '読み込み中...',
            sProcessing: '処理中...',
            sSearch:
              '<div class="input-group js-date-search" data-e2e="list_search">' +
              '_INPUT_' +
              '<div class="input-group-append"><span class="input-group-text" ><svg class="bi" width="26" height="20" fill="currentColor"><use xlink:href="/img/bootstrap-icons.svg#search"/></svg></div></div>',
            sSearchPlaceholder: 'コンテンツ内検索',
            sZeroRecords: '登録された処理状況が0件です。',
            oPaginate: {
              sFirst: '先頭',
              sLast: '最終',
              sNext: '次',
              sPrevious: '前',
            },
          },
          columnDefs: [
            { targets: [0], width: '5%'  },    //処理状況
            { targets: [1], width: '60%' },    //Boxのフォルダパス
            { targets: [2], width: '10%' },    //フォルダID
            { targets: [3], width: '10%' },    //作成開始
            { targets: [4], width: '10%' },    //作成終了
            {
              targets: [5],                   //ダウンロード
              orderable: false,
              width: '5%',
              className: 'edit-button-col text-center',
            },
          ],
        })
        ,1
      );
    },
    //-----
    //イベントハンドラ
    //-----
    //ボタン『メタデータ一覧作成』
    onClickButtonCreate: function() {
      console.log('MetaDataList>>onClickButtonCreate');
      //ログインチェック
      if (this.isLocal) {
        this.$refs.loginCheckerForLocal.checkLoginContinue();
      } else {
        this.$refs.loginChecker.checkLoginContinue();
      }
    },
    //LoginChecker でエラーがあった場合のコールバック
    onShowDialogFileListError: function() {
      this.$refs.dlg_filelist.close();
    },
    //ダイアログの表示
    onShowDialogFileList: function() {
      const dlg_args = { 
          title: 'フォルダ選択',
          id:    this.folder.id, 
          name:  this.folder.name,
      };
      this.$refs.dlg_filelist.show(dlg_args);
    },
    //ダイアログ『フォルダ選択／実行』ボタン押下時の
    //イベントハンドラ（コールバック）
    onSelectedFolderId: function(args) {
      // console.log('MetaDataList>>onSelectedFolderId');

      //ログインチェック
      if (this.isLocal) {
        // console.log('MetaDataList>>onSelectedFolderId: local');
        this.$refs.loginCheckerForLocal.checkLogin();
      } else {
        // console.log('MetaDataList>>onSelectedFolderId: other');
        this.$refs.loginChecker.checkLogin();
      }
      
      this.folder.id   = args.id;
      this.folder.name = args.name;

      this.messages = [];

      //ダイアログ閉じる
      this.$refs.dlg_filelist.close();

      //メタデータ権一覧の作成を非同期で実行
      axios
        .post("/api/box/createMetaDataListCSV", {
          folder_id: this.folder.id,
          folder_name: args.full_name
        })
        .then((res) => {  // eslint-disable-line
          // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
          //ログイン画面へ戻す場合
          if (err.request.responseURL.toLowerCase().indexOf('box/login') > -1) {
            this.$refs.loginChecker.redirectTo(err.request.responseURL);
            return;
          }
          this.messages.push('メタデータ一覧の作成に失敗しました。');
        });

      //DataTableを更新しても一覧が正常に更新できないため
      //自分自身にリダイレクトして画面を再描画させる
      this.$refs.ReloadButton.redirectSelf();
    },
    //ボタン『ダウンロード』
    onClickButtonDownload: function(item) {
      // console.log('MetaDataList>>onClickButtonDownload>>id:>' + item.id);
      this.messages = [];

      const download_args = {
        option: 2,
        target_id: item.id,
        id:        item.target_folder_id,
        name:      item.target_folder_name,
        mine_type: 'text/csv',
        default_filename: 'MetadataList.csv',
      }
      this.$refs.downloader.downloadCSV(download_args);
    },
    //ダウンロードに失敗（コールバック）
    onDownloadCSVError: function(err) { // eslint-disable-line
      // console.log('MetaDataList>>onDownloadCSVError' + err);
      this.messages.push('ファイルのダウンロードに失敗しました。');
      this.$refs.dlg_filelist.close();
    }
  }
};
</script>

<style scoped>
.button-create {
  width: 37%;
}

.BoxDownloadButton {
  width: 37%;
}

.traning {
  background-color: #2c8fff;
  color: white;
}

.alert-secondary {
  padding-left: 10px;
}

.alert-secondary p {
  padding-left:1em;
	text-indent:-1em;
  margin: 0;
}

th {
  position: sticky;
  top:0;
  left:0;
}
</style>

import Vue from 'vue';
import App from './App.vue';
// import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/scripts/index.js';
import '@/assets/css/symphonict-platform-pack.css';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'vue-moment';
import 'toastr/toastr.scss';
import Toasted from 'vue-toasted';

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(moment);

// Toasted オプション設定
// 今回はポジション・表示されてから消えるまでの時間・横幅についてのオプション追加
var options = {
  duration: 4000,
};
Vue.use(Toasted, options);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');

// ほとんどのエラーをキャッチ
Vue.config.errorHandler = function (err) {
  if (err.response.status == 500) {
    router.push('/500');
  } else if (err.response.status == 401) {
    router.push('/');
  }
};

// 残りのエラーをキャッチ
window.addEventListener('error', (event) => {
  if (event.error.response.status == 500) {
    router.push('/500');
  } else if (event.error.response.status == 401) {
    router.push('/');
  }
});
window.addEventListener('unhandledrejection', (event) => {
  if (event.reason.response.status == 500) {
    router.push('/500');
  } else if (event.reason.response.status == 401) {
    router.push('/');
  }
});

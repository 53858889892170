<template>
  <main class="main-content content bgc-grey-200">
    <!-- ログインチェック -->
    <component-login-checker
      ref="loginChecker"
      @onCheckLoginContinueSuccess="onShowDialogFileList"
      @onCheckLoginContinueError="onShowDialogFileListError"
    />
    <component-login-checker-local
      ref="loginCheckerForLocal"
      @onCheckLoginContinueSuccess="onShowDialogFileList"
      @onCheckLoginContinueError="onShowDialogFileListError"
    />
    <!-- ダイアログの定義 -->
    <component-dialog-file-list
      ref="dlg_filelist"
      @onClickExecute="onSelectedFolderId"
    />
    <component-downloader
      ref="downloader"
      @onDownloadCSVError="onDownloadCSVError"
    />

    <!-- 画面項目 -->
    <div class="useful">
      <!-- タイトル行 -->
      <div class="row">
        <!-- タイトル -->
        <div class="d-flex flex-row bd-highlight mb-3 align-items-center">
          <img :src="logo.logo_url" alt="Boxロゴ" class="img-fluid c-img display:flex p-2 bd-highlight" />
          <div class="p-2 bd-highlight">
            <h4 class="c-grey-900 mt-4 display:flex" data-e2e="page_title_name">アクセス権一覧取得</h4>
          </div>
        </div>

        <!-- 戻る -->
        <div class="col-12 col-lg-auto ml-xl-auto d-flex align-items-center">
          <router-link role="button" id="ButtonReturn" to="/box/OptionMenu" class="btn btn-outline-info btn-sm btn-width bg-white">
            オプション機能選択へ戻る
          </router-link>
        </div>
      </div>

      <!-- エラーメッセージ -->
      <div>
        <h4 style="color: #CC0000 ; padding-top: 8px; padding-left: 20px;">
          <div v-for="msg in messages" :key="msg">
            {{ msg }}
          </div>
        </h4>
      </div>

      <!-- デバッグ用：フォルダID、フォルダ名 -->
<!-- 
      <h5 class="c-grey-900 mt-5 mb-3">対象フォルダ名</h5>
      <div class="textbox mb-4" data-e2e="textbox_block">
        <input v-model="folder.name" type="text" size="35" />
        <input v-model="folder.id" type="text" size="35" />
      </div>
 -->

      <!-- 説明文と制限事項 -->
      <div class="mt-3">
        <div class="alert alert-secondary" role="alert">
          <p data-e2e="role_policy_1">・所有者/共同所有者権限があるフォルダのアクセス権一覧をダウンロードできます。</p>
        </div>
      </div>

      <!-- コマンド（ボタン） -->
      <div class="card border-0 m-0 mb-3 mb-30px">
        <div class="card-body">
          <div class="row align-items-start align-items-lg-stretch flex-lg-nowrap">

            <!-- プルダウン「階層選択」-->
            <div class="col-2 col-lg-auto p-0">
                <select class="form-control-sm" v-model="hierarchy_info.selected">
                    <template v-for="arr in hierarchy_info.items" :value="arr">
                        <option v-for="(val, key, idx) in arr" :key="idx" :value="val"
                        >{{ key }}</option>
                    </template>
                </select>
            </div>

            <!-- アクセス権一覧取得 -->
            <div class="col-12 col-lg-auto">
                <a
                id="ButtonCreate"
                class="btn btn-info link-btn btn-sm"
                target="_blank"
                role="button"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-3" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                v-on:click="onClickButtonCreate()"
                >アクセス権一覧取得</a>
            </div>

            <div class="col-12 d-lg-none p-0">
              <hr />
            </div>

            <!-- 更新ボタン -->
            <ReloadButton ref="ReloadButton" id="ButtonReload"/>

        </div>
        </div>
      </div>

      <!-- 処理状況一覧 -->
      <div class="row">
        <div class="col-md-12">
          <div class="bgc-white bd bdrs-3 p-20 mB-20">

            <table
              id="dataTable"
              class="table table-striped table-bordered table-hover text-break"
              aria-hidden="true"
            >
              <thead>
                <tr class="thead-light">
                  <th scope="col">アクセス権取得の進捗</th>
                  <th scope="col">権限編集の進捗</th>
                  <th scope="col">Boxのフォルダパス</th>
                  <th scope="col">フォルダID</th>
                  <th scope="col">作成開始</th>
                  <th scope="col">作成終了</th>
                  <th scope="col">ダウンロード</th>
                  <th scope="col">編集</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in process_status" :key="item.id" data-e2e="process-status">
                  <td >{{item.creation_status}}</td>
                  <td >{{item.edit_status}}</td>
                  <td >{{item.target_folder_name}}</td>
                  <td >{{item.target_folder_id}}</td>
                  <td >{{item.creation_started_at}}</td>
                  <td >{{item.creation_ended_at}}</td>
                  <td>
                    <div
                      class="td-n c-red-500 cH-blue-500 fsz-md"
                      style="cursor: pointer"
                      v-on:click="onClickButtonDownload(item)"
                      v-bind:hidden="item.can_download === false"
                    >
                      <svg class="bi" width="26" height="26" fill="currentColor">
                        <use xlink:href="/img/bootstrap-icons.svg#download"/>
                      </svg>
                    </div>
                  </td>
                  <td>
                    <router-link
                        class="td-n c-deep-purple-500 cH-blue-500 fsz-md"
                        v-bind:hidden="item.can_edit === false"
                        v-bind:to="{path: '/box/AccessListEditAuthority', query: { value: item.id }}"
                    >
                      <svg class="bi" width="26" height="26" fill="currentColor">
                        <use xlink:href="/img/bootstrap-icons.svg#pencil"/>
                      </svg>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <!-- 処理状況一覧 -->

    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';
import * as $ from 'jquery';
import axios from 'axios';

import LoginChecker         from '@/pages/box/common/LoginChecker.vue';
import LoginCheckerForLocal from '@/pages/box/common/LoginCheckerForLocal.vue';
import Downloader           from '@/pages/box/common/DownloaderForAxios.vue';
import ReloadButton         from '@/pages/box/common/ReloadButton.vue';

import DialogFileList from '@/pages/box/dialogs/DialogFileList.vue';

export default {
  name: 'BoxAccessList',
  mixins: [apiMixin, tooltipMixin],

  components: {
    'component-login-checker': LoginChecker,
    'component-login-checker-local': LoginCheckerForLocal,
    'component-dialog-file-list': DialogFileList,
    'component-downloader': Downloader,
    'ReloadButton':ReloadButton,
  },

  data() {
    return {
      isLocal: false,
      folder: {
        id:   '0',  //デフォオルトのフォルダID（0:ルート）
        name: '',
      },
      logo: [],
      messages: [],
      process_status: [],

      data_table: null,

      //アクセス権一括編集と同様の記述をすると正常にプルダウンが作成されない：原因不明
      // hierarchy_info: {
      //  selected: null,
      //  items: [],
      // },
      hierarchy_info: null,
    }
  },

  mounted() {
    this.checkLogin();
    this.getHierarchyInfo();
    this.getLogoUrl();
    this.getProcessStatus();
  },

  methods: {
    //-----
    //内部メソッド
    //-----
    // checkLogin: async function(code) {
    checkLogin: async function() {
      // console.log('AccessList>>checkLogin');
      this.isLocal = await this.getApi(`box/isLocal`);

      if (this.isLocal) {
        // console.log('AccessList>>isLocal: local');
        this.$refs.loginCheckerForLocal.checkLogin();
      } else {
        // console.log('AccessList>>isLocal: other');
        this.$refs.loginChecker.checkLogin();
      }
    },
    // 表示するロゴのURLを取得
    getLogoUrl: async function () {
      // API共通処理のGET用メソッドを実行
      this.logo = (await this.getApi(`box/getLogoURL`)) || [];
      if (this.logo.length === 0) {
        this.$router.push({ path: '/404' });
      }
    },
    // 階層の一覧表示
    getHierarchyInfo: async function() {
        this.hierarchy_info = (await this.getApi('box/getHierarchyInfoForAccessList')) || [];
        // console.log('★★★' + JSON.stringify(this.hierarchy_info));
    },
    //データテーブルの作成
    // ※コンポーネント化不可（正常に描画されない）
    createDataTable: function(deleteRow = false) {
      if (this.data_table != null) {
        this.data_table.state.clear();
        this.data_table.destroy();
        if (deleteRow) {
          $("#dataTable > tbody > tr").remove();
        }
      }

      let html_menu
        = '<div class="form-inline mb-3">'
        + '<select class="form-control">'
        + '<option>10</option>'
        + '<option>25</option>'
        + '<option>50</option>'
        + '<option>100</option>'
        + '</select>'
        + '<span class="my-1 ml-2">件表示'  //</span>を追加すると行間が広がるため削除している
        + '</div>'
      ;

      let html_search
        = '<div class="input-group js-date-search" data-e2e="list_search">'
        + '_INPUT_'
        + '<div class="input-group-append">'
        + '<span class="input-group-text" >'
        + '<svg class="bi" width="26" height="20" fill="currentColor">'
        + '<use xlink:href="/img/bootstrap-icons.svg#search"/>'
        + '</svg>'
        + '</span>'
        + '</div>'
        + '</div>'
      ;

      setTimeout(() =>
        this.data_table = $('#dataTable').DataTable({
          destroy: true,
          order: [],
          autowidth: false,
          oLanguage: {
            sEmptyTable: '現在処理状況が登録されていません',
            sInfo: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
            sInfoEmpty: ' 0 件中 0 から 0 まで表示',
            sInfoFiltered: '（全 _MAX_ 件より抽出）',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu: html_menu,
            sLoadingRecords: '読み込み中...',
            sProcessing: '処理中...',
            sSearch: html_search,
            sSearchPlaceholder: 'コンテンツ内検索',
            sZeroRecords: '登録された処理状況が0件です。',
            oPaginate: {
              sFirst:    '先頭',
              sLast:     '最終',
              sNext:     '次',
              sPrevious: '前',
            },
          },
          columnDefs: [
            { targets: [0], width: '3%'  },    //処理状況
            { targets: [1], width: '3%'  },    //編集状況
            { targets: [2], width: '60%' },    //Boxのフォルダパス
            { targets: [3], width: '10%' },    //フォルダID
            { targets: [4], width: '8%' },    //作成開始
            { targets: [5], width: '8%' },    //作成終了
            {
              targets: [6],                   //ダウンロード
              width: '3%',
              orderable: false,
              className: 'edit-button-col text-center',
            },
            {
              targets: [7],                   //編集
              width: '3%',
              orderable: false,
              className: 'edit-button-col text-center',
            },
          ],
        })
        ,0
      );
    },
    getProcessStatus: async function() {
      this.process_status = await this.getApi(
          `box/getBoxCSVStatusForAccessList`
      );

    //   this.process_status = [
    //         {
    //             id: 1,
    //             creation_status: '作成成功',
    //             edit_status: '－',
    //             target_folder_id: '168564287414',
    //             target_folder_name: 'すべてのファイル/develop',
    //             creation_started_at: '2024-04-01',
    //             creation_ended_at: '2024-04-01',
    //             can_download: true,
    //             can_edit: false,
    //         },
    //         {
    //             id: 2,
    //             creation_status: '作成成功',
    //             edit_status: '編集開始',
    //             target_folder_id: '168564287414',
    //             target_folder_name: 'すべてのファイル/develop',
    //             creation_started_at: '2024-04-01',
    //             creation_ended_at: '2024-04-01',
    //             can_download: true,
    //             can_edit: false,
    //         },
    //         {
    //             id: 3,
    //             creation_status: '作成成功',
    //             edit_status: '編集成功',
    //             target_folder_id: '168564287414',
    //             target_folder_name: 'すべてのファイル/develop',
    //             creation_started_at: '2024-04-01',
    //             creation_ended_at: '2024-04-01',
    //             can_download: true,
    //             can_edit: true,
    //         },
    //         {
    //             id: 4,
    //             creation_status: '作成失敗',
    //             edit_status: '編集失敗',
    //             target_folder_id: '168564287414',
    //             target_folder_name: 'すべてのファイル/develop',
    //             creation_started_at: '2024-04-01',
    //             creation_ended_at: '2024-04-01',
    //             can_download: true,
    //             can_edit: false,
    //         },
    //   ];
      this.createDataTable(true);
    },

    //-----
    //イベントハンドラ
    //-----
    // ボタン『アクセス権一覧作成』
    onClickButtonCreate: function() {
      //ログインチェック
      if (this.isLocal) {
        this.$refs.loginCheckerForLocal.checkLoginContinue();
      } else {
        this.$refs.loginChecker.checkLoginContinue();
      }
    },
    onShowDialogFileListError: function() {
      this.$refs.dlg_filelist.close();
    },
    //ダイアログの表示
    onShowDialogFileList: function() {
      const dlg_args = { 
          title: 'フォルダ選択',
          id:    this.folder.id, 
          name:  this.folder.name,
      };
      this.$refs.dlg_filelist.show(dlg_args);
    },
    //ダイアログ『フォルダ選択／実行』ボタン押下時の
    //イベントハンドラ（コールバック）
    onSelectedFolderId: function(args) {
      // console.log('AccessList>>onSelectedFolderId');

      //ログインチェック
      if (this.isLocal) {
        // console.log('AccessList>>onSelectedFolderId: local');
        this.$refs.loginCheckerForLocal.checkLogin();
      } else {
        // console.log('AccessList>>onSelectedFolderId: other');
        this.$refs.loginChecker.checkLogin();
      }
      
      this.folder.id   = args.id;
      this.folder.name = args.name;

      this.messages = [];

      //ダイアログ閉じる
      this.$refs.dlg_filelist.close();

      //アクセス権一覧の作成を非同期で実行
      axios
        .post("/api/box/createAccessListCSV", {
          folder_id: this.folder.id,
          folder_name: args.full_name,
          hierarchy: this.hierarchy_info.selected
        })
        .then((res) => {    // eslint-disable-line
          // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
          //ログイン画面へ戻す場合
          if (err.request.responseURL.toLowerCase().indexOf('box/login') > -1) {
            this.$refs.loginChecker.redirectTo(err.request.responseURL);
            return;
          }
          this.messages.push('アクセス権一覧の作成に失敗しました。');
        });

      //DataTableを更新しても一覧が正常に更新できないため
      //自分自身にリダイレクトして画面を再描画させる
      this.$refs.ReloadButton.redirectSelf();
    },
    //ボタン『ダウンロード』
    onClickButtonDownload: function(item) {
      // console.log('AccessList>>onClickButtonDownload');
      this.messages = [];

      const download_args = {
        option: 1,
        target_id: item.id,
        id:        item.target_folder_id,
        name:      item.target_folder_name,
        mine_type: 'text/csv',
        default_filename: 'AccessList.csv',
      }
      this.$refs.downloader.downloadCSV(download_args);
    },
    //ダウンロードに失敗（コールバック）
    onDownloadCSVError: function(err) { // eslint-disable-line
      // console.log(err);
      this.messages.push('ファイルのダウンロードに失敗しました。');
      this.$refs.dlg_filelist.close();
    },
  }
};
</script>

<style scoped>
.traning {
  background-color: #2c8fff;
  color: white;
}

.alert-secondary {
  padding-left: 10px;
}

.alert-secondary p {
  padding-left:1em;
  text-indent:-1em;
  margin: 0;
}
</style>

<template>
  <div>
    <main class="main-content bgc-grey-200 layers">
      <!-- ロゴ -->
      <img class="w-25" src="/images/symphonict-logo-bk-y.png" alt="" />
      <h2 class="c-grey-900 font-weight-bold ml-5">サービスポータル</h2>

      <div class="masonry-item col-md-6">
        <div class="d-flex justify-content-start mt-4">
          <h4 class="c-grey-900">パスワードリセット</h4>
        </div>
        <div class="bgc-white p-20 bd card">
          <!-- 送信成功メッセージ -->
          <!-- Modal -->
          <div
            class="modal fade"
            id="successModal"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <p></p>
                  <h5 class="modal-title">パスワードリセットが完了しました</h5>
                  <p></p>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                  <a
                    class="btn cur-p btn-outline-info w-75 mb-3 px-0 btn-sm"
                    :href="this.loginUrl"
                  >
                    ログイン画面へ
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- リセット失敗メッセージ -->
          <div class="alert alert-danger" role="alert" v-if="failedMessage">
            {{ failedMessage }}
          </div>

          <form>
            <!-- メールアドレス -->
            <div class="form-group">
              <label for="email">メールアドレス</label>
              <div>
                {{ this.emailValue }}
              </div>
            </div>

            <!-- パスワード -->
            <div class="form-group">
              <label for="password">新しいパスワード</label>
              <input
                :class="'form-control h-75' + (errors && errors.password ? ' is-invalid' : '')"
                type="password"
                id="password"
                placeholder=""
                v-model="user.password"
                name="password"
                ref="password"
              />
              <div class="invalid-feedback">{{ errors.password }}</div>
            </div>

            <!-- パスワード再入力 -->
            <div class="form-group">
              <label for="password_confirmation">新しいパスワード(確認用)</label>
              <input
                :class="'form-control h-75' + (errors && errors.password_confirmation ? ' is-invalid' : '')"
                type="password"
                id="password_confirmation"
                placeholder=""
                v-model="user.password_confirmation"
                name="password_confirmation"
              />
              <div class="invalid-feedback">{{ errors.password_confirmation }}</div>
            </div>

            <!-- パスワードポリシー  -->
            <PasswordPolicy :passwordPolicyMin=this.passwordPolicyMin :passwordPolicyMax=this.passwordPolicyMax />

            <!-- ボタン -->
            <div class="d-flex justify-content-center">
              <button v-if="!isLoading" type="button" class="btn btn-info btn-sm" @click="submit()">
                パスワードリセット
              </button>
              <button v-show="isLoading" class="btn btn-info btn-sm" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                パスワードリセット中…
              </button>
            </div>
          </form>

        </div>
      </div>
    </main>

    <!-- フッター -->
    <Footer />
  </div>
</template>

<script>
import PasswordPolicy from '@/components/PasswordPolicy.vue';
import Footer from '@/components/Footer.vue';
import { apiMixin } from '@/mixins/api.js';
import * as $ from 'jquery';

export default {
  name: 'PasswordRecoveryForm',
  mixins: [apiMixin],
  components: {
    PasswordPolicy,
    Footer,
  },
  data() {
    return {
      constants: [],
      resetLink: '',
      errors: null,
      isLoading: false,
      user: null,
      token: null,
      emailValue: null,
      getPrams: [],
      failedMessage: null,
      passwordPolicyMin: null,
      passwordPolicyMax: null,
      passwordPolicyRegex: null,
    };
  },
  created() {
    this.getToken();
    this.initialization();
    this.getConstant();
  },
  mounted() {
    this.$refs.password.focus();
  },
  methods: {
    initialization() {
      // メアドをパラメーターから取得
      this.emailValue = `${this.email}`;

      this.user = {
        email: this.emailValue,
        password: '',
        password_confirmation: '',
        token: this.token,
      };

      this.errors = {
        email: '',
        password: '',
        password_confirmation: '',
      };

      this.resetLink = '';
      this.passwordPolicyMin = '';
      this.passwordPolicyMax = '';
      this.passwordPolicyRegex = '';
      this.loginUrl = '';

    },

    // 定数情報を取得
    async getConstant() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.constants = (await this.getApi('constant')) || [];
      for (const i in this.constants) {
        // 定数APIからpasswordReset リンクの取得
        if (this.constants[i].key == 'passwordReset') {
          this.resetLink = this.constants[i].value;
        }
        // パスワード最小文字数
        if (this.constants[i].key == 'passwordPolicyMin') {
          this.passwordPolicyMin = this.constants[i].value;
        }
        // パスワード最大文字数
        if (this.constants[i].key == 'passwordPolicyMax') {
          this.passwordPolicyMax = this.constants[i].value;
        }
        // パスワード正規表現
        if (this.constants[i].key == 'passwordPolicyRegex') {
          this.passwordPolicyRegex = this.constants[i].value;
        }
        // パスワード正規表現
        if (this.constants[i].key == 'loginUrl') {
          this.loginUrl = this.constants[i].value;
        }
      }
    },
    // トークンを取得
    getToken() {
      this.getPrams = this.$route.path;
      let cut_str = 'm/';
      let index = this.getPrams.indexOf(String(cut_str));
      this.token = this.getPrams.slice(index + 2);
    },
    // パスワードリセットリンクの送信
    async submit() {
      // バリデーション
      if (this.validater()) {
        await this.postUserPass();
      } else {
        this.$toasted.error('入力内容に誤りがあります');
      }
    },
    // 入力内容の検証
    validater() {
      this.errors = {
        email: '',
      };

      this.failedMessage = null;

      let result = true;

      // パスワード
      if (this.user.password.length === 0) {
        result = false;
        this.errors.password = '新しいパスワードを入力してください。';
      } else if (this.user.password.length < this.passwordPolicyMin || this.passwordPolicyMax < this.user.password.length ) {
        result = false;
        this.errors.password = '新しいパスワードの長さは' + this.passwordPolicyMin + '文字以上' + this.passwordPolicyMax + '文字以下で入力してください。';
       } else if (!RegExp(this.passwordPolicyRegex).test(this.user.password)) {
        result = false;
        this.errors.password = 'パスワードの条件に則って入力してください。';
      }

      // パスワード再入力
      if (this.user.password_confirmation.length === 0) {
        result = false;
        this.errors.password_confirmation = '新しいパスワード(確認用)を入力してください';
      } else if (this.user.password_confirmation !== this.user.password) {
        result = false;
        this.errors.password_confirmation = '新しいパスワードと同じ値を入力してください。';
      }
      return result;
    },
    // パスワードリセットリンクを送信する
    async postUserPass() {
      this.isLoading = true;
      // API共通処理（apiMixin）のPOST用メソッドを実行
      const response = (await this.postApi('password/reset', this.user)) || { result: false };
      this.isLoading = false;
      if (response.result) {
        $('#successModal').modal('show');
      } else if (response.message) {
        this.$toasted.error(response.message);
        this.failedMessage =
          'パスワードリセットに失敗しました。新しくパスワードリセットリンクの再発行を行い、もう一度行ってください';
      }
    },
  },
  computed: {
    email() {
      return this.$route.query.email;
    },
  },
};
</script>

<style scoped>
/* メアドフォーム */
.emailForm {
  border: none;
}
.modal-header .close{
  padding: 0;
  margin: 0;
}
</style>

<template>
  <main class="main-content content bgc-grey-200">
    <!-- ログインチェック -->
    <component-login-checker
      ref="loginChecker"
      @onCheckLoginContinueSuccess="onShowDialogFileList"
      @onCheckLoginContinueError="onShowDialogFileListError"
    />
    <component-login-checker-local
      ref="loginCheckerForLocal"
      @onCheckLoginContinueSuccess="onShowDialogFileList"
      @onCheckLoginContinueError="onShowDialogFileListError"
    />

    <!-- ダイアログの定義 -->
    <component-dialog-confirm
      ref="dlg_confirm"
      @onClickYes="onConfirmSelectYes"
    />
    <component-dialog-file-list
      ref="dlg_filelist"
      @onClickExecute="onSelectedFolderId" 
    />
    <component-dialog-file-inventory-operation
      ref="dlg_editOperation"
      @onSelected="onSelectedAction"
    />

    <!-- 画面項目 -->
    <div class="useful">
      <!-- タイトル行 -->
      <div class="row">
        <!-- タイトル -->
        <div class="d-flex flex-row bd-highlight mb-3 align-items-center">
          <img :src="logo.logo_url" alt="Boxロゴ" class="img-fluid c-img display:flex p-2 bd-highlight" />
          <div class="p-2 bd-highlight">
            <h4 class="c-grey-900 mt-4 display:flex" data-e2e="page_title_name">一定期間後のファイル操作</h4>
          </div>
        </div>
        <!-- 戻る -->
        <div class="col-12 col-lg-auto ml-xl-auto d-flex align-items-center">
            <router-link role="button" id="ButtonReturn" to="/box/OptionMenu" class="btn btn-outline-info btn-sm btn-width bg-white">
                オプション機能選択へ戻る
            </router-link>
        </div>
      </div> <!-- row -->

      <!-- エラーメッセージ -->
      <div>
        <h4 style="color: #cc0000; padding-top: 8px; padding-left: 20px">
          <div v-for="msg in messages" :key="msg">
            {{ msg }}
          </div>
        </h4>
      </div>

      <!-- 説明文と制限事項 -->
      <div class="mt-3">
        <div class="alert alert-secondary" role="alert">
          <p data-e2e="role_policy_1">・所有者/共同所有者権限があるフォルダで棚卸(削除・移動)設定ができます。</p>
          <p data-e2e="role_policy_2">・該当ファイルの最終更新日を起点にしてアクションを実行します。</p>
          <p data-e2e="role_policy_3">・登録できるレコードは最大100レコードまでです。</p>
          <p data-e2e="role_policy_4">・複数フォルダを選択された場合の動作イメージについては、<a 
            target="box_explanation" style="color:#ff6c9e;text-decoration: underline" 
            class="content-link" href="https://nesicjp.box.com/s/piag8xfxn33mt5b18rq50fv2lkzhi83n"
            >こちら</a>をご参照ください。</p>
        </div>
      </div>

      <!-- コマンド -->
      <div class="card border-0 m-0 mb-3 mb-30px">
        <div class="card-body">
          <div class="row align-items-start align-items-lg-stretch flex-lg-nowrap">

            <div class="col-12 col-lg-auto">
              <!-- 追加ボタン -->
              <a
                id="ButtonAppend"
                class="btn btn-info btn-icon-left link-btn btn-sm w-60"
                role="button"
                target="_blank"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-3" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                v-on:click="onClickButtonAppend()"
              >
                <svg data-v-0ebcf349="" fill="currentColor" class="bi">
                  <use data-v-0ebcf349="" xlink:href="/img/bootstrap-icons.svg#plus"></use>
                </svg>
                設定行を追加する
              </a>
            </div>

            <div class="col-12 d-lg-none p-0">
              <hr />
            </div>

            <div class="col-12 col-lg-auto ml-xl-auto d-flex align-items-center">
              <!-- 編集を破棄する -->
              <a
                id="ButtonReload"
                class="btn btn-info link-btn btn-sm w-200 w-lg-auto px-6 add-btn-txt"
                role="button"
                target="_blank"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-3" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                v-on:click="onClickButtonReload()"
                >編集を破棄する
              </a>
              <span class="p-10"></span>
              <!-- 保存ボタン -->
              <a
                id="ButtonSave"
                class="btn btn-info link-btn btn-sm w-lg-auto px-6 add-btn-txt"
                role="button"
                target="_blank"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-3" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                v-on:click="onClickButtonSave()"
                >保存する
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- ファイル削除設定一覧 -->
      <div class="row">
        <div class="col-md-12">
          <div class="bgc-white bd bdrs-3 p-20 mB-20">
            <p id="table-title" hidden ></p>
            <table  aria-describedby="table-title" id="dataTable" class="table table-striped table-bordered table-hover text-break" aria-hidden="true">
              <thead>
                <tr class="thead-light">
                  <th scope="col" style="min-width:65px; max-width:65px">作業番号</th>
                  <th scope="col">対象フォルダ名</th>
                  <th scope="col" style="min-width:70px; max-width:70px" >設定日</th>
                  <th scope="col" style="min-width:85px; max-width:85px">操作</th><!--アクション-->
                  <th scope="col" style="min-width:80px; max-width:80px">データ保持</th>
                  <th scope="col" style="min-width:65px; max-width:65px">事前通知</th>
                  <th scope="col">移動先フォルダ名</th>
                  <th scope="col" style="min-width:35px; max-width:35px">状態</th>
                  <th scope="col" style="min-width:65px; max-width:65px">実行状況</th>
                  <th scope="col">削除</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, idx) in delete_files_list" :key="idx" :value="item">
                  <!--作業番号-->
                  <td>{{item.id}}</td>
                  <!--対象フォルダ名-->
                  <td style="padding:0px">
                    <table  aria-describedby="table-title">
                      <thead hidden>
                        <tr>
                          <th scope="col">icon</th> 
                          <th scope="col">text</th> 
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="background-color:transparent">
                          <td
                            class="td-n fsz-md"
                            v-bind:id="'ButtonSelectTarget' + idx"
                            v-bind:class="{'c-deep-purple-500 cH-blue-500': ! isGrayOut(idx, item)}"
                            style="cursor: pointer;  padding-top : 15px"
                            v-on:click="onClickButtonSelectFolder(1, idx, item)"
                          >
                            <svg class="bi" width="26" height="26" fill="currentColor"
                              v-bind:style="{'color:gray' : isGrayOut(idx, item)}"
                            >
                              <use xlink:href="/img/bootstrap-icons.svg#folder2-open"/>
                            </svg>
                          </td>
                          <td 
                            v-bind:id="'FolderNameTarget' + idx"
                            v-bind:data-original-title="item.target.folder_name"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                            >{{item.target.display_name}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <!--設定日-->
                  <td v-bind:id="'SettingDateAt' + idx">{{item.setting_date_at.substr(0,10)}}</td>
                  <!--操作-->
                  <td style="padding:0px">
                    <table  aria-describedby="table-title">
                      <thead hidden>
                        <tr>
                          <th scope="col">icon</th> 
                          <th scope="col">text</th> 
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="background-color:transparent">
                          <td
                            class="td-n fsz-md"
                            v-bind:id="'ButtonEdit' + idx"
                            v-bind:class="{'c-deep-purple-500 cH-blue-500': ! isGrayOut(idx, item)}"
                            style="cursor: pointer; padding-top : 15px"
                            :disabled=true
                            v-on:click="onClickButtonEdit(idx, item)"
                          >
                            <svg class="bi" width="26" height="26" fill="currentColor"
                              v-bind:style="{'color:gray' : isGrayOut(idx, item)}"
                            >
                              <use xlink:href="/img/bootstrap-icons.svg#pencil"/>
                            </svg>
                          </td>
                          <td v-bind:id="'ActionInfAction' + idx">{{action_text[item.action_inf.action_value]}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <!--データ保持-->
                  <td v-bind:id="'ActionInfPeriod' + idx">{{item.action_inf.period.text}}</td>
                  <!--事前通知-->
                  <td v-bind:id="'ActionInfNotice' + idx">{{item.action_inf.notice.text}}</td>
                  <!--移動先フォルダ名-->
                  <td style="padding:0px">
                    <table  aria-describedby="table-title" v-if="!item.action_inf.disabled.move_to">
                      <thead hidden>
                        <tr>
                          <th scope="col">icon</th> 
                          <th scope="col">text</th> 
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="background-color:transparent">
                          <td
                            class="td-n fsz-md"
                            v-bind:id="'ButtonSelectMoveTo' + idx"
                            v-bind:class="{'c-deep-purple-500 cH-blue-500': ! isGrayOut(idx, item)}"
                            style="cursor: pointer; padding-top : 15px"
                            v-on:click="onClickButtonSelectFolder(2, idx, item)"
                          >
                            <svg class="bi" width="26" height="26" fill="currentColor"
                              v-bind:style="{'color:gray' : isGrayOut(idx, item)}"
                            >
                              <use xlink:href="/img/bootstrap-icons.svg#folder2-open"/>
                            </svg>
                          </td>
                          <td
                            v-bind:id="'FolderNameMoveTo' + idx"
                            v-bind:data-original-title="item.move_to.folder_name"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                          >{{item.move_to.display_name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <!--状態-->
                  <td>
                    <div class="custom-control custom-switch">
                      <input type="checkbox" v-bind:id="'switchExecute' + idx"
                        class="custom-control-input"
                        v-model="item.execute"
                        v-on:change="onChangeSwitchStatus(idx, item)"
                      />
                      <label class="custom-control-label" v-bind:for="'switchExecute' + idx" ></label>
                    </div>
                  </td>
                  <!--実行状況-->
                  <td v-bind:id="'CreationStatus' + idx">
                    <span v-if="item.creation_status !== null">
                      {{status_text[item.creation_status]}}
                    </span>
                  </td>
                  <!--削除-->
                  <td>
                    <div
                      class="td-n fsz-md"
                      v-bind:id="'ButtonDelete' + idx"
                      v-bind:class="{'c-deep-purple-500 cH-blue-500': ! isGrayOut(idx, item)}"
                      style="cursor: pointer"
                      v-on:click="onClickButtonDelete(idx, item)"
                    >
                      <svg class="bi" width="26" height="26" fill="currentColor"
                        v-bind:style="{'color:gray' : isGrayOut(idx, item)}"
                      >
                        <use xlink:href="/img/bootstrap-icons.svg#trash"/>
                      </svg>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- ファイル削除設定一覧 -->
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';
import * as $ from 'jquery';
import axios from 'axios';

import LoginChecker         from '@/pages/box/common/LoginChecker.vue';
import LoginCheckerForLocal from '@/pages/box/common/LoginCheckerForLocal.vue';

import DialogConfirm                from '@/pages/box/dialogs/DialogConfirm.vue';
import DialogFileList               from '@/pages/box/dialogs/DialogFileList.vue';
import DialogFileInventoryOperation from '@/pages/box/dialogs/DialogFileInventoryOperation.vue';

// チェックボックスのソート順
$.fn.dataTable.ext.order['dom-checkbox'] = function (settings, col) {
  return this.api()
    .column(col, { order: 'index' })
    .nodes()
    .map(function (td) {
        return $('input', td).prop('checked') ? '1' : '0';
    });
};

//確認ダイアログの呼び出し元（この画面内だけの定数）
export const DialogCall = {
  SAVE:   1,
  WRN:    2,
  DELETE: 3,
  RELOAD: 4,
}

export default {
  name: 'BoxFileInventoryOperation',
  mixins: [apiMixin, tooltipMixin],

  components: {
    'component-login-checker': LoginChecker,
    'component-login-checker-local': LoginCheckerForLocal,

    'component-dialog-confirm': DialogConfirm,
    'component-dialog-file-list': DialogFileList,
    'component-dialog-file-inventory-operation': DialogFileInventoryOperation,
  },

  data() {
    return {
      isLocal: false,
      messages: [],
      delete_files_list: [],            //一覧（最新の状態）
      delete_files_list_clone: [],      //一覧（画面起動時／DBからの値を保持）
      action_text: {1: '削除', 2: '移動'},
      status_text: {1: '開始', 2: '完了', 3:'失敗'},

      data_table: null,

      new_record_idx: 1,                // レコード追加時のID値を保持
      max_records: 100,                 // 最大登録件数
      read_records: 0,                  // 読込データ数

      selected: {
        call: -1,
        idx : -1,
        item: null,
      },

      logo: [],
    }
  },

  mounted() {
    this.checkLogin();
    this.getLogoUrl();
    this.getFileDeleteList();
  },

  updated() {
    this.initTooltip();
  },

  methods: {
    //-----
    //内部メソッド
    //-----
    // checkLogin: async function (code) {
    checkLogin: async function () {
      // console.log('FileInventoryOperation>>checkLogin');
      this.isLocal = await this.getApi(`box/isLocal`);

      if (this.isLocal) {
        // console.log('FileInventoryOperation>>isLocal: local');
        this.$refs.loginCheckerForLocal.checkLogin();
      } else {
        // console.log('FileInventoryOperation>>isLocal: other');
        this.$refs.loginChecker.checkLogin();
      }
    },
    redirectSelf: function () {
      //DataTableを更新しても一覧が正常に更新できないため
      //自分自身にリダイレクトして画面を再描画させる
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    //Linkボタンの活性／非活性
    disableLinkButton(name, disabled) {
      //Linkボタンの場合、vuejs の :disabled="変数名" が無視されてしまい、
      //活性／非活性ができないので getElementById で対応
      if (disabled == true) {
        document.getElementById(name).classList.add("disabled");
        return;
      }
      document.getElementById(name).classList.remove("disabled");
    },
    //追加ボタンを非活性／活性にする（登録件数をもとに）
    enableButtonAppend: function() {
      // console.log('FileInventoryOperation>>enableButtonAppend')

      let count = this.delete_files_list.length;
      this.disableLinkButton('ButtonAppend', count >= this.max_records);
      this.disableLinkButton('ButtonSave', false);
      this.disableLinkButton('ButtonReload', false);
    },
    //インスタンスをクローンする
    cloneObject: function(target) {
      return JSON.parse(JSON.stringify(target));
    },
    isGrayOut: function(idx, item) {
      return (item.creation_status === 1)
    },
    //フルパスから最後のパスを取得する
    convertShortName: function(list) {
      //フォルダパスから短い名前を取得する
      let shortName = function(folder_name, delimitor = '/') {
        if (folder_name == null) {
          return folder_name;
        }

        if (folder_name.indexOf('/') < 0) {
          return folder_name;
        }

        let arr = folder_name.split(delimitor);
        return arr[arr.length - 1];
      }

      //表示名を設定する
      for (let key in list) {
        list[key].target.display_name  = shortName(list[key].target.folder_name);
        list[key].move_to.display_name = shortName(list[key].move_to.folder_name);
      }

      return list;
    },
    //バックグラウンドから一覧を取得
    getFileDeleteList: async function () {
      // DBから一覧取得
      axios
        .get('/api/box/getFileInventory', {
        })
        .then((res) => {
          this.delete_files_list = this.convertShortName(res.data);
          this.delete_files_list_clone = this.cloneObject(this.delete_files_list);
          // 読込データ数を保持
          this.read_records = this.delete_files_list.length;
          // console.log(this.delete_files_list);
          // 追加レコード初期値を設定
          this.new_record_idx = 1;
          // データテーブル作成（データテーブルが存在する場合は破棄）
          this.createDataTable(true);
          // 追加ボタン非活性
          // ※dataTable の on('draw')が無視されるので強制的に呼び出す
          this.enableButtonAppend();
        })
        .catch((err) => { // eslint-disable-line
          // console.log(err);
          this.delete_files_list = [];
          // 読込データなし
          this.read_records = 0;
          this.new_record_idx = 1;
          // データテーブル作成（データテーブルが存在する場合は破棄）
          this.createDataTable(true);
          // 追加ボタン非活性
          // ※dataTable の on('draw')が無視されるので強制的に呼び出す
          this.enableButtonAppend();
          this.messages.push('設定の読込に失敗しました。');
        });
    },
    createDataTable: function(deleteRow = false) {
      if (this.data_table != null) {
        this.data_table.state.clear();
        this.data_table.destroy();
        if (deleteRow) {
          $("#dataTable > tbody > tr").remove();
        }
      }

      let html_menu
          = '<div class="form-inline mb-3">'
          + '<select class="form-control">'
          + '<option>10</option>'
          + '<option>25</option>'
          + '<option>50</option>'
          + '<option>100</option>'
          + '</select>'
          + '<span class="my-1 ml-2">件表示'  //</span>を追加すると行間が広がるため削除している
          + '</div>'
      ;

      const html_search
          = '<div class="input-group js-date-search" data-e2e="list_search">'
          + '_INPUT_'
          + '<div class="input-group-append">'
          + '<span class="input-group-text" >'
          + '<svg class="bi" width="26" height="20" fill="currentColor">'
          + '<use xlink:href="/img/bootstrap-icons.svg#search"/>'
          + '</svg>'
          + '</span>'
          + '</div>'
          + '</div>'
      ;

      // DataTables内から呼び出すため、現在のthisを保持
      let me = this;

      setTimeout(() =>
        this.data_table = $('#dataTable').DataTable({
          destroy: true,
          order: [[ 0, 'desc' ]],
          autowidth: false,
          oLanguage: {
            sEmptyTable: '現在、棚卸(削除・移動)設定が登録されていません',
            sInfo: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
            sInfoEmpty: ' 0 件中 0 から 0 まで表示',
            sInfoFiltered: '（全 _MAX_ 件より抽出）',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu: html_menu,
            sLoadingRecords: '読み込み中...',
            sProcessing: '処理中...',
            sSearch: html_search,
            sSearchPlaceholder: 'コンテンツ内検索',
            sZeroRecords: '棚卸(削除・移動)設定が0件です。',
            oPaginate: {
              sFirst: '先頭',
              sLast:  '最終',
              sNext:  '次',
              sPrevious: '前',
            },
          },

          columnDefs: [
            { targets: [0], width: '5%' },  //作業番号
            { targets: [1], width: '25%' }, //ボタン「選択」・対象フォルダ名
            { targets: [2], width: '11%' }, //ボタン「編集」・アクションまでの時間
            { targets: [3], width: '5%' },  //設定日
            { targets: [4], width: '4%' },  //事前通知
            { targets: [5], width: '8%'  }, //アクション
            { targets: [6], width: '25%' }, //ボタン「選択」・移動先フォルダ
            {
              targets: [7],                 //状態
              width: '40px',
              className: 'text-center',
              orderDataType: 'dom-checkbox',
            },
            { targets: [8], width: '40px' },  //実行状況
            {
              targets: [9],                 //削除
              orderable: false,
              width: '40px',
              className: 'edit-button-col text-center',
            },
          ],
        }).on('draw', function () {
          // console.log("FileInventoryOperation>>createDataTable>>on('draw')");
          me.enableButtonAppend();
        })
        ,0
      );
    },
    //チェック対象を取得する
    getCheckTarget: function(idx) {
      //DBから取得した場合は、画面表示時の値を使用して値をチェックする
      let check_target = this.delete_files_list[idx];           //新規追加の場合（n《連番》）
      if (typeof this.delete_files_list[idx].id != 'string') {
        check_target = this.delete_files_list_clone[idx];       //DBから取得した場合（DBレコード番号）
      }
      return check_target;
    },
    //値が更新されているかどうかをチェック（true:更新されている、false:更新されていない）
    isModifiedActionInf: function(check_target, action_inf) {
      // console.log('FileInventoryOperation>>isModifiedActionInf>> period.value :'    + check_target.period.value     + ' new value :'  + action_inf.period.value);
      // console.log('FileInventoryOperation>>isModifiedActionInf>> period.custom:'    + check_target.period.custom    + ' new custom:'  + action_inf.period.custom);
      // console.log('FileInventoryOperation>>isModifiedActionInf>> notice.value :'    + check_target.notice.value     + ' new value :'  + action_inf.notice.value);
      // console.log('FileInventoryOperation>>isModifiedActionInf>> notice.custom:'    + check_target.notice.custom    + ' new custom:'  + action_inf.notice.custom);
      // console.log('FileInventoryOperation>>isModifiedActionInf>> action.value :'    + check_target.action_value     + ' new action:'  + action_inf.action_value);
      // console.log('FileInventoryOperation>>isModifiedActionInf>> disabled.move_to:' + check_target.disabled.move_to + ' new move_to:' + action_inf.disabled.move_to);

      //アクションまでの期間
      if (check_target.period.value != action_inf.period.value) {
        return true;
      }
      if (check_target.period.custom != action_inf.period.custom) {
        return true;
      }
      //事前通知
      if (check_target.notice.value != action_inf.notice.value) {
        return true;
      }
      if (check_target.notice.custom != action_inf.notice.custom) {
        return true;
      }
      //アクション
      if (check_target.action_value != action_inf.action_value) {
        return true;
      }
      return false;
    },
    //（入力エラーチェック）OK:true、NG:false
    validateErr: function() {
      let check_target = this.cloneObject(this.delete_files_list);
      let codes = this.$refs.dlg_editOperation.codes();

      //  登録件数なし かつ 読込データなしの場合
      //  ※読込データあり、登録件数なしの場合は、レコードを削除する必要あり
      if ((check_target.length < 1) && (this.read_records < 1)) {
        this.messages.push('登録可能なデータが １件もありません');
      }
     
      //対象フォルダ名、移動先フォルダ名の入力チェック
      let f_target = '';
      let f_moveto = '';
      for (let key in check_target) {
        let item = check_target[key];

        //入力値チェック
        if (item.target.folder_id == '') {
          this.messages.push('作業番号「' + item.id + '」の「対象フォルダ名」が設定されていません');
          continue;
        }
        if ((item.action_inf.action_value == codes.action.move_to)
         && (item.move_to.folder_id == '')
        ) {
          this.messages.push('作業番号「' + item.id + '」の「移動先フォルダ名」が設定されていません');
          continue;
        }
        //対象フォルダに フォルダID=0 が指定された
        if (item.target.folder_id == '0') {
          this.messages.push('作業番号「' + item.id + '」の「対象フォルダ名」に「すべてのファイル」は指定できません');
          continue;
        }

        //削除の場合、移動先はチェックしない
        if (item.action_inf.action_value == codes.action.delete) {
          continue;
        }
        //移動の場合
        //移動先フォルダ と 対象フォルダ が同じとき
        if (item.target.folder_id == item.move_to.folder_id) {
          this.messages.push('作業番号「' + item.id + '」の「対象フォルダ名」と「移動先フォルダ名」が同じです');
          continue;
        }
        //移動先フォルダ名 と 対象フォルダ名 が前方一致するとき
        f_target = item.target.folder_name + '/';
        f_moveto = item.move_to.folder_name + '/';
        if (f_moveto.indexOf(f_target) === 0) {
          this.messages.push('作業番号「' + item.id + '」の「移動先フォルダ名」に「対象フォルダ名」が含まれます');
          continue;
        }
      }

      //結果
      if (this.messages.length > 0) {
        //作業番号の逆順に並び替え
        this.messages = this.messages.sort((a, b) => {
          return ((a > b) ? -1 : 1);
        });
        return false;
      }
      return true;
    },
    //（関連チェック）OK:true、NG:false
    validateWrn: function() {
      //メッセージを組み立てて返却
      let createMassageWrn = function(messages, map, field) {
        for (const [key, ids] of map.entries()) { // eslint-disable-line
          // console.log('field:' + field + ' key:' + key + ' ids:' + ids);
          if (ids.length < 2) {
            continue;
          }
          messages.push('作業番号「' + ids.join('、') + '」の「' + field + '」が重複しています');
        }
      }

      //チェック対象
      let check_target = this.cloneObject(this.delete_files_list);
      let map = new Map();

      //重複チェック：対象フォルダ
      map.clear();
      for (let key in check_target) {
        // console.log('key:' + key + ' (target)folder_id:' + check_target[key].target.folder_id);

        if (map.has(check_target[key].target.folder_id)) {
          let ids = map.get(check_target[key].target.folder_id);
          ids.push(check_target[key].id);
          continue;
        }
        map.set(check_target[key].target.folder_id, [check_target[key].id,]);
      }
      createMassageWrn(this.messages, map, '対象フォルダ名');

      //結果
      return (this.messages.length < 1);
    },
    //編集を破棄
    executeReload: function() {
      this.redirectSelf();
    },
    //保存処理
    executeSave: function() {
      this.messages = [];

      //非同期で実行するので this.postApi() は使用できない
      axios
        .post("/api/box/saveFileInventory", {
          data: JSON.stringify(this.delete_files_list),
        })
        .then((res) => {  // eslint-disable-line
          // console.log(res);
          // 読込データ数を保存時の件数で置換
          this.read_records = this.delete_files_list.length;
          //画面再表示
          this.$refs.dlg_filelist.close();
          this.redirectSelf();
        })
        .catch((err) => {
          // console.log(err);

          //ログイン画面へ戻す場合
          if (err.request.responseURL.toLowerCase().indexOf('box/login') > -1) {
            this.$refs.loginChecker.redirectTo(err.request.responseURL);
            return;
          }

          //ダイアログを閉じる
          this.messages.push('設定情報の保存に失敗しました');
          this.$refs.dlg_filelist.close();
        });
    },
    executeDelete: function(idx) {
      // console.log('FileInventoryOperation>>executeDelete idx:' + idx);
      this.delete_files_list.splice(idx,1);
      this.delete_files_list_clone = this.cloneObject(this.delete_files_list);

      // データテーブル破棄後、データテーブル作成
      this.createDataTable();
    },
    // 表示するロゴのURLを取得
    getLogoUrl: async function () {
      // API共通処理のGET用メソッドを実行
      this.logo = (await this.getApi(`box/getLogoURL`)) || [];
      if (this.logo.length === 0) {
        this.$router.push({ path: '/404' });
      }
    },
    // -----
    // イベントハンドラ
    // -----
    // ボタン『＋追加』
    //
    // ■デフォルト値
    // 対象フォルダ        ：空欄
    // アクションまでの期間：1年
    // 事前通知            ：1週間
    // アクション          ：移動
    // 移動先フォルダ      ：空欄
    // 状態                ：無効
    // 設定日              ：現在日付
    // 実行状況            ：空欄
    onClickButtonAppend: function () {
      // console.log('FileInventoryOperation>>onClickButtonAppend');

      // 追加ボタンを非活性化する。（DataTablesのdrawイベントで戻す）
      this.disableLinkButton('ButtonAppend', true);
      this.disableLinkButton('ButtonSave', true);
      this.disableLinkButton('ButtonReload', true);

      //デフォルト値
      let types = this.$refs.dlg_editOperation.types();
      let codes = this.$refs.dlg_editOperation.codes();

      let dd = new Date();                                //設定日
      let setting_date_text  = dd.toISOString();          //表示用

      let action_date_value = codes.period.year01;        //アクションまでの期間
      let action_date_text  = this.$refs.dlg_editOperation.getTextFromValue(types.period, action_date_value);   //表示用

      let notice_date_value = codes.notice.week01;        //事前通知
      let notice_date_text  = this.$refs.dlg_editOperation.getTextFromValue(types.notice, notice_date_value);   //表示用

      let action_move_value = codes.action.move_to;       //アクション

      // 追加ボタンで追加する要素
      let item = {
        id: 'n' + String(this.new_record_idx).padStart(3, '0'), //DBのレコード番号('n999')
        setting_date_at: setting_date_text,         // 設定日
        creation_status: '',                  // 実行状況
        // del: false,                           //DBのレコードが削除されたか
        modified: false,                      //修正されたか
        target: {                             //対象
          folder_name: '',                      //対象フォルダ名
          folder_id: '',                        //対象フォルダID
          display_name: '',                     //表示名
        },

        action_inf: {                         //アクション情報（ダイアログに渡すデータ構造）
          period: {                             //アクションまでの期間
            text:  action_date_text,              //表示用
            value: action_date_value,             //設定値(0:カスタム、1~n)
            custom: -1,                           //カスタム入力値（-1:未入力）
          },
          notice: {                            //事前通知
            text:  notice_date_text,              //表示用
            value: notice_date_value,             //設定値(0:カスタム、1～n)
            custom: -1,                           //カスタム入力値（-1:未入力）
          },
          action_value: action_move_value,    //アクション

          disabled: {                         //非活性にするかどうか
            move_to: false,                     //項目「移動先フォルダ名」（アクション＝削除：true、移動：false）
          }
        },//action_inf

        move_to: {                            //移動先
            folder_name: '',                      //フォルダ名
            folder_id: '',                        //id
            display_name: '',                     //表示名
        },  
        execute: false,                       //状態
      };
      // unshiftだと順番が入れ替わる（idが逆になる）ため、pushしてidを降順にソートして表示
      this.delete_files_list.push(item);
      this.delete_files_list_clone = this.cloneObject(this.delete_files_list);

      //レコードインデックス
      this.new_record_idx++;

      // データテーブル破棄後、データテーブル作成
      this.createDataTable();
    },
    //（コールバック）確認ダイアログからの戻り
    onConfirmSelectYes: function(args) {
      // console.log('FileInventoryOperation>>onConfirmSelectYes call:' + args.call);
      // console.log('FileInventoryOperation>>onConfirmSelectYes idx:' + args.idx);

      this.$refs.dlg_confirm.close();
      switch(args.call) {
      case DialogCall.WRN:    { this.executeSave(); break; }            //（保存）ダイアログ『警告』からの戻り
      case DialogCall.SAVE:   { this.executeSave(); break; }            //（保存）ダイアログ『実行確認』からの戻り
      case DialogCall.DELETE: { this.executeDelete(args.idx); break; }  //（行削除）ダイアログ『削除確認』からの戻り
      case DialogCall.RELOAD: { this.executeReload(); break; }          //（編集破棄）ダイアログ『実行確認』からの戻り
      }
    },
    //ボタン『編集を破棄する』
    onClickButtonReload: function() {
      // console.log('FileInventoryOperation>>onClickButtonReload');

      let dlg_args_reload = {
          call: DialogCall.RELOAD,
          title: '実行確認',
          messages: [
            '編集内容を破棄しますか？'
          ],
          btn: {
              caption: {
                  yes: 'はい',
                  no:  'いいえ',
              },
              active: 'no',
          },
      };
      this.$refs.dlg_confirm.show(dlg_args_reload);
    },
    //ボタン『保存』
    onClickButtonSave: function () {
      // console.log('FileInventoryOperation>>onClickButtonSave');

      //メッセージクリア
      this.messages = [];

      //-------
      //エラーチェック
      //-------
      //エラーがある場合は中断
      if (!this.validateErr()) {
        return;
      }

      //警告がある場合は、ダイアログを表示し保存確認
      if (!this.validateWrn()) {
        let dlg_args_wrn = {
          call: DialogCall.WRN,
          title: '警告',
          messages: [
            '警告メッセージがありますが、実行しますか？'
          ],
          btn: {
              caption: {
                  yes: '保存する',
                  no:  'キャンセル',
              },
              active: 'yes',
          },
        };
        this.$refs.dlg_confirm.show(dlg_args_wrn);
        return;
      }

      //実行確認の表示
      let dlg_args_save = {
          call: DialogCall.SAVE,
          title: '実行確認',
          messages: [
            '保存しますか？'
          ],
          btn: {
              caption: {
                  yes: 'はい',
                  no:  'いいえ',
              },
              active: 'yes',
          },
      };
      this.$refs.dlg_confirm.show(dlg_args_save);
    },
    //ボタン『選択』
    onClickButtonSelectFolder: function(call, idx, item) {
      // console.log('FileInventoryOperation>>onClickButtonSelectFolder>> call:' + call);
      // console.log('FileInventoryOperation>>onClickButtonSelectFolder>> idx:'  + idx);
      // console.log('FileInventoryOperation>>onClickButtonSelectFolder>> item:' + item.id);

      if (this.isGrayOut(idx, item)) {
        return;
      }
      this.selected.call = call;
      this.selected.idx  = idx;
      this.selected.item = item;

      //ログインチェック
      if (this.isLocal) {
        this.$refs.loginCheckerForLocal.checkLoginContinue();
      } else {
        this.$refs.loginChecker.checkLoginContinue();
      }
    },
    //コールバック：LoginChecker がエラーの場合
    onShowDialogFileListError: function() {
      // console.log('FileInventoryOperation>>onShowDialogFileListError');
      this.$refs.dlg_filelist.close();
    },
    //コールバック：LoginChecker が正常の場合
    onShowDialogFileList: function() {
      //DBから取得したときに値がないと null が返ってくるので
      //新規（空文字）と DB取得時（null）の場合デフォルト値を返却する
      let checkNull = function(value, default_value) {
        if ((value == null) || (value == '')) {
          return default_value;
        }
        return value;
      };

      let selected = this.selected;
      // console.log('FileInventoryOperation>>onShowDialogFileList>> call:' + selected.call);
      // console.log('FileInventoryOperation>>onShowDialogFileList>> idx :' + selected.idx);

      //ダイアログのパラメータ組み立て
      let dlg_args = { 
          //必須パラメータ
          title: 'フォルダ選択',
          id:    '',
          name:  '',
          //追加パラメータ
          btn: {                //ボタンのキャプション
            caption: {
                execute: '選択',
            },
          },
          showConfirm: false,   //確認ダイアログを表示しない
          call: selected.call,  //呼び出し元の機能
          idx:  selected.idx,   //行番号
      };

      switch(selected.call) {
      case 1: { //対象フォルダ名
          dlg_args.title = dlg_args.title + '：対象フォルダ名'
          dlg_args.id    = checkNull(selected.item.target.folder_id, '0');
          dlg_args.name  = checkNull(selected.item.target.folder_name, '');
          break;
        }//case 1
      case 2: { //移動先フォルダ名
          dlg_args.title = dlg_args.title + '：移動先フォルダ名'
          dlg_args.id    = checkNull(selected.item.move_to.folder_id, '0');
          dlg_args.name  = checkNull(selected.item.move_to.folder_name, '');
          break;
        }//case 2
      }//switch

      //ダイアログ表示
      // console.log('FileInventoryOperation>> ' + dlg_args);
      this.$refs.dlg_filelist.show(dlg_args);
    },
    //コールバック：ダイアログ『フォルダ選択／実行』
    onSelectedFolderId: function(selected) {
      //基本情報
      // console.log('FileInventoryOperation>>onSelectedFolderId>> id:'   + selected.id);
      // console.log('FileInventoryOperation>>onSelectedFolderId>> name:' + selected.name);
      // console.log('FileInventoryOperation>>onSelectedFolderId>> full_name:' + selected.full_name);

      // console.log('FileInventoryOperation>>onSelectedFolderId>> call:' + selected.call);
      // console.log('FileInventoryOperation>>onSelectedFolderId>> idx:'  + selected.idx);
      this.$refs.dlg_filelist.close();
      let idx = selected.idx;

      let check_target = this.getCheckTarget(idx);
      switch(selected.call) {
      case 1: { //対象フォルダ名
          if ((check_target.target.folder_id   != selected.id)
           || (check_target.target.folder_name != selected.full_name)
          ) {
            this.delete_files_list[idx].modified = true;
            this.delete_files_list[idx].target.folder_id    = selected.id;
            this.delete_files_list[idx].target.folder_name  = selected.full_name;
            this.delete_files_list[idx].target.display_name = selected.name;
          }
          break;
        }
      case 2: { //移動先フォルダ名
          if ((check_target.move_to.folder_id   != selected.id)
           || (check_target.move_to.folder_name != selected.full_name)
          ) {
            this.delete_files_list[idx].modified = true;
            this.delete_files_list[idx].move_to.folder_id    = selected.id;
            this.delete_files_list[idx].move_to.folder_name  = selected.full_name;
            this.delete_files_list[idx].move_to.display_name = selected.name;
          }
          break;
        }
      }
    },
    //ボタン『編集』
    onClickButtonEdit: function(idx, item) {
      // console.log('FileInventoryOperation>>onClickButtonEdit>>idx:' + idx + ' => creation_status:' + item.creation_status);

      if (this.isGrayOut(idx, item)) {
        return;
      }
      this.$refs.dlg_editOperation.show(idx, item.action_inf);
    },
    //コールバック：ダイアログ『アクション設定編集』
    onSelectedAction: function(idx, action_inf) {
      // console.log('FileInventoryOperation>>onSelectedAction>> idx:' + idx);
      // console.log('FileInventoryOperation>>onSelectedAction>> action_inf:' + action_inf);

      //チェック対象を選択する
      let check_target = this.getCheckTarget(idx);

      if (this.isModifiedActionInf(check_target.action_inf, action_inf)) {
        this.delete_files_list[idx].action_inf = action_inf;
        this.delete_files_list[idx].modified   = true;
      }
    },
    //スイッチ『状態』
    onChangeSwitchStatus: function(idx, item) {
      // console.log('FileInventoryOperation>>onClickSwitchStatus>> idx:' + idx);
      // console.log('FileInventoryOperation>>onClickSwitchStatus>> item.id' + item.id);

      if (this.isGrayOut(idx, item)) {
        return;
      }
      //値が更新されていたら modified を設定する
      let clone = this.delete_files_list_clone[idx];
      let target = this.delete_files_list[idx];
      target.modified = (clone.execute !== target.execute);
    },
    //ボタン『削除』
    onClickButtonDelete: function(idx, item) {
      // console.log('FileInventoryOperation>>onSelectedAction>> idx:' + idx);
      // console.log('FileInventoryOperation>>onSelectedAction>> item.id' + item.id);

      if (this.isGrayOut(idx, item)) {
        return;
      }

      let dlg_args_delete = {
        call: DialogCall.DELETE,
        idx: idx,
        title: '削除確認',
        messages: [
          '削除しますか？',
        ],
        btn: {
            caption: {
                yes: '削除する',
                no:  'キャンセル',
            },
            active: 'yes',
        },
      };
      this.$refs.dlg_confirm.show(dlg_args_delete);
    },
  },
};
</script>

<style scoped>
.alert-secondary {
  padding-left: 10px;
}

.alert-secondary p {
  padding-left: 1em;
  text-indent: -1em;
  margin: 0;
}
</style>

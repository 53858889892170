import { OK, CREATED, UNAUTHORIZED, UNPROCESSABLE_ENTITY, EXCLUSIVE_ERROR, INTERNAL_SERVER_ERROR } from '@/util';

export const apiMixin = {
  methods: {
    // GETの共通処理
    async getApi(url) {
      this.setMultiCloudPortalCookie();
      try {
        this.$store.commit('view/pushLoading');
        const response = await this.axios.request({
          url: `/api/${url}`,
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'get',
        });

        const data = response.data;

        if (response.status === OK) {
          this.$store.commit('view/popLoading');
          return data;
        }
      } catch (error) {
        if (error.response.status === UNAUTHORIZED) {
          this.$store.commit('auth/clearUser');
          this.$router.push({ path: '/' });
        } else {
          this.$router.push({ path: '/500' });
        }
        this.$store.commit('view/popLoading');
        console.error('データが正常に取得できませんでした');
        return null;
      }
    },
    // POSTの共通処理
    async postApi(url, data) {
      this.setMultiCloudPortalCookie();
      try {
        const response = await this.axios.request({
          url: `/api/${url}`,
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'post',
          data: data,
        });

        if (response.status === CREATED || response.status === OK) {
          if (response.data) {
            return { result: true, data: response.data };
          }
          // 成功
          return { result: true };
        }
      } catch (error) {
        if (error.response.status === UNPROCESSABLE_ENTITY) {
          // バリデーションエラー
          return { result: false, message: error.response.data.message, errors: error.response.data.errors };
        } else if (error.response.status === UNAUTHORIZED) {
          this.$store.commit('auth/clearUser');
          this.$router.push({ path: '/' });
          return { result: false };
        } else {
          this.$router.push({ path: '/500' });
          return { result: false };
        }
      }

      // その他エラー
      return { result: false };
    },
    // PUTの共通処理
    async putApi(url, data) {
      this.setMultiCloudPortalCookie();
      try {
        const response = await this.axios.request({
          url: `/api/${url}`,
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'put',
          data: data,
        });

        if (response.status === CREATED || response.status === OK) {
          // 成功
          return { result: true };
        }
      } catch (error) {
        if (error.response.status === UNPROCESSABLE_ENTITY) {
          // バリデーションエラー
          return { result: false, message: error.response.data.message, errors: error.response.data.errors };
        } else if (error.response.status === EXCLUSIVE_ERROR) {
          // 楽観排他エラー
          return { result: false, exclusive: error.response.data.exclusive };
        } else if (error.response.status === UNAUTHORIZED) {
          this.$store.commit('auth/clearUser');
          this.$router.push({ path: '/' });
          return { result: false };
        } else {
          this.$router.push({ path: '/500' });
          return { result: false };
        }
      }

      // その他エラー
      return { result: false };
    },
    // DELETEの共通処理
    async deleteApi(url) {
      this.setMultiCloudPortalCookie();
      try {
        const response = await this.axios.request({
          url: `/api/${url}`,
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'delete',
        });
        if (response.status === OK) {
          // 成功
          return { result: true };
        }
      } catch (error) {
        if (error.response.status === EXCLUSIVE_ERROR) {
          // 楽観排他エラー
          return { result: false, exclusive: error.response.data.exclusive };
        } else if (error.response.status === UNAUTHORIZED) {
          return { result: false, authError: true };
        } else if (error.response.status === INTERNAL_SERVER_ERROR) {
          return { result: false };
        } else {
          return { result: false };
        }
      }

      // その他エラー
      return { result: false };
    },
    setMultiCloudPortalCookie: function () {
      const cookies = document.cookie;
      const cookiesArray = cookies.split(';');
      let sendCookies = [];
      const targetCookieId = ['XSRF-TOKEN', 'multicloudportal-key'];
      for (let c of cookiesArray) {
        const cArray = c.trim().split('=');
        if (targetCookieId.some((target) => target === cArray[0])) {
          // 取り出したいkeyと合致したら
          sendCookies.push(c);
        }
      }
    },
  },
};

<template>
  <main class="main-content content bgc-grey-200">
    <div>
      <h4 class="c-grey-900 mt-3" data-e2e="page_title_name">パスワード変更</h4>
    </div>

    <div class="masonry-item">
      <div class="bgc-white p-20 bd card col-md-8">
        <form>
          <!-- パスワード -->
          <div class="form-group">
            <label for="password">新しいパスワード</label>
            <input
              :class="'form-control h-75' + (errors && errors.password ? ' is-invalid' : '')"
              type="password"
              id="password"
              placeholder=""
              v-model="user.password"
              name="password"
              ref="password"
              data-e2e="new_password"
            />
            <div class="invalid-feedback">{{ errors.password }}</div>
          </div>

          <!-- パスワード再入力 -->
          <div class="form-group">
            <label for="password_confirmation">新しいパスワード(確認用)</label>
            <input
              :class="'form-control h-75' + (errors && errors.password_confirmation ? ' is-invalid' : '')"
              type="password"
              id="password_confirmation"
              placeholder=""
              v-model="user.password_confirmation"
              name="password_confirmation"
              data-e2e="new_password_confirmation"
            />
            <div class="invalid-feedback">{{ errors.password_confirmation }}</div>
          </div>

          <!-- パスワードポリシー  -->
          <div class="mt-2">
            <PasswordPolicy :passwordPolicyMin="this.passwordPolicyMin" :passwordPolicyMax="this.passwordPolicyMax" />
          </div>
        </form>
        <div class="d-flex justify-content-center">
          <button
            v-if="!isLoading"
            type="button"
            class="btn btn-info btn-sm"
            @click="submit()"
            data-e2e="password_change_submit"
          >
            パスワード変更
          </button>
          <button v-show="isLoading" class="btn btn-info btn-sm" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            パスワード変更…
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PasswordPolicy from '@/components/PasswordPolicy.vue';
import { apiMixin } from '@/mixins/api.js';

export default {
  name: 'PasswordReset',
  mixins: [apiMixin],
  components: {
    PasswordPolicy,
  },
  data() {
    return {
      constants: [],
      changeLink: '',
      response: '',
      errors: null,
      isLoading: false,
      user: null,
      passwordPolicyMin: null,
      passwordPolicyMax: null,
      passwordPolicyRegex: null,
    };
  },
  created() {
    this.initialization();
    this.getConstant();
  },
  mounted() {
    this.$refs.password.focus();
  },
  methods: {
    initialization() {
      this.user = {
        password: '',
        password_confirmation: '',
      };

      this.errors = {
        password: '',
        password_confirmation: '',
      };
    },

    // 定数情報を取得
    async getConstant() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.constants = (await this.getApi('constant')) || [];

      for (const i in this.constants) {
        // パスワード最小文字数
        if (this.constants[i].key == 'passwordPolicyMin') {
          this.passwordPolicyMin = this.constants[i].value;
        }
        // パスワード最大文字数
        if (this.constants[i].key == 'passwordPolicyMax') {
          this.passwordPolicyMax = this.constants[i].value;
        }
        // パスワード正規表現
        if (this.constants[i].key == 'passwordPolicyRegex') {
          this.passwordPolicyRegex = this.constants[i].value;
        }
      }
    },

    // パスワードリセットリンクの送信
    async submit() {
      // バリデーション
      if (this.validater()) {
        await this.postUserPass();
      } else {
        this.$toasted.error('入力内容に誤りがあります');
      }
    },
    // 入力内容の検証
    validater() {
      this.errors = {
        email: '',
      };

      let result = true;

      // NEWパスワード
      if (this.user.password.length === 0) {
        result = false;
        this.errors.password = '新しいパスワードを入力してください。';
      } else if (
        this.user.password.length < this.passwordPolicyMin ||
        this.passwordPolicyMax < this.user.password.length
      ) {
        result = false;
        this.errors.password =
          '新しいパスワードの長さは' +
          this.passwordPolicyMin +
          '文字以上' +
          this.passwordPolicyMax +
          '文字以下で入力してください。';
      } else if (!RegExp(this.passwordPolicyRegex).test(this.user.password)) {
        result = false;
        this.errors.password = 'パスワードの条件に則って入力してください。';
      }

      // NEWパスワード再入力
      if (this.user.password_confirmation.length === 0) {
        result = false;
        this.errors.password_confirmation = '新しいパスワード(確認用)を入力してください';
      } else if (this.user.password_confirmation !== this.user.password) {
        result = false;
        this.errors.password_confirmation = '新しいパスワードと同じ値を入力してください。';
      }
      return result;
    },
    // パスワードリセットリンクを送信する
    async postUserPass() {
      this.isLoading = true;
      // API共通処理（apiMixin）のPOST用メソッドを実行
      const response = (await this.postApi('password/change', this.user)) || { result: false };
      this.isLoading = false;
      if (response.result) {
        if (response.data && response.data.message) {
          this.$toasted.success(response.data.message);
          this.initialization();
        } else {
          this.$toasted.success('パスワードの変更が完了しました');
          setTimeout(this.$router.push({ path: '/user' }), 3000);
        }
      }
    },
  },
};
</script>

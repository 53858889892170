<template>
  <main class="main-content content bgc-grey-200">
    <div>
      <h4 class="c-grey-900 mt-3" data-e2e="page_title_name">プロフィール</h4>
    </div>

    <!-- プロフィール -->
    <div class="masonry-item card" v-if="user">
      <div class="bgc-white p-20 bd">
        <!-- 会社 -->
        <div class="form-group d-flex align-items-center">
          <label for="name" class="col-sm-3 col-form-label">
            <span id="name" class="decoration font-weight-bold">会社</span>
          </label>
          <div class="col-sm-9">
            {{ user.company_kanji }}
          </div>
        </div>

        <!-- 部署 -->
        <div class="form-group d-flex align-items-center">
          <label for="name" class="col-sm-3 col-form-label">
            <span id="name" class="decoration font-weight-bold">部署</span>
          </label>
          <div class="col-sm-9">
            {{ user.department_kanji }}
          </div>
        </div>

        <!-- 名前 -->
        <div class="form-group d-flex align-items-center">
          <label for="name" class="col-sm-3 col-form-label">
            <span id="name" class="decoration font-weight-bold">名前</span>
          </label>
          <div class="col-sm-9">
            {{ user.name }}
          </div>
        </div>

        <!-- メールアドレス -->
        <div class="form-group d-flex align-items-center">
          <label for="email" class="col-sm-3 col-form-label">
            <span id="email" class="decoration font-weight-bold">メールアドレス</span>
          </label>
          <div class="col-sm-9">
            {{ user.email }}
          </div>
        </div>
        <!-- タイプ（ロール） -->
        <div class="form-group d-flex align-items-center">
          <label for="role" class="col-sm-3 col-form-label">
            <span id="role" class="decoration font-weight-bold">権限</span>
          </label>
          <div class="col-sm-9">
            {{ user.role }}
          </div>
        </div>
        <!-- 表示クラウド Zoom -->
        <div class="form-group d-flex align-items-top">
          <label for="cloudService" class="col-sm-3 col-form-label">
            <span id="cloudService" class="decoration font-weight-bold">アプリ</span>
          </label>
          <div class="col-sm-9">
            <div class="flex-row bd-highlight">
              <span v-for="app in user.apps" :key="app.id">
                <img v-if="app.id != 13" class="img-width" :src="app.logo_url" :alt="app.name" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';

export default {
  name: 'Profile',
  mixins: [apiMixin],
  data() {
    return {
      user: null,
    };
  },
  beforeCreate() {
    // 自分自身しか閲覧できないように制御
    if (this.$route.params['id'] != this.$store.getters['auth/id']) {
      this.$router.push({ path: '/403' });
    }
  },
  created() {
    this.getUser();
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    // 表示するユーザー情報を取得
    async getUser() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.user = (await this.getApi(`users/${this.$route.params.id}`)) || null;

      // 権限を日本語に
      if (this.user.role === 1) {
        this.user.role = 'オーナー';
      } else if (this.user.role === 2) {
        this.user.role = '管理者';
      } else if (this.user.role === 3) {
        this.user.role = '一般ユーザー';
      }
    },
  },
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
    role() {
      return this.$store.getters['auth/role'];
    },
  },
};
</script>

<style scoped>
.img-width {
  width: auto;
  height: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
}

.flex-row {
  padding-top: 7px;
}
</style>

<template>
    <div>
        <component-login-checker
            ref="loginChecker"
        />
        <component-dialog-confirm
            ref="dlg_confirm"
            @onClickYes="onConfirmSelectYes"
        />

        <dialog id="dialog-filelist" class="dialog-filelist">
            <!-- タイトル -->
            <div class="dialog-filelist-title">{{ params.title }}</div>

            <div class="dialog-filelist-window">
<!--
                <div>
                    【デバッグ用】<br/>呼び出し元から渡された情報：<br/>
                    <input type="text" readonly="true" v-model="params.name"/>
                    <input type="text" readonly="true" v-model="params.id"/>
                    <br/>親フォルダの情報：<br/>
                    <input type="text" readonly="true" v-model="list_box.parent.id"/> 
                    <input type="text" readonly="true" v-model="list_box.parent.name"/>
                    <br/>選択した情報：<br/>
                    <input type="text" readonly="true" v-model="list_box.selected.id"/> 
                    <input type="text" readonly="true" v-model="list_box.selected.name"/>
                    <br/>選択したフォルダ：<br/>
                    <span style="border:solid;border-width:1px;width:100%">{{getHistoryText(1)}}</span><br/>
                    <span style="border:solid;border-width:1px;width:100%">{{getHistoryText(2)}}</span>
                </div>
 -->
                <!-- ダイアログの状態表示用 -->
                <div class="dialog-filelist-information" >
                    <div  v-show="dlg_status.show" class="dialog-filelist-status">
                        <span class="spinner-border text-info spinner-border-sm" role="status"/>
                        <span>{{ dlg_status.text }}</span>
                    </div>
                    <div class="dialog-filelist-selected-info" style="border-bottom: 1px;">
                        <label hidden>{{ list_box.selected.id }}</label> 
                        <label>{{ list_box.selected.name }}</label> 
                    </div>
                </div>

                <!--  リストボックス -->
                <ul class="list-group dialog-filelist-list-box">
                    <!-- リストボックスの要素 -->
                    <li class="list-group-item list-group-item-action dialog-filelist-list-box-item"
                        v-for="item in list_box.items" :key="item.id" :value="item" 
                        v-on:click="onClickListBoxItem(item)"
                        v-bind:class="{'active': item.active, 'bg-info': item.active}"
                    >
                        <svg class="bi mr-3" width="26px" height="24px">
                            <use xlink:href="/img/bootstrap-icons.svg#folder" />
                        </svg>
                        {{ item.name }}
                    </li>
                </ul>

                <!-- コマンド 
                    talbe に 以下を設定しないと SonerQube でエラーになる
                    ・aria-describedby の指定
                    ・thead、tbody タグの記述
                -->
                <div class="dialog-filelist-commands">
                    <p id="table-title" hidden></p>
                    <table aria-describedby="table-title" style="width:100%;">
                        <thead class="d-none">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" class="d-none"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <!-- 左側 -->
                                <td>
                                    <button type="button" id="btn_move_up" class="btn btn-sm btn-light" style="margin-right:6px;"
                                        :disabled="ctrl_state.move_up.disabled"
                                        v-on:click="onMoveParentFolder"
                                    >
                                        <svg class="bi" width="12px" height="12px" fill="currentColor">
                                            <use xlink:href="/img/bootstrap-icons.svg#arrow-90deg-up"/>
                                        </svg>
                                        前の階層へ戻る
                                    </button>

                                    <button type="button" id="btn_move_top" class="btn btn-sm btn-light"
                                        :disabled="ctrl_state.move_top.disabled"
                                        v-on:click="onMoveTopFolder"
                                    >
                                        <svg class="bi" width="16px" height="16px" fill="currentColor">
                                            <use xlink:href="/img/bootstrap-icons.svg#arrow-bar-up"/>
                                        </svg>
                                        １番上の階層へ戻る
                                    </button>
                                </td>
                                <!-- 右側 -->
                                <td style="text-align:right;">
                                    <button type="button" id="btn_cancel" class="btn btn-light btn-sm" style="margin-right:6px;"
                                        :disabled="ctrl_state.cancel.disabled"
                                        v-on:click="onClickCancel"
                                    >{{btn.caption.cancel}}
                                    </button>
                                    <button type="button" id="btn_execute" class="btn btn-info btn-sm"
                                        :disabled="ctrl_state.execute.disabled"
                                        v-on:click="onClickExecute"
                                    >{{btn.caption.execute}}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>  <!-- commands -->
            </div>  <!-- window -->
        </dialog>
    </div>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';
import axios from 'axios';

import LoginChecker  from '@/pages/box/common/LoginChecker.vue';
import DialogConfirm from '@/pages/box/dialogs/DialogConfirm.vue';

export const HistoryType = {
    id: 1,
    name: 2,
}
export const DelimiterText = {
    folder: '/',
}

export default {
    name: 'DialogFileList',
    mixins: [apiMixin, tooltipMixin],

    components: {
        'component-login-checker': LoginChecker,
        'component-dialog-confirm': DialogConfirm,
    },

    data() {
        return {
            //ダイアログの状態管理
            dlg_status: {
                show: false,                    //ダイアログの状態の表示／非表示
                text: '',                       //表示する文字列
            },
            //リストボックスの情報管理
            list_box: {
                parent:   { id: '', name: '' },     //表示しているフォルダの親フォルダ情報
                selected: { id: '', name: '' },     //選択されたリストボックスのアイテム情報
                items: [],                          //リストボックスへ表示する一覧

                history: {                      //履歴情報
                    list: [],                     //履歴の一覧
                },
            },
            //コマンドボタン
            btn: {
                caption: {
                    cancel:  'キャンセル',
                    execute: '実行',
                },
            },
            //活性、非活性制御用
            ctrl_state: {
                move_up:  { disabled: true,  }, //ボタン『前の階層へ戻る』
                move_top: { disabled: true,  }, //ボタン『１番上の階層へ戻る』
                cancel:   { disabled: false, }, //ボタン『キャンセル』
                execute:  { disabled: true,  }, //ボタン『実行』
            },
            //呼び出し元から取得した情報
            params: {
                title: '',                      //ダイアログタイトル
                id: '',                         //検索対象のID
                name: '',                       //（デバッグ用）IDに対応する名称

                messages: {
                    execute: '',
                },

                showConfirm: true,              //ダイアログ『実行確認』を表示するか（true:表示する）
                call: -1,                       //呼び出し元
                idx: -1,                        //一覧から指定された場合の行番号
            }
        }
    },

    methods: {
        //----------
        //内部メソッド
        //----------
        cloneObject: function(target) {
            return JSON.parse(JSON.stringify(target));
        },
        initialize: function() {
            this.list_box.items = [];
            this.list_box.parent.id   = '';
            this.list_box.parent.name = '';

            this.list_box.selected.id   = '';
            this.list_box.selected.name = '';

            this.list_box.history.list = [];
        },
        checkArgs: function(args) {
            //呼び出し元の値を書き換えない様に args をクローンする
            let clone_args = this.cloneObject(args);

            //------------------------------------
            //確認ダイアログの設定
            //------------------------------------
            //確認ダイアログ のメッセージ
            if ((typeof clone_args.messages != 'undefined') 
             && (typeof clone_args.messages.confirm != 'undefined')
             ) {
                this.params.messages['confirm'] = clone_args.messages.confirm;
            }
            //確認ダイアログの表示
            if (typeof clone_args.showConfirm != 'undefined') {
                this.params.showConfirm = clone_args.showConfirm;
            }
            //呼び出し元
            if (typeof clone_args.call != 'undefined') {
                this.params.call = clone_args.call;
            }
            //行番号
            if (typeof clone_args.idx != 'undefined') {
                this.params.idx = clone_args.idx;
            }
            
            //------------------------------------
            //ファイル一覧の設定
            //------------------------------------
            //呼び出し元でCSV作成中のメッセージが指定されていれば設定する
            //ファイルリストダイアログ のメッセージ
            if ((typeof clone_args.messages != 'undefined')
             && (typeof clone_args.messages.execute != 'undefined') 
            ) {
                this.params.messages.execute = clone_args.messages.execute;
            } else {
                this.params.messages.execute = '《CSVファイル作成中です。完了までしばらくお待ちください。》';
            }

            //ボタンのキャプション
            if (typeof clone_args.btn != 'undefined') {
                //キャンセル
                if (typeof clone_args.btn.caption.cancel != 'undefined') {
                    this.btn.caption.cancel = clone_args.btn.caption.cancel;
                }
                //実行
                if (typeof clone_args.btn.caption.execute != 'undefined') {
                    this.btn.caption.execute = clone_args.btn.caption.execute;
                }
            }

            //必須項目
            this.params.title = clone_args.title;
            this.params.id    = clone_args.id;
            this.params.name  = clone_args.name;
        },
        getHistoryText: function(history_type, delimiter = DelimiterText.folder) {
            let result = [];

            let list = this.list_box.history.list;
            for (let key in list) {
                switch(history_type) {
                case HistoryType.id:   { result.push(list[key].id);   break; }
                case HistoryType.name: { result.push(list[key].name); break; }
                }
            }
            return result.join(delimiter);
        },
        showStatus: function(text) {
            this.dlg_status.text = text;
            this.dlg_status.show = (text !== null);
        },
        //非同期で一覧を更新
        searchFolderList: function(folder_id) {
            //非同期で実行しないと ダイアログが表示されたときに
            //呼び出し元の画面が描画されず、白くなってしまう
            const url = '/api/box/searchFolderList?folder_id=' + folder_id;

            //検索中を表示
            this.showStatus('《検索中》');
            this.ctrl_state.move_top.disabled = true;
            this.ctrl_state.move_up.disabled  = true;
            this.ctrl_state.cancel.disabled   = true;
            this.ctrl_state.execute.disabled  = true;

            //コントローラ呼び出し
            axios
                .get(url, {})
                .then((result) => {
                    //画面の表示内容をクリアし、一覧に追加
                    this.list_box.items = [];
                    for (const item of result.data.items) {
                        this.list_box.items.push({
                            id:   item.id, 
                            name: item.name,
                            active: false,
                        });
                    }
                    //検索したフォルダを選択する
                    this.list_box.selected.id   = result.data.target.id;
                    this.list_box.selected.name = result.data.target.name;
                    this.list_box.history.list  = result.data.target.history_list;

                    //ボタン、項目、表示制御
                    //・検索中を非表示
                    //・親のディレクトリがある場合は『前の階層へ戻る』を表示する
                    //・ボタン『実行』を活性化
                    if (result.data.parent !== null) {
                        this.list_box.parent.id   = result.data.parent.id;
                        this.list_box.parent.name = result.data.parent.name;
                        this.ctrl_state.move_top.disabled = false;
                        this.ctrl_state.move_up.disabled = false;
                    }

                    this.ctrl_state.cancel.disabled  = false;
                    this.ctrl_state.execute.disabled = false;
                    this.showStatus(null);
                })
                .catch((err) => {
                    // console.log(err);
                    this.$refs.loginChecker.redirectTo(err.request.responseURL);

                    //検索中を非表示
                    this.showStatus(null);
                    this.ctrl_state.cancel.disabled  = false;
                });
        },
        //----------
        //イベントハンドラ
        //----------
        //（リストボックスをクリック）再検索
        onClickListBoxItem: function(item) {
            if (this.dlg_status.show) {
                return;
            }
            // console.log('onDblClickListBoxItem >' + ' id:' + item.id + ' name:' + item.name);
            this.searchFolderList(item.id);
        },
        //ボタン『前の階層へ戻る』
        onMoveParentFolder: function() {
            this.searchFolderList(this.list_box.parent.id);
        },
        //ボタン『１番上の階層へ戻る』
        onMoveTopFolder: function() {
            this.searchFolderList(0);
        },
        //ボタン『キャンセル』
        onClickCancel: function() {
            this.close();
        },
        //ボタン『実行』
        onClickExecute: function() {
            //確認ダイアログを表示しない
            if (!this.params.showConfirm) {
                let dlg_result_confirm = {
                    call: -1,
                    idx: -1,
                };
                this.onConfirmSelectYes(dlg_result_confirm);
                return;
            }

            //確認ダイアログを表示する
            let dlg_args = {
                title: '確認',
                btn: {
                    caption: {
                        yes: 'はい',
                        no:  'いいえ',
                    },
                    active: 'yes',
                },
            };
            //呼び出し元で確認ダイアログのメッセージが指定されていれば設定する
            if ( (typeof this.params.messages != 'undefined')
              && (typeof this.params.messages.confirm != 'undefined') 
            ) {
                dlg_args['messages'] = this.params.messages.confirm;
            }
            this.$refs.dlg_confirm.show(dlg_args);
        },
        //ダイアログ『確認／はい』ボタン押下時のイベントハンドラ（コールバック）
        onConfirmSelectYes: function(args) {    // eslint-disable-line
            // console.log('DialogFileList>>onConfirmSelectYes' + args.call);
            // console.log('DialogFileList>>onConfirmSelectYes' + args.idx);
            //ボタンを押下できないようにして、ステータス更新
            //※CSVの作成は途中でキャンセルできないため
            //  ボタン『キャンセル』は押下させない
            this.ctrl_state.move_top.disabled = true;
            this.ctrl_state.move_up.disabled  = true;
            this.ctrl_state.cancel.disabled   = true;
            this.ctrl_state.execute.disabled  = true;
            this.showStatus(this.params.messages.execute);

            //返却値の設定
            //・ダイアログに影響を与えないようにクローンを作成して返す
            let clone_selected = this.cloneObject(this.list_box.selected);

            let full_name = this.getHistoryText(HistoryType.name);
            if (full_name.length > 0) {
                full_name += DelimiterText.folder;
            }
            full_name += clone_selected.name;

            let result = {
                id:        clone_selected.id,
                name:      clone_selected.name,
                full_name: full_name,

                call: this.params.call,
                idx:  this.params.idx,
            };

            //インデックス番号が設定されていない場合
            this.$emit('onClickExecute', result);
        },

        //----------
        //外部I/F
        //----------
        //ダイアログ画面を閉じる
        close: function() {
            document.querySelector('#dialog-filelist').close();
        },
        //ダイアログ画面を表示する
        show: function(args) {
            //自分自身を初期化
            this.initialize();

            //パラメータチェック
            this.checkArgs(args);

            //検索を呼び出して画面表示
            this.ctrl_state.cancel.disabled = false;

            this.searchFolderList(this.params.id);
            document.querySelector('#dialog-filelist').showModal();
        },
    }
}
</script>

<style>
/* ダイアログの外観：丸くする */
.dialog-filelist {
  padding: 0;
  padding-bottom: 10px;
  border: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 1em black;
  width: 650px;
}
/* ダイアログの背景：半透明のブラック */
.dialog-filelist::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}
/* ダイアログのタイトル */
.dialog-filelist-title {
    font-weight: bold;
    padding: 10px;
}
/* ダイアログの表示領域 */
.dialog-filelist-window {
    padding-left:  20px;
    padding-right: 20px;
}
/* ダイアログの状態表示領域 */
.dialog-filelist-information {
    width:  100%;
    height: 45px;
}
/* リストボックスの大きさ */
.dialog-filelist-list-box {
    width:  100%;
    height: 300px;
    overflow: scroll;
}
/* リストボックスの要素の大きさ */
.dialog-filelist-list-box-item {
    height: 40px;
    padding-top: 5px;
    padding-left:  8px;
    padding-right: 8px;
    user-select: none;
}
/* ダイアログの状態 */
.dialog-filelist-status {
    padding-top: 15px;
    height:100%;
    color: darkgreen;
    font-weight: bold;
}
/* 現在選択されているフォルダ情報 */
.dialog-filelist-selected-info {
    padding-top: 15px;
}
/* コマンド（ボタン） */
.dialog-filelist-commands {
    padding-top: 15px;
    width: 100%;
}
</style>
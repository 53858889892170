<template>
  <main class="main-content main-wrapper content bgc-grey-200">
    <!-- ログインチェック -->
    <component-login-checker
      ref="loginChecker"
    />
    <component-login-checker-local
      ref="loginCheckerForLocal"
    />

    <!-- メッセージ出力 -->
    <div v-if="eroor_code == 1">
      <h4 style="color: #CC0000 ; padding-top: 8px; padding-left: 20px;">
        Boxのオプション機能をご利用いただくためには、「Boxへのアクセスを許可」を有効にしてからお試しください。
      </h4>
    </div>
    <div v-if="eroor_code == 2">
      <h4 style="color: #CC0000 ; padding-top: 8px; padding-left: 20px;">
        認証情報の有効期限が切れました。再度ログインしてください。
      </h4>
    </div>
    <div v-if="eroor_code == 3">
      <h4 style="color: #CC0000 ; padding-top: 8px; padding-left: 20px;">
        ログインに失敗しました。再度ログインしなおしてください。
      </h4>
    </div>

    <div class="mt-3 mb-3 ml-4 mr-4">
      <div class="alert alert-secondary pl-10px" role="alert">
        <p data-e2e="role_policy_1" class="mb-0px">・本機能は必ずBoxからログアウトしたうえでご利用ください。ブラウザにBoxログイン認証が残っていた場合は正しく動作しません。</p>
        <p data-e2e="role_policy_2" class="mb-0px">・Boxのアカウント(ユーザー)に2段階認証を設定している場合、ログインは行えません。本機能をご利用いただくには、Box上で2段階認証の設定を解除してください。</p>
      </div>
    </div>

    <!-- iframe（開始） -->
    <div class="iframe-wrapper">
      <iframe
        width="100%"
        height="100%"
        :src="client_id"
        title="boxログイン画面"
      ></iframe>
    </div>
    <!-- iframe（終了） -->

  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';

import LoginChecker         from '@/pages/box/common/LoginChecker.vue';
import LoginCheckerForLocal from '@/pages/box/common/LoginCheckerForLocal.vue';

export default {
  name: 'BoxLogin',
  mixins: [apiMixin],

  components: {
    'component-login-checker': LoginChecker,
    'component-login-checker-local': LoginCheckerForLocal,
  },

  data() {
    return {
      client_id: '',
      eroor_code: '',
      isLocal: false,
    };
  },

  // beforeMounted: function () {
  mounted: function () {
    this.SetClientData();
    this.CheckErrorMessage();

    //すでにログインされているかのチェック
    this.checkLogin();
  },

  methods: {
    initialization() {
      this.client_id = '';
      this.eroor_code = '';
      this.isLocal = false;
    },
    //実行環境のチェック
    async checkLogin() {
      // console.log('Login>>checkLogin');
      this.isLocal = await this.getApi(`box/isLocal`);

      if (this.isLocal) {
        // console.log('Login>>isLocal: local');
        this.$refs.loginCheckerForLocal.AlreadyLoggedIn(this.eroor_code);
      } else {
        // console.log('Login>>isLocal: other');
        this.$refs.loginChecker.AlreadyLoggedIn(this.eroor_code);
      }
    },
    //Apiからクライアント情報を取得
    async SetClientData() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.clientDatas = (await this.getApi(`box/getClientDataConnectOAuth`)) || [];
      // コントローラを呼び出してクライアントIDを取得する
      if ('client_id' in this.clientDatas) {
        this.client_id =
          'https://account.box.com/api/oauth2/authorize?response_type=code&client_id=' + this.clientDatas['client_id'];
      }
    },
    async CheckErrorMessage() {
      this.eroor_code = this.$route.query.code;
    },
  },
};
</script>

<style scoped>
.iframe-wrapper {
  width: 70vw;
  height: 100vh;
}
.main-wrapper {
  margin: 0;
  padding: 0;
}

/* iframe(開始) */
.iframe-wrapper iframe {
  position: absolute;
  top: 20;
  left: 0;
  width: 100%;
  height: 100%;
}
/* iframe(終了) */

/* 中黒折り返し */
.alert-secondary p {
  padding-left:1em;
  text-indent:-1em;
  margin: 0;
}
</style>

import axios from 'axios';

const state = {
  user: null,
};

const getters = {
  check: state => !!state.user,
  id: state => (state.user ? state.user.id : ''),
  username: state => (state.user ? state.user.name : ''),
  role: state => (state.user ? state.user.role : ''),
  companyId: state => (state.user ? state.user.companyId : ''),
};

const mutations = {
  setUser(state, user) {
    state.user = null;
    if (user.data) {
      state.user = {
        id: user.data.id,
        name: user.data.name,
        role: user.data.role,
        companyId: user.data.company_id,
      };
    }
  },
  clearUser(state) {
    state.user = null;
  },
};

const actions = {
  doSetLoginUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: '/api/users/me',
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'get',
        })
        .then(function (response) {
          // 取得成功時
          //        console.log('取得成功', response);
          commit('setUser', response);
          resolve(1);
        })
        .catch(function () {
          // eslint-disable-line no-unused-vars
          // 取得失敗時
          // console.log('取得失敗', error);
          reject(new Error('error'));
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

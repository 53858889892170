<template>
  <main class="main-content content bgc-grey-200">
    <div class="page_title">
      <h4 class="c-grey-900 mt-3" data-e2e="page_title_name">ダッシュボード</h4>
    </div>

    <div class="row gap-20" data-e2e="app_list_block">
      <div v-for="app in apps" :key="app.id" class="col-md-2 col-lg-1 card-content">
        <div class="bd bgc-white card h-100">
          <div class="layers card-padding">
            <img class="pr-3 pl-3 pt-4 pb-4" :src="app.logo_url" :alt="app.name" />
              <a
                v-if="dashboardList[app.id] != null"
                class="btn btn-info link-btn btn-sm w-100"
                :href="dashboardList[app.id].item_url"
                target="_blank"
                role="button"
                data-toggle="tooltip"
                data-placement="right"
                :title="dashboardList[app.id].tooltip"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
              >
                ダッシュボードを表示
                <em class="ti-link"></em>
              </a>
              <span v-else class="pr-2 pl-2 pb-2 text-center"> ダッシュボードサイトは<br />現在ありません </span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export default {
  name: 'Dashboard',
  mixins: [apiMixin, tooltipMixin],
  data() {
    return {
      apps: [],
      dashboardList: [],
    };
  },
  created() {
    this.getApps();
  },
  updated() {
    this.initTooltip();
  },
  methods: {
    // 表示するアプリ情報を取得
    async getApps() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.apps = (await this.getApi(`users/${this.id}/apps?group=master`)) || [];
      this.makeDashboardList();
    },
    makeDashboardList() {
      this.apps.forEach(app => {
        for(let item of app.items) {
          if(item.title == '利用状況を確認する') {
            this.dashboardList[item.application_id] = item;
            break;
          } else {
            this.dashboardList[item.application_id] = null;
          }
        }
      })
    },
  },
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
  },
};
</script>

<style scoped>
.page_title {
  margin: 15px 0px 0px 0px;
}
/* カード */

.card-content {
  min-width: 255px;
}

/* カードのpadding */
.card-padding {
  padding: 10px;
}

/* リンクボタン */

.link-btn {
  margin: 5px 0 15px 0;
  width: 80%;
}
</style>

<template>
    <dialog id="dialog-period-selection" class="dialog-period-selection">
        <div class="dialog-period-selection-title">{{ title }}</div>

        <div class="dialog-period-selection-window">
            <div class="row">
                <div class="col-md-12">
                    <div class="bgc-white bd bdrs-3 mB-20">
                        <p id="table-title-period" hidden ></p>
                        <table id="setting-period" aria-describedby="table-title-period" class="table table-bordered p-0 m-0">
                            <thead hidden>
                                <tr><th></th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="thead-light pb-0">
                                        <div class="form-group">
                                            <div class="form-check mb-1" v-for="(item, idx) in radio_period.list" :key="idx" :value="item">
                                                <label class="form-check-label">
                                                    <input type="radio" name="grp-period"
                                                        v-bind:id="'radio-period' + idx"
                                                        v-bind:value="item.value"
                                                        v-model="radio_period.selected"
                                                    />{{item.text}}
                                                </label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- コマンド 
                talbe に 以下を設定しないと SonerQube でエラーになる
                ・aria-describedby の指定
                ・thead、tbody タグの記述
            -->
            <div class="dialog-period-selection-commands">
                <p id="table-title" hidden></p>
                <table aria-describedby="table-title" style="width:100%;">
                    <thead class="d-none">
                        <tr>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <!-- 右側 -->
                            <td style="text-align:center;">
                                <button type="button" id="btn_yes" class="btn btn-sm" style="margin-right:6px;"
                                    v-bind:class="{'btn-info': btn.active === 'yes', 'btn-light': btn.active !== 'yes'}"
                                    v-on:click="onClickYes"
                                >{{ btn.caption.yes }}
                                </button>
                                <button type="button" id="btn_no" class="btn btn-sm"
                                    v-bind:class="{'btn-info': btn.active !== 'yes', 'btn-light': btn.active === 'yes'}"
                                    v-on:click="onClickNo"
                                >{{ btn.caption.no }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>  <!-- commands -->

        </div>  <!-- window -->
    </dialog>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export default {
    name: 'DialogPeriodSelection',
    mixins: [apiMixin, tooltipMixin],

    data() {
        return {
            title: '',
            radio_period: {
                selected: 30,
                list: [
                    {value:   7, text: '  7日', },
                    {value:  14, text: ' 14日', },
                    {value:  30, text: ' 30日', },
                    {value:  60, text: ' 60日', },
                    {value:  90, text: ' 90日', },
                    {value: 180, text: '180日（半年）', },
                    {value: 365, text: '365日（１年）', },
                ]
            },
            call: -1,
            obj: null,
            btn: {
                caption: {
                    yes: '',
                    no:  '',
                },
                active: '',     //「yes」or「no」を指定
            }
        }
    },

    methods: {
        //----------
        //内部メソッド
        //----------
        cloneObject: function(target) {
            return JSON.parse(JSON.stringify(target));
        },
        initialize: function() {
            this.title = '';
            this.messages = [];
        },
        checkArgs: function(args) {
            let clone_args = this.cloneObject(args);

            //呼び出し元
            if (typeof clone_args.call != 'undefined') {
                this.call = clone_args.call;
            }
            //行情報
            if (typeof clone_args.obj != 'undefined') {
                this.obj = clone_args.obj;
            }

            //必須項目
            this.title           = clone_args.title;
            this.messages        = clone_args.messages;
            this.btn.caption.yes = clone_args.btn.caption.yes;
            this.btn.caption.no  = clone_args.btn.caption.no;
            this.btn.active      = clone_args.btn.active;
        },
        //----------
        //イベントハンドラ
        //----------
        onClickYes: function() {
            this.close();

            let ret_value = {
                call: this.call,
                selected: this.radio_period.selected,
                obj: this.obj,
            };
            this.$emit('onClickYes', ret_value);
        },
        onClickNo: function() {
            this.close();
        },
        //----------
        //外部I/F
        //----------
        //ダイアログ画面を閉じる
        close: function() {
            document.querySelector('#dialog-period-selection').close();
        },
        //ダイアログ画面を表示する
        show: function(args) {
            //自分自身を初期化
            this.initialize();

            //パラメータチェック
            this.checkArgs(args);

            //画面表示
            document.querySelector('#dialog-period-selection').showModal();
        },
    }
}
</script>

<style>
/* ダイアログの外観：丸くする */
.dialog-period-selection {
  padding: 0;
  padding-bottom: 10px;
  border: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 1em black;
  width: 480px;
}
/* ダイアログのタイトル */
.dialog-period-selection-title {
    font-weight: bold;
    padding: 10px;
}
/* ダイアログの背景：半透明のブラック */
.dialog-period-selection::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}
/* ダイアログの表示領域 */
.dialog-period-selection-window {
    padding-top:   20px;
    padding-left:  20px;
    padding-right: 20px;
}
/* コマンド（ボタン） */
.dialog-period-selection-commands {
    padding-top: 15px;
    width: 100%;
}
</style>

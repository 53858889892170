<template>
    <dialog id="dialog-message" class="dialog-message">
        <div class="dialog-message-title">{{ title }}</div>

        <div class="dialog-message-window">
            <!-- メッセージ -->
            <div v-if="type == dlg_Type().ERR">
                <div class="dialog-message-text">
                    <div v-for="(arr, idx) in messages" :key="idx" :value="arr">
                        <div v-for="msg in arr" :key="msg" value="msg"
                        >{{msg}}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <ul class="list-group dialog-message-list-box">
                    <!-- リストボックスの要素 -->
                    <li class="list-group-item list-group-item-action dialog-message-list-box-item"
                        v-for="(arr, idx) in messages" :key="idx" :value="item"
                    >
                        <div v-for="msg in arr" :key="msg" :value="msg">{{msg}}</div>
                    </li>
                </ul>
            </div>

            <!-- コマンド 
                talbe に 以下を設定しないと SonerQube でエラーになる
                ・aria-describedby の指定
                ・thead、tbody タグの記述
            -->
            <div class="dialog-message-commands">
                <p id="table-title" hidden></p>
                <table aria-describedby="table-title" style="width:100%;">
                    <thead class="d-none">
                        <tr>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <!-- 右側 -->
                            <td style="text-align:center;">
                                <button type="button" id="btn_yes" class="btn btn-sm" style="margin-right:6px;"
                                    v-bind:class="{'btn-info': btn.active === 'yes', 'btn-light': btn.active !== 'yes'}"
                                    v-on:click="onClickYes"
                                >{{ btn.caption.yes }}
                                </button>

                                <button type="button" id="btn_no" class="btn btn-sm"
                                    v-bind:class="{'btn-info': btn.active !== 'yes', 'btn-light': btn.active === 'yes'}"
                                    v-on:click="onClickNo"
                                    v-bind:hidden="btn.caption.no == null"
                                >{{ btn.caption.no }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>  <!-- commands -->

        </div>  <!-- window -->
    </dialog>
</template>

<style>
/* ダイアログの外観：丸くする */
.dialog-message {
  padding: 0;
  padding-bottom: 10px;
  border: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 1em black;
  width: 600px;
}
/* ダイアログの背景：半透明のブラック */
.dialog-message::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}
/* ダイアログのタイトル */
.dialog-message-title {
  font-weight: bold;
  padding: 10px;
}
/* ダイアログの表示領域 */
.dialog-message-window {
  padding-left:  20px;
  padding-right: 20px;
}
/* コマンド（ボタン） */
.dialog-message-commands {
  padding-top: 15px;
  width: 100%;
}

/* ダイアログ内の要素 */
.dialog-message-text {
  margin-top: 25px;
  margin-bottom: 5px;
}
.dialog-message-list-box {
  margin-top: 25px;
  width:  100%;
  height: 300px;
  overflow-y: scroll;
}
.dialog-message-list-box-item {
  overflow-wrap: break-word;
  /* user-select: none; */
}
</style>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export const DialogMessageType = {
  ERR: 1,
  WRN: 2,
}

export default {
    name: 'DialogMessage',
    mixins: [apiMixin, tooltipMixin],

    data() {
        return {
            type: null,
            title: '',
            messages: [],
            call: -1,
            btn: {
                caption: {
                    yes: '',
                    no:  '',
                },
                active: '',     //「yes」or「no」を指定
            }
        }
    },

    methods: {
        //----------
        //内部メソッド
        //----------
        cloneObject: function(target) {
            return JSON.parse(JSON.stringify(target));
        },
        initialize: function() {
            this.title = '';
            this.messages = [];
        },
        checkArgs: function(args) {
            let clone_args = this.cloneObject(args);

            //呼び出し元
            if (typeof clone_args.call != 'undefined') {
                this.call = clone_args.call;
            }
            //行情報
            if (typeof clone_args.obj != 'undefined') {
                this.obj = clone_args.obj;
            }

            //必須項目
            this.type            = clone_args.type;
            this.title           = clone_args.title;
            this.messages        = clone_args.messages;
            this.btn.caption.yes = clone_args.btn.caption.yes;
            this.btn.caption.no  = clone_args.btn.caption.no;
            this.btn.active      = clone_args.btn.active;
        },
        dlg_Type: function() {
          return DialogMessageType;
        },
        //----------
        //イベントハンドラ
        //----------
        onClickYes: function() {
            this.close();

            let ret_value = {
                call: this.call,
                type: this.type,
            };
            this.$emit('onClickYes', ret_value);
        },
        onClickNo: function() {
            this.close();

            let ret_value = {
                call: this.call,
                type: this.type,
            }
            this.$emit('onClickNo', ret_value);
        },
        //----------
        //外部I/F
        //----------
        //ダイアログ画面を閉じる
        close: function() {
            document.querySelector('#dialog-message').close();
        },
        //ダイアログ画面を表示する
        show: function(args) {
            //自分自身を初期化
            this.initialize();

            //パラメータチェック
            this.checkArgs(args);

            //画面表示
            document.querySelector('#dialog-message').showModal();
        },
    }
}
</script>

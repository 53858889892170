<template>
    <dialog id="dialog-confirm" class="dialog-confirm">
        <div class="dialog-confirm-title">{{ title }}</div>

        <div class="dialog-confirm-window">
            <!-- コマンド -->
            <div class="dialog-confirm-messages">
                <div v-for="msg in messages" :key="msg">
                {{ msg }}
                </div>
            </div>

            <!-- コマンド 
                talbe に 以下を設定しないと SonerQube でエラーになる
                ・aria-describedby の指定
                ・thead、tbody タグの記述
            -->
            <div class="dialog-confirm-commands">
                <p id="table-title" hidden></p>
                <table aria-describedby="table-title" style="width:100%;">
                    <thead class="d-none">
                        <tr>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <!-- 右側 -->
                            <td style="text-align:center;">
                                <button type="button" id="btn_yes" class="btn btn-sm" style="margin-right:6px;"
                                    v-bind:class="{'btn-info': btn.active === 'yes', 'btn-light': btn.active !== 'yes'}"
                                    v-on:click="onClickYes"
                                >{{ btn.caption.yes }}
                                </button>
                                <button type="button" id="btn_no" class="btn btn-sm"
                                    v-bind:class="{'btn-info': btn.active !== 'yes', 'btn-light': btn.active === 'yes'}"
                                    v-on:click="onClickNo"
                                >{{ btn.caption.no }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>  <!-- commands -->
        </div>  <!-- window -->
    </dialog>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export default {
    name: 'DialogConfirm',
    mixins: [apiMixin, tooltipMixin],

    data() {
        return {
            title: '',
            messages: [],
            call: -1,
            idx: -1,
            btn: {
                caption: {
                    yes: '',
                    no:  '',
                },
                active: '',     //「yes」or「no」を指定
            },
            event_exec: {
                no: false,      //「no」のイベントを実行するか
            },
        }
    },

    methods: {
        //----------
        //内部メソッド
        //----------
        cloneObject: function(target) {
            return JSON.parse(JSON.stringify(target));
        },
        initialize: function() {
            this.title = '';
            this.messages = [];
        },
        checkArgs: function(args) {
            let clone_args = this.cloneObject(args);

            //呼び出し元で確認メッセージが指定されていれば設定する
            if (typeof clone_args.messages != 'undefined') {
                this.messages = clone_args.messages;
            } else {
                this.messages = [
                    'フォルダ内のコンテンツ数が多い場合は時間がかかります。',
                    '実行後のキャンセルはできません。',
                    '実行しますか。'
                ];
            }
            //呼び出し元
            if (typeof clone_args.call != 'undefined') {
                this.call = clone_args.call;
            }
            //行情報
            if (typeof clone_args.idx != 'undefined') {
                this.idx = clone_args.idx;
            }
            if (typeof clone_args.event_exec != 'undefined') {
                if (typeof clone_args.event_exec.no != 'undefined') {
                    this.event_exec.no = clone_args.event_exec.no;
                }
            }

            //必須項目
            this.title           = clone_args.title;
            this.btn.caption.yes = clone_args.btn.caption.yes;
            this.btn.caption.no  = clone_args.btn.caption.no;
            this.btn.active      = clone_args.btn.active;
        },
        //----------
        //イベントハンドラ
        //----------
        onClickYes: function() {
            this.close();

            let result = {
                call: this.call,
                idx: this.idx,
            };
            this.$emit('onClickYes', result);
        },
        onClickNo: function() {
            this.close();

            if (!this.event_exec.no) {
                return;
            }
            let result = {
                call: this.call,
                idx: this.idx,
            };
            this.$emit('onClickNo', result);
        },
        //----------
        //外部I/F
        //----------
        //ダイアログ画面を閉じる
        close: function() {
            document.querySelector('#dialog-confirm').close();
        },
        //ダイアログ画面を表示する
        show: function(args) {
            //自分自身を初期化
            this.initialize();

            //パラメータチェック
            this.checkArgs(args);

            //画面表示
            document.querySelector('#dialog-confirm').showModal();
        },
    }
}
</script>

<style>
/* ダイアログの外観：丸くする */
.dialog-confirm {
  padding: 0;
  padding-bottom: 10px;
  border: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 1em black;
  width: 480px;
}
/* ダイアログのタイトル */
.dialog-confirm-title {
    font-weight: bold;
    padding: 10px;
}
/* ダイアログの背景：半透明のブラック */
.dialog-confirm::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}
/* ダイアログの表示領域 */
.dialog-confirm-window {
    padding-left:  20px;
    padding-right: 20px;
}
/* メッセージ表示領域 */
.dialog-confirm-messages {
    padding-top: 20px;
}
/* コマンド（ボタン） */
.dialog-confirm-commands {
    padding-top: 15px;
    width: 100%;
}
</style>
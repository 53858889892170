<template>
    <div></div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'DownloaderForAxios',

    methods: {
        getFileName: function(response, default_filename) {
            // 正規表現で filename を抜き出す
            const content_disposition = response.headers['content-disposition'];
            const regex_filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

            let filename = default_filename;

            const matches = regex_filename.exec(content_disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
            return decodeURI(filename);    //日本語対応
        },
        downloadBlob: function(args) {
            const data = args.response.data;
            const filename = this.getFileName(args.response, args.default_filename);
            const mine_type = args.mine_type;

            if (window.navigator.msSaveOrOpenBlob) {
                // for IE,Edge
                window.navigator.msSaveOrOpenBlob(data, filename);
            } else {
                // for chrome, firefox
                // const mine_type = args.response.headers["content-type"];
                const link_url = URL.createObjectURL(new Blob([data], { type: mine_type }));
                
                const link_el = document.createElement('a');
                link_el.href  = link_url;
                link_el.setAttribute('download', filename);
                document.body.appendChild(link_el);
                link_el.click();
                URL.revokeObjectURL(link_url);
                link_el.parentNode.removeChild(link_el);
            }
        },

        downloadCSV: function(args) {
            const url = '/api/box/downloadCSV?value=' + args.target_id
                + '&option_id=' + args.option
                + '&folder_id=' + args.id
                + '&folder_name=' + args.name;

            axios
                .get(url, {
                    responseType: 'blob',
                })
                .then((res) => {
                    // console.log(res);

                    //axios の レスポンスをもとにダウンロード
                    const download_args = {
                        response: res,
                        mine_type: args.mine_type,
                        default_filename: args.default_name,
                    }
                    this.downloadBlob(download_args);
                })
                .catch((err) => {
                    // console.log(err);
                    this.$emit('onDownloadCSVError', err);
                });
        },
    }
}
</script>

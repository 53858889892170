<template>
  <main class="main-content content bgc-grey-200">
    <!-- お知らせ -->
    <div ref="notice-description">
      <h4 class="c-grey-900 mt-3" data-e2e="page_title_name" id="notice-description">お知らせ</h4>
    </div>
    <div class="card">
      <div class="row">
        <div class="col-md-12">
          <div class="bgc-white bd bdrs-3 p-20">
            <div class="d-flex justify-content-between align-items-center"></div>

            <table
              id="dataTable"
              class="table table-data table-striped"
              cellspacing="0"
              width="100%"
              aria-describedby="notice-description"
            >
              <thead class="d-none">
                <tr>
                  <th scope="col"></th>
                  <th scope="col" class="d-none"></th>
                  <th scope="col" class="d-none"></th>
                  <th scope="col" class="d-none"></th>
                  <th scope="col" class="d-none"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(notice, index) in notices.slice(0, 100)" :key="index" data-e2e="notice_list_block">
                  <td v-if="index <= 99">
                    <div class="accordion" id="accordion" role="tablist" aria-multiselectable="true" v-if="index <= 99">
                      <div class="card border-0">
                        <!-- お知らせ -->
                        <div class="card-header p-1 bg-light" role="tab">
                          <div
                            class="d-flex align-items-center justify-content-between notice-hover"
                            @click="toggleAccordion(index)"
                          >
                            <button
                              class="btn btn-link btn-sm d-flex align-items-center justify-content-between text-decoration-none py-1"
                            >
                              <!-- 日付 -->
                              <div class="d-flex pr-0 pl-0 pb-3 pt-3 justify-content-start align-items-center">
                                <span class="title-txt">{{ $moment(notice.from).format('YYYY/MM/DD') }}</span>

                                <!-- NEWアイコン -->
                                <div class="new-area d-flex align-items-center">
                                  <span
                                    v-if="new Date(notice.from) >= days"
                                    class="badge badge-pill badge-primary ml-3 badge-font"
                                    >NEW</span
                                  >
                                </div>
                                <!-- アプリ 画像 -->
                                <div class="ml-4 mr-4 img-area d-flex align-items-center">
                                  <img class="img-notice" :src="notice.logo_url" :alt="notice.servicenow_name" />
                                </div>
                              </div>

                              <!-- タイトル -->
                              <object class="align-items-center">
                                <span class="notice-txt">{{ notice.title.slice(notice.title.indexOf('】') + 1) }}</span>
                              </object>
                            </button>

                            <!-- アイコン -->
                            <div class="up-down-icon d-flex justify-content-end mr-3">
                              <img v-if="show_contents.indexOf(index) >= 0" src="/images/dash-square.svg" alt="dash" />
                              <img v-else src="/images/plus-square.svg" alt="plus" />
                            </div>
                          </div>
                        </div>
                        <!-- /.card-header -->
                        <!-- アコーディオンの中身 -->
                        <div v-show="show_contents.indexOf(index) >= 0">
                          <div class="card-body">
                            {{ notice.summary }}
                            <span>
                              <a :href="notice.details_url" target="_blank">{{ notice.details_link_text }}</a>
                            </span>
                          </div>
                          <!-- /.card-body -->
                        </div>
                        <!-- /.collapse -->
                      </div>
                    </div>
                    <!-- /#accordion -->
                  </td>

                  <td class="d-none"></td>
                  <td class="d-none"></td>
                  <td class="d-none"></td>
                  <td class="d-none"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- アプリ一覧 -->
    <div ref="appList">
      <h4 class="c-grey-900 mt-3" data-e2e="page_title_name" id="appList">アプリ一覧</h4>
    </div>
    <div class="row gap-20 content-top" data-e2e="app_list_block">
      <!-- 表示対象のアプリ -->
      <div v-for="app in apps" :key="app.id" class="col-md-2 col-lg-1 card-content">
        <div class="bd bgc-white card">
          <div class="layers card-padding">
            <img class="pr-3 pl-3 pt-4 pb-4" :src="app.logo_url" :alt="app.name" />

            <!-- 各ボタン -->
            <template v-for="item in app.items">
              <a
                v-if="item.title !== 'none'"
                :key="item.item_id"
                :class="item.color"
                :href="item.item_url"
                :target="item.is_extarnal_site ? '_blank' : false"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                :title="item.tooltip"
              >
                {{ item.title }}
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';
import * as $ from 'jquery';

export default {
  name: 'AppList',
  mixins: [apiMixin, tooltipMixin],
  data() {
    return {
      notices: [],
      apps: [],
      constants: [],
      days: '',
      newPeriod: null,
      show_contents: [],
      hash: this.$route.hash, // アンカーリンク
    };
  },
  created() {
    this.getNotices();
    this.getApps();
  },
  updated() {
    this.initTooltip();

    // アンカーリンク
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace('#', '');
        setTimeout(() => {
          this.scrollToAnchorPoint(refName);
        }, 0);
      }
    });
  },

  methods: {
    // 表示するお知らせ情報を取得
    async getNotices() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.notices = (await this.getApi(`notices/${this.id}`)) || [];

      // 定数情報を取得
      this.constants = (await this.getApi('constant')) || [];

      for (const i in this.constants) {
        // NEWの期間
        if (this.constants[i].key == 'newPeriod') {
          this.newPeriod = this.constants[i].value;
        }
      }

      // 日付処理
      var day = new Date();
      // 現在日時から -7日
      this.days = day.setDate(day.getDate() - this.newPeriod - 1);

      setTimeout(
        () =>
          $('#dataTable').DataTable({
            dom: '<"table-data-wrapper"<"table-responsive"t><"row align-items-center mt-20px mt-md-5px"<"col col-12 col-md-auto"i><"col col-12 col-md-auto ml-auto mt-10px mt-md-0px mb-10px mb-md-0px mt-10px mt-md-0px mb-10px mb-md-0px"p>>>',
            destroy: true,
            scrollX: true,
            scrollY: true,
            oLanguage: {
              sEmptyTable: '現在お知らせはありません',
              sInfo: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
              sInfoEmpty: ' 0 件中 0 から 0 まで表示',
              sInfoFiltered: '（全 _MAX_ 件より抽出）',
              sInfoPostFix: '',
              sInfoThousands: ',',
              sLengthMenu: '_MENU_ 件表示',
              sLoadingRecords: '読み込み中...',
              sProcessing: '処理中...',
              sSearch: '検索:',
              sZeroRecords: '一致するレコードがありません',
              oPaginate: {
                sFirst: '先頭',
                sLast: '最終',
                sNext: '次',
                sPrevious: '前',
              },
            },
            columnDefs: [
              {
                targets: [1, 2, 3, 4],
                orderable: false,
              },
            ],
            searching: false,
            lengthChange: false,
            displayLength: 5,
            ordering: false,
          }),
        0
      );
    },

    // 表示するアプリ情報を取得
    async getApps() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.apps = (await this.getApi(`users/${this.id}/apps?group=master`)) || [];

      this.apps.forEach((app) => {
        this.makeItemTitle(app);
      });
    },
    makeItemTitle(app) {
      app.items.forEach((item) => {
        if (item.order_num === 50) {
          item.title = 'アプリを開始する';
          item.color = 'btn cur-p btn-info w-75 mb-3 px-0 btn-sm';
        } else if (item.order_num === 100) {
          item.title = 'マニュアルをみる';
          item.color = 'btn cur-p btn-outline-info w-75 mb-3 px-0 btn-sm';
        } else if (item.order_num === 101) {
          item.title = 'よくある質問をみる';
          item.color = 'btn cur-p btn-outline-info w-75 mb-3 px-0 btn-sm';
        } else if (item.order_num === 200) {
          item.title = 'オプション機能を使う';
          item.color = 'btn cur-p btn-outline-info w-75 mb-3 px-0 btn-sm';
        } else if (item.order_num === 201) {
          item.title = '全てのコンテンツをみる';
          item.color = 'btn cur-p btn-outline-info w-75 mb-3 px-0 btn-sm';
        } else {
          item.title = 'none';
        }

        if (item.item_url === 'none') {
          item.color = 'disabled ' + item.color;
        }
      });
    },
    // お知らせのアコーディオンクリックした時のメソッド
    toggleAccordion(data) {
      if (this.show_contents.indexOf(data) >= 0) {
        this.show_contents = this.show_contents.filter((n) => n !== data);
      } else {
        this.show_contents.push(data);
      }
    },
    // アンカーリンク
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: 'smooth' });
    },
  },
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
    accordion() {
      return this.$store.getters['view/accordion'];
    },
  },
};
</script>

<style scoped>
/* カード */

.card-content {
  min-width: 255px;
}

.content-top {
  /* padding-top: 40px; */
}

.card-padding {
  padding: 10px;
}

/* お知らせマウスオーバー */
.notice-hover:hover {
  background-color: white;
}
/* お知らせ画像 */
.img-notice {
  height: 25px;
}

/* お知らせ時間 */
.notice-time {
  min-width: 50px;
}

/* 「NEW」アイコン */
.new-area {
  /* height: 45px; */
  width: 50px;
}
/* アプリ画像エリア */
.img-area {
  width: 100px;
  justify-content: center;
}

/* リンクボタン */

.link-btn {
  margin: 5px 0 15px 0;
  width: 80%;
}

/* 行の色を変更する */

#dataTable thead tr {
  background-color: blue;
  border: none;
}

#dataTable tbody tr.odd {
  background-color: white;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 0;
  height: 70%;
}

.list-group-item:last-child {
  border: none;
}

#accordion .card-header a[data-toggle='collapse'].collapsed::after {
  /* display: none; */
  /* font-family: 'Font Awesome 5 Solid'; */
  /* content: '\f078'; */
  /* chevron-down */
}

#accordion .card-header a[data-toggle='collapse']::after {
  /* display: none; */
  /* font-family: 'Font Awesome 5 Solid'; */
  /* content: '\f077'; */
  /* chevron-up */
}

/* 日付、お知らせタイトル */
.title-txt {
  font-size: 16px;
}

/* お知らせテキスト */
.notice-txt {
  display: block;
  text-align: left;
  font-size: 16px;
}

/* バッジ */
.badge {
  font-size: 12px;
}
</style>

<style>
#accordion .card-header a .svg-inline--fa[data-fa-pseudo-element] {
  margin-left: 20px;
  text-align: right !important;
}
/* ソートアイコン */
table.dataTable thead .sorting {
  background-image: url(/images/datatables/sort_both.png);
}
table.dataTable thead .sorting_asc {
  background-image: url(/images/datatables/sort_asc.png);
}

table.dataTable thead .sorting_desc {
  background-image: url(/images/datatables/sort_desc.png);
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
  background-repeat: no-repeat;
  background-position: center right;
}

html {
  scroll-padding-top: 80px;
}
</style>

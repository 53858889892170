import Vue from 'vue';
import VueRouter from 'vue-router';

// ページコンポーネントをインポートする
import Index from '@/components/Index.vue';
import Top from '@/pages/Index.vue';
import AppList from '@/pages/TopPage.vue';
import CatalogManual from '@/pages/catalog/Manual.vue';
import CatalogFAQ from '@/pages/catalog/FAQ.vue';
import Question from '@/pages/items/Question.vue';
import ContentsList from '@/pages/items/ContentsList.vue';
import Notice from '@/pages/Notice.vue';
import Dashboard from '@/pages/Dashboard.vue';
import ContactForm from '@/pages/ContactForm.vue';
import UserEdit from '@/pages/user/Edit.vue';
import UserList from '@/pages/user/List.vue';
import Profile from '@/pages/user/Profile.vue';
import UserRegister from '@/pages/user/Register.vue';
import PasswordRecovery from '@/pages/password/Recovery.vue';
import PasswordRecoveryForm from '@/pages/password/RecoveryForm.vue';
import PasswordReset from '@/pages/password/Reset.vue';
import PasswordOtherReset from '@/pages/password/OtherReset.vue';
import BoxLogin from '@/pages/box/Login.vue';
import BoxCloseLoginIframe from '@/pages/box/CloseLoginIframe.vue';
import BoxOptionMenu from '@/pages/box/OptionMenu.vue';
import BoxAccessList from '@/pages/box/AccessList.vue';
import BoxAccessListEditAuthority from '@/pages/box/AccessListEditAuthority.vue';
import BoxMetaDataList from '@/pages/box/MetaDataList.vue';
import BoxFileInventoryOperation from '@/pages/box/FileInventoryOperation.vue';
import BoxOperationLog from '@/pages/box/OperationLog.vue'
import Error404 from '@/pages/errors/404.vue';
import Error500 from '@/pages/errors/500.vue';
import Error403 from '@/pages/errors/403.vue';

import store from './store';

// VueRouterプラグインを使用する
// これによって<RouterView />コンポーネントなどを使うことができる
Vue.use(VueRouter);
Vue.use(store);

// パスとコンポーネントのマッピング
const routes = [
  // ログイン
  {
    path: '/',
    component: Top,
    meta: { title: 'ログイン' },
    beforeEnter(to, from, next) {
      // アクション実行完了後の処理
      if (store.getters['auth/check']) {
        next('/top');
      } else {
        next();
      }
    },
  },
  // トップページ
  {
    path: '/top',
    component: Index,
    children: [
      {
        path: '',
        component: AppList,
        meta: { title: 'トップページ' },
      },
    ],
  },

  // マニュアル
  {
    path: '/catalog/Manual',
    component: Index,
    children: [
      {
        path: '',
        component: CatalogManual,
        meta: { title: 'マニュアル' },
      },
    ],
  },
  // よくある質問
  {
    path: '/catalog/FAQ',
    component: Index,
    children: [
      {
        path: '',
        component: CatalogFAQ,
        meta: { title: 'よくある質問' },
      },
    ],
  },

  // コンテンツ一覧
  {
    path: '/:url_name/contentsList',
    component: Index,
    children: [
      {
        path: '',
        component: ContentsList,
        meta: { title: 'コンテンツ一覧' },
      },
    ],
  },

  // 運用状況
  {
    path: '/notice',
    component: Index,
    children: [
      {
        path: '',
        component: Notice,
        meta: { title: '運用状況' },
      },
    ],
  },

  // お問い合わせ
  {
    path: '/question',
    component: Index,
    children: [
      {
        path: '',
        component: Question,
        meta: { title: 'お問い合わせ' },
      },
    ],
  },

  // ダッシュボード
  {
    path: '/dashboard',
    component: Index,
    children: [
      {
        path: '',
        component: Dashboard,
        meta: { title: 'ダッシュボード' },
      },
    ],
    beforeEnter(to, from, next) {
      if (store.getters['auth/role'] == 3) {
        next('/403');
      } else {
        next();
      }
    },
  },

  // お問い合わせフォーム
  {
    path: '/ContactForm',
    component: Index,
    children: [
      {
        path: '',
        component: ContactForm,
        meta: { title: 'お問い合わせフォーム' },
      },
    ],
    beforeEnter(to, from, next) {
      if (store.getters['auth/role'] == 3) {
        next('/403');
      } else {
        next();
      }
    },
  },

  // ユーザー
  {
    path: '/user',
    component: Index,
    children: [
      // ユーザー設定
      {
        path: '',
        component: UserList,
        meta: { title: 'ユーザー設定' },
        beforeEnter(to, from, next) {
          if (store.getters['auth/role'] == 3) {
            next('/403');
          } else {
            next();
          }
        },
      },
      // ユーザー追加
      {
        path: 'register',
        component: UserRegister,
        meta: { title: 'ユーザー追加' },
        beforeEnter(to, from, next) {
          if (store.getters['auth/role'] == 3) {
            next('/403');
          } else {
            next();
          }
        },
      },
      // ユーザー編集
      {
        path: ':id/edit',
        component: UserEdit,
        meta: { title: 'ユーザー編集' },
        beforeEnter(to, from, next) {
          if (store.getters['auth/role'] == 3) {
            next('/403');
          } else {
            next();
          }
        },
      },
      // プロフィール
      {
        path: ':id',
        component: Profile,
        meta: { title: 'プロフィール' },
      },
    ],
  },
  // パスワード再設定(リセット)
  {
    path: '/password/recovery',
    component: PasswordRecovery,
    meta: { title: 'パスワード再設定' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/check']) {
        next('/top');
      } else {
        next();
      }
    },
  },
  // パスワードリセットフォーム画面
  {
    path: '/password/recoveryForm/:id',
    component: PasswordRecoveryForm,
    meta: { title: 'パスワード再設定フォーム' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/check']) {
        next('/top');
      } else {
        next();
      }
    },
  },
  // パスワード変更
  {
    path: '/password/change',
    component: Index,
    children: [
      {
        path: '',
        component: PasswordReset,
        meta: { title: 'パスワード変更' },
      },
    ],
  },
  // 他ユーザーのパスワード変更
  {
    path: '/password/otherChange/:targetId',
    component: Index,
    children: [
      {
        path: '',
        component: PasswordOtherReset,
        meta: { title: '他ユーザーパスワード変更' },
      },
    ],
  },

  // BoxLogin画面
  {
    path: '/box/Login',
    component: Index,
    children: [
      {
        path: '',
        component: BoxLogin,
        meta: { title: 'Box Login' },
      },
    ],
  },
  // BoxLogin画面のiframeから親へ遷移するための画面
  {
    path: '/box/CloseLoginIframe',
    component: BoxCloseLoginIframe,
    meta: { title: 'BoxLogin画面のiframeから親へ遷移する' },
  },
  // Boxオプション機能選択
  {
    path: '/box/OptionMenu',
    component: Index,
    children: [
      {
        path: '',
        component: BoxOptionMenu,
        meta: { title: 'オプション機能選択' },
      },
    ],
  },
  // Boxアクセス権一覧取得
  {
    path: '/box/AccessList',
    component: Index,
    children: [
      {
        path: '',
        component: BoxAccessList,
        meta: { title: 'アクセス権一覧取得' },
      },
    ],
  },
  // Boxアクセス権一覧取得
  {
    path: '/box/AccessListEditAuthority',
    component: Index,
    children: [
      {
        path: '',
        component: BoxAccessListEditAuthority,
        meta: { title: 'アクセス権一覧編集' },
      },
    ],
  },
  // Box メタデータ一覧取得
  {
    path: '/box/MetaDataList',
    component: Index,
    children: [
      {
        path: '',
        component: BoxMetaDataList,
        meta: { title: 'メタデータ一覧取得' },
      },
    ],
  },
  // Box 一定期間後のファイル削除
  {
    path: '/box/FileInventoryOperation',
    component: Index,
    children: [
      {
        path: '',
        component: BoxFileInventoryOperation,
        meta: { title: '一定期間後のファイル削除' },
      },
    ],
  },
  // Box ログの取得
  {
    path: '/box/OperationLog',
    component: Index,
    children: [
      {
        path: '',
        component: BoxOperationLog,
        meta: { title: 'ログの取得' },
      },
    ],
  },

  // 403
  {
    path: '/403',
    component: Error403,
    meta: { title: '閲覧禁止 403' },
  },
  // 404
  {
    path: '/404',
    component: Error404,
    meta: { title: 'ページが見つかりません 404' },
  },
  // 500
  {
    path: '/500',
    component: Error500,
    meta: { title: 'サーバーエラー 500' },
  },
  // 存在しないページにアクセスした場合
  {
    path: '*',
    component: Error404,
    meta: { title: 'ページが見つかりません 404' },
  },
];

// VueRouterインスタンスを作成する
const router = new VueRouter({
  mode: 'history',
  routes,
  // base: process.env.BASE_URL,
});

// ログインしていない場合はTOP画面へ
router.beforeEach((to, from, next) => {
  var matchStr = '/password/recoveryForm/';
  var routerStr = to.path;
  var result = routerStr.match(matchStr);
  if (
    to.path !== '/' &&
    to.path !== '/password/recovery' &&
    result == null &&
    !store.getters['auth/check'] &&
    to.path !== '/403' &&
    to.path !== '/404' &&
    to.path !== '/500'
  ) {
    // ログインユーザー情報を取得し、auth を更新する
    store
      .dispatch('auth/doSetLoginUser')
      .then(() => {
        if (to.path !== '/spa' && to.path !== '/login') {
          next();
        } else if (to.path === '/logout') {
          next('/');
        } else {
          next('/top');
        }
      })
      .catch((e) => {
        console.error('取得失敗', e);
        next('/');
      });
  } else if (result !== null) {
    next();
  } else {
    next();
  }
});

// VueRouterインスタンスをエクスポートする
export default router;

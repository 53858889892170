<template>
    <div class="alert alert-secondary" role="alert">
      <p data-e2e="password_policy_1">※パスワードは下記の条件で設定してください。</p>
      <p data-e2e="password_policy_2">・パスワード長さ： {{ this.passwordPolicyMin }}文字以上、{{ this.passwordPolicyMax }}文字以下</p>
      <p data-e2e="password_policy_3">・使用可能文字： 半角大文字英語[A-Z]、半角小文字英語[a-z]、半角数字[0-9]、半角記号[!"#$%&'()+,-./=@[]^_`{}~]</p>
      <p data-e2e="password_policy_4">・組み合わせ条件： 英語、数字、記号の内2種類が含まれていること</p>
    </div>
</template>

<script>
export default {
  props: ['passwordPolicyMin', 'passwordPolicyMax'],
  name: 'PasswordPolicy',
};
</script>

<style scoped>
.alert-secondary {
  padding-left: 10px;
}

.alert-secondary p {
	padding-left:1em;
	text-indent:-1em;
  margin: 0;
}
</style>

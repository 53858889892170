<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top">
      <div class="container-fluid header-container">
        <a
          href="javascript:void(0);"
          class="sidebar-toggle js-sidebar-toggle c-toggle-sidebar d-none d-md-flex align-self-stretch align-items-center justify-content-center"
          @click="toggleNavbar()"
          id="sidebar-toggle"
          data-e2e="sidebar-toggle"
        >
          <svg class="js-sidebar-toggle-icon" width="27" height="16" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.767 10.025L.525 15.268l.707.707 5.242-5.243z" fill="393636" />
            <path d="M27 15H1v1h26zM27 10H9v1h18zM27 5H1v1h26zM27 0H1v1h26z" fill="393636" />
            <path d="M27 0H1v1h26z" fill="393636" />
            <path d="M27 5H1v1h26z" fill="393636" />
            <path d="M27 10H9v1h18z" fill="393636" />
            <g><path d="M27 15H1v1h26z" fill="393636" /></g>
            <g><path d="M5.767 10.025L.525 15.268l.707.707 5.242-5.243z" fill="393636" /></g>
            <g>
              <path d="M27 0H1v1h26z" fill="393636" />
              <path d="M27 5H1v1h26z" fill="393636" />
              <path d="M27 10H9v1h18z" fill="393636" />
              <g><path d="M27 15H1v1h26z" fill="393636" /></g>
              <g><path d="M5.767 10.025L.525 15.268l.707.707 5.242-5.243z" fill="393636" /></g>
            </g>
          </svg>
        </a>

        <div class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav d-flex align-items-center">

            <!-- パーティション -->
            <!-- <div class="nav-item-partision"></div>-->

            <!-- ユーザー名 -->
            <li data-e2e="dropdown-menu" class="nav-item profile rounded-pill">
              <div class="prof-btn-wrap" data-toggle="dropdown">
                <p class="user-name">{{ username }}<i class="fas fa-angle-down"></i></p>
              </div>
              <div class="dropdown-menu c-profile mw-160px ml-0">
                <div class="menu-minsize-inner">
                  <router-link :to="`/user/${id}`" data-e2e="user_id" class="dropdown-item has-icon item-range">
                    <svg class="bi menu-minsize-icon" width="1.2em" height="1.2em" fill="currentColor">
                      <use xlink:href="/img/bootstrap-icons.svg#person"></use>
                    </svg>
                    <span>プロフィール</span>
                  </router-link>

                  <router-link to="/password/change" data-e2e="password_change" class="dropdown-item has-icon item-range" v-if="`${this.role}` != 3">
                    <svg class="bi menu-minsize-icon" width="1.2em" height="1.2em" fill="currentColor">
                      <use xlink:href="/img/bootstrap-icons.svg#key"></use>
                    </svg>
                    <span>パスワード変更</span>
                  </router-link>

                  <!-- ログアウト -->
                  <!-- ローカル用 ログアウト -->
                  <div v-if="this.host">
                    <div v-if="this.host == 'localhost'">
                      <a
                        data-e2e="logout"
                        class="dropdown-item has-icon item-range"
                        href="http://localhost:8000/logout"
                        role="button"
                        target="_self"
                      >
                        <svg class="bi menu-minsize-icon" width="1.2em" height="1.2em" fill="currentColor">
                          <use xlink:href="/img/bootstrap-icons.svg#unlock"></use>
                        </svg>
                        <span>ログアウト</span>
                      </a>
                    </div>
                  </div>

                  <!-- 本番用 ログアウト -->
                  <div v-if="this.host != 'localhost'">
                    <div v-for="constant in this.constants" :key="constant.key">
                      <div v-if="constant.key == 'logoutUrl'">
                        <a
                          data-e2e="logout"
                          class="dropdown-item has-icon item-range"
                          :href="constant.value"
                          role="button"
                          target="_self"
                        >
                          <svg class="bi menu-minsize-icon" width="1.2em" height="1.2em" fill="currentColor">
                            <use xlink:href="/img/bootstrap-icons.svg#unlock"></use>
                          </svg>
                          <span>ログアウト</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- dropdown-menu -->
            </li>
            <!-- your navbar here -->
          </ul>
        </div>
      </div>
    </nav>

    <!-- スマホ 用 -->
    <div class="sp-header-container">
      <div class="sp-header js-header-sp d-flex align-items-center justify-content-between pr-3 pl-3">
        <div class="area-toggler d-flex align-items-center justify-content-center">
          <div class="open-btn" v-if="flg" @click="changeFlg()">
            <svg class="bi text-white" width="30" height="30" fill="currentColor">
              <use xlink:href="/img/bootstrap-icons.svg#list" />
            </svg>
          </div>
          <div class="close-btn" v-if="!flg" @click="changeFlg()">
            <svg class="bi text-white" width="30" height="30" fill="currentColor">
              <use xlink:href="/img/bootstrap-icons.svg#x" />
            </svg>
          </div>
        </div>
        <div class="font-family-futura service-logo-txt pl-5">
          <a href="/" class="text-white font-weight-bold">サービスポータル</a>
        </div>
        <!-- モーダル -->
        <div class="prof-btn position-relative dropdown">
          <div class="symbol dropdown-toggle text-white" data-toggle="dropdown" data-target="#modal"></div>
          <div class="dropdown-toggle text-white" data-toggle="dropdown" data-target="#modal">
            {{ username }}
          </div>
        </div>
      </div>

      <!-- プロフィール Modal -->
      <div class="fade no-move" id="modal" tabindex="-1">
        <div class="c-modal-profile dropdown-menu" role="document">
          <div class="dropdown-content border-0">
            <router-link :to="`/user/${id}`" class="dropdown-item has-icon" data-dismiss="modal">
              <svg class="bi menu-minsize-icon" width="1.2em" height="1.2em" fill="currentColor">
                <use xlink:href="/img/bootstrap-icons.svg#person"></use>
              </svg>
              プロフィール<i class="fas fa-angle-right"></i>
            </router-link>

            <router-link :to="'/password/change'" class="dropdown-item has-icon" data-dismiss="modal" v-if="`${this.role}` != 3">
              <svg class="bi menu-minsize-icon" width="1.2em" height="1.2em" fill="currentColor">
                <use xlink:href="/img/bootstrap-icons.svg#key"></use>
              </svg>
              パスワード変更<i class="fas fa-angle-right"></i>
            </router-link>

            <div v-for="constant in this.constants" :key="constant.key">
              <div v-if="constant.key == 'logoutUrl'">
                <a class="dropdown-item has-icon" :href="constant.value">
                  <svg class="bi menu-minsize-icon" width="1.2em" height="1.2em" fill="currentColor">
                    <use xlink:href="/img/bootstrap-icons.svg#unlock"></use>
                  </svg>
                  ログアウト<i class="fas fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export default {
  name: 'Header',
  mixins: [apiMixin, tooltipMixin],

  data() {
    return {
      constants: [],
      blogUrl: '',
      flg: true,
      host: '',
    };
  },
  beforeCreate() {},
  created() {
    this.getConstant();
  },
  beforeMount() {},
  mounted() {
    this.host = window.location.hostname;
  },
  beforeUpdate() {},
  updated() {
    this.initTooltip();
  },
  methods: {
    // 定数情報を取得
    async getConstant() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.constants = (await this.getApi('constant')) || [];

      // for (const i in this.constants) {
      // }
    },
    toggleNavbar() {
      this.$store.commit('view/setNavbar', !this.$store.state.view.navbar);
      if (!this.$store.state.view.navbar == true) {
        document.body.className = '';
      } else {
        document.body.className = 'aside-minimize';
      }
    },
    changeFlg() {
      this.flg = !this.flg;
    },
  },
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
    username() {
      return this.$store.getters['auth/username'];
    },
    role() {
      return this.$store.getters['auth/role'];
    },
  },
};
</script>

<style scoped>
/* UI統一化 */

/* ヘッダー container */
.header-container {
  padding: 0 60px 0 0;
}

/* li要素 */
.nav-item {
  border: none;
}

.nav-item .prof-btn-wrap .user-name {
  font-size: 18px;
  padding: 0 10px;
  margin: 0;
}

/* dropdown メニュー */
.item-range {
  padding: 10px 0px 10px 15px;
}

/* スマホ以下 */

@media screen and (max-width: 768px) {
  /* スマホ用 ヘッダー */
  .sp-header {
    background-color: #242424;
    height: 80px;
  }

  /* サービステキスト */
  .service-logo-txt {
    font-size: 24px;
  }
  /* スマホ用のヘッダーを囲うクラス */
  .sp-header-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  /* dropdownのコンテンツ一覧 */
  .dropdown-content {
    width: 80vw;
  }
  /* プロフィール ドロップダウン */
  .symbol {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .sp-header-container {
    display: none;
  }
}

/* パーティション用 */
.nav-item-partision {
  height: 50px;
  margin: 10px;
}
</style>

<template>
    <div>
        <dialog id="dialog-file-inventory-operation" class="dialog-file-inventory-operation">
            <!-- タイトル -->
            <div class="dialog-file-inventory-operation-title" >
                操作の編集
            </div>

            <div>
                <h4 style="color: #cc0000; padding-top: 8px; padding-left: 20px">
                    <div v-for="msg in messages" :key="msg">
                        {{ msg }}
                    </div>
                </h4>
            </div>
<!--
            <div style="height:160px;">
                【デバッグ用】呼び出し元から渡された情報：<br/>

                選択された行：{{ params.idx }}<br/>

                選択：{{ radio_period.selected }}<br/>
                選択：{{ radio_notice.selected }}<br/>
                選択：{{ radio_action.selected }}<br/>
            </div>
-->
            <div class="dialog-file-inventory-operation-window">
                <div class="row">
                    <div class="col-md-12">
                        <div class="bgc-white bd bdrs-3 p-20 mB-20">
                            <p id="table-title" hidden ></p>
                            <table id="Setting" aria-describedby="table-title" class="table table-striped table-bordered p-0 m-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">データ保持</th> 
                                        <th scope="col">事前通知</th> 
                                        <th scope="col">操作</th> 
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <!--データ保持-->
                                        <td class="thead-light pb-0">
                                            <div class="form-group">
                                                <div class="form-check mb-1" v-for="(item, idx) in radio_period.list" :key="idx" :value="item">
                                                    <label class="form-check-label">
                                                        <input type="radio" name="grp_period"
                                                            v-bind:id="'radio-period' + idx"
                                                            v-bind:value="item.value"
                                                            v-model="radio_period.selected"
                                                            v-on:click="onClickRadioPeriod(item)"
                                                        />
                                                        {{item.text}}
                                                    </label>
                                                    <!--カスタム選択時の入力枠-->
                                                    <span id="custom-input" class="pl-3" v-if="item.value === codes().period.custom" >
                                                        <input type="number" class="input-day-action"
                                                            v-bind:id="'period-custom' + idx"
                                                            v-bind:disabled="radio_period.selected !== 0"
                                                            v-model.number="item.custom_value"
                                                            v-on:keyup ="onKeyCustomPeriod('up',     item)"
                                                            v-on:change="onKeyCustomPeriod('change', item)"
                                                        />
                                                        <label class="pl-1">{{radio_period.custom_text}}</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <!--事前通知-->
                                        <td class="pb-0">
                                            <div class="form-group">
                                                <div class="form-check" v-for="(item, idx) in radio_notice.list" :key="idx" :value="item">
                                                    <label class="form-check-label">
                                                        <input type="radio" name="grp_notice"
                                                            v-bind:id="'radio-notice' + idx" 
                                                            v-bind:disabled="item.disabled"
                                                            v-bind:value="item.value"
                                                            v-model="radio_notice.selected"
                                                        />
                                                        {{item.text}}
                                                    </label>
                                                    <!--カスタム選択時の入力枠-->
                                                    <span id="custom-input" class="pl-3" v-if="item.value === 0" >
                                                        <input type="number" class="input-day-notice"
                                                            v-bind:id="'notice-custom' + idx"
                                                            v-bind:disabled="radio_notice.selected !== 0"
                                                            v-model.number="item.custom_value"
                                                        />
                                                        <label class="pl-1">{{radio_notice.custom_text}}</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <!--操作-->
                                        <td class="pb-0">
                                            <div class="form-group">
                                                <div class="form-check" v-for="(item, idx) in radio_action.list" :key="idx" :value="item">
                                                    <label class="form-check-label">
                                                        <input type="radio" name="grp_action"
                                                            v-bind:id="'radio_action' + idx"
                                                            v-bind:value="item.value"
                                                            v-model="radio_action.selected"
                                                        />
                                                        {{item.text}}
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> <!-- row -->

                <!-- コマンド -->
                <div data-e2e="dialog-file-inventory-operation-commands">
                    <p id="table-title" hidden></p>
                    <table aria-describedby="table-title" style="width:100%;">
                        <thead class="d-none">
                            <tr>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align:center;">
                                    <button type="button" id="btn_ok" class="btn btn-info btn-sm"
                                        style="margin-right:6px;"
                                        v-on:click="onClickButtonOK"
                                    >ＯＫ</button>

                                    <button type="button" id="btn_cancel" class="btn btn-light btn-sm"
                                        v-on:click="onClickButtonCancel"
                                    >キャンセル</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>  <!-- commands -->
                
            </div>  <!-- window -->
        </dialog>
    </div>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export const Types = {
    period: 1,
    notice: 2,
    action: 3,
}

export const Codes = {
    period: {
        day07: 11,
        day30: 12,
        day60: 13,
        day90: 14,
        year01: 21,
        year03: 22,
        year10: 23,
        custom: 0,
    },
    notice: {
        none:   1,
        week01: 2,
        week02: 3,
        week03: 4,
        custom: 0,

        days: {
            none: null,
            week01:  7,
            week02: 14,
            week03: 21,
        },
    },
    action: {
        delete:  1,
        move_to: 2,
    },
}

export default {
    name: 'DialogFileInventoryOperation',
    mixins: [apiMixin, tooltipMixin],

    data() {
        return {
            //呼び出し元から渡された情報
            params: {
                idx: -1,            //行番号
                action_inf: null,   //設定情報
            },//params

            messages: [],

            radio_period: {
                selected: 1,
                list: [
                    {value: Codes.period.day07,  text: '7日間',      disable_notice_default: 1, disable_notice: [Codes.notice.week01, Codes.notice.week02, Codes.notice.week03]},
                    {value: Codes.period.day30,  text: '30日間',     disable_notice_default: 1, disable_notice: []},
                    {value: Codes.period.day60,  text: '60日間',     disable_notice_default: 1, disable_notice: []},
                    {value: Codes.period.day90,  text: '90日間',     disable_notice_default: 1, disable_notice: []},
                    {value: Codes.period.year01, text: '1年間',      disable_notice_default: 1, disable_notice: []},
                    {value: Codes.period.year03, text: '3年間',      disable_notice_default: 1, disable_notice: []},
                    {value: Codes.period.year10, text: '10年間',     disable_notice_default: 1, disable_notice: []},
                    {value: Codes.period.custom, text: 'カスタム',   disable_notice_default: 1, disable_notice: [], custom_value: '', },
                ],
                custom_text: '日間',
            },
            radio_notice: {
                selected: 1,
                list: [
                    {value: Codes.notice.none,   disabled: false, text: 'なし' ,    days: Codes.notice.days.none,   },
                    {value: Codes.notice.week01, disabled: false, text: '1週間前',  days: Codes.notice.days.week01, },
                    {value: Codes.notice.week02, disabled: false, text: '2週間前',  days: Codes.notice.days.week02, },
                    {value: Codes.notice.week03, disabled: false, text: '3週間前',  days: Codes.notice.days.week03, },
                    {value: Codes.notice.custom, disabled: false, text: 'カスタム', days: Codes.notice.days.week03, custom_value: ''},
                ],
                custom_text: '日前',
            },
            radio_action: {
                selected: 1,
                list: [
                    {value: Codes.action.delete,  text: '削除'},
                    {value: Codes.action.move_to, text: '移動'},
                ],
            },
        }
    },

    methods: {
        //----------
        //内部メソッド
        //----------
        initialize: function() {
            this.messages = [];
        },
        //呼び出し元で指定された値をデフォルトとして設定する
        checkArgs: function(idx, action_inf) {
            //let clone = Vue.utils.extend({}, object); だと複製できないため、
            //let clone = JSON.parse(JSON.stringify(object)); で対応
            this.params.idx = idx;
            this.params.action_inf = JSON.parse(JSON.stringify(action_inf));

            //画面へ初期値を設定
            this.radio_period.selected = this.params.action_inf.period.value;
            this.radio_notice.selected = this.params.action_inf.notice.value;
            this.radio_action.selected = this.params.action_inf.action_value;
            this.setRadioCustom(this.radio_period, this.params.action_inf.period.custom);
            this.setRadioCustom(this.radio_notice, this.params.action_inf.notice.custom);
        },
        setRadioCustom: function(radio_inf, value) {
            let target = this.searchCustom(radio_inf.list);

            target.custom_value = '';
            if (radio_inf.selected == 0) {
                target.custom_value = value;
            }
        },
        //ラジオボタンの一覧から選択されている要素を取得する
        search: function(radio_list, selected) {
            for (let key in radio_list) {
                if (radio_list[key].value === selected) {
                    return radio_list[key];
                }
            }
            return null;
        },
        //ラジオボタンから選択されている要素を取得する
        searchSelected: function(radio_inf) {
            return this.search(radio_inf.list, radio_inf.selected);
        },
        //ラジオボタンのカスタムの要素を取得する
        searchCustom: function(radio_list) {
            return this.search(radio_list, 0);
        },
        validateCustom: function(radio_target, min, max, text) {
            //カスタム以外が選択されていたら、チェックしない
            if (radio_target.selected !== 0) {
                return;
            }
            //一覧からカスタムを探して、入力値をチェック
            let item = this.searchCustom(radio_target.list);
            if ((item.custom_value < min) || (item.custom_value > max)) {
                let area = (min >= max) ? min : (min + '～' + max);
                let msg = text + ' は' + area + '日を入力してください';
                this.messages.push(msg);
            }
        },
        //アクションまでの期間
        validatePeriod: function() {
            this.validateCustom(this.radio_period, 1, 3650, 'アクションまでの期間');
        },
        //事前通知
        validateNotice: function() {
            //アクションまでの期間:カスタム かつ 事前通知：カスタム
            if ((this.radio_period.selected == Codes.period.custom)
             && (this.radio_notice.selected == Codes.notice.custom)
            ) {
                let custom_period = this.getRadioCustomValue(this.radio_period);
                let custom_notice = this.getRadioCustomValue(this.radio_notice);

                let day_end = 21;
                if ((custom_period == '') && (custom_notice == '')) {
                    this.validateCustom(this.radio_notice, 1, day_end, '事前通知');    
                    return;
                }
                if (custom_period <= 1) {
                    this.messages.push('事前通知 は「なし」を選択してください');
                    return;
                }

                if (custom_period <= Codes.notice.days.week01) {
                    day_end = Codes.notice.days.week01 - 1;
                } else if (custom_period <= Codes.notice.days.week02) {
                    day_end = Codes.notice.days.week02 - 1;
                } else if (custom_period <= Codes.notice.days.week03) {
                    day_end = Codes.notice.days.week03 - 1;
                }
                if (day_end >= custom_period) {
                    day_end = custom_period - 1;
                }

                this.validateCustom(this.radio_notice, 1, day_end, '事前通知');
                return;
            }

            let end_day = (this.radio_period.selected == Codes.period.day07) ? 6 : 21;
            this.validateCustom(this.radio_notice, 1, end_day, '事前通知');
        },
        //入力チェック
        validation: function() {
            this.messages = [];

            //入力値チェック
            this.validatePeriod();
            this.validateNotice();

            //結果返却
            return (this.messages.length < 1);
        },
        //カスタム項目の入力値を取得する
        getRadioCustomValue: function(radio_inf) {
            let result = -1;
            if (radio_inf.selected == 0) {
                result = this.searchCustom(radio_inf.list).custom_value;
            }
            return result;
        },
        //画面項目の活性／非活性
        disabledEnabled: function(selected) {
            //this.radio_period.selected はイベントハンドラの終了後に値が設定される
            //（this.radio_period.selected は前回の選択結果のままになる）
            //このため、イベントの引数（selected.value）を使って処理する

            //アクションまでの期間から選択されている要素を取得する
            //※show() から呼ばれた場合
            if (selected == null) {
                selected = this.searchSelected(this.radio_period);
            }
            // let prefix = 'DialogFileInventoryOperation>>disabledEnabled>> '; 
            // console.log(prefix + 'radio_period.selected:' + this.radio_period.selected);
            // console.log(prefix + 'value:' + selected.value);
            // console.log(prefix + 'custom_value[' + selected.custom_value + ']');

            //※選択されている事前通知が 非活性の一覧にある場合は、デフォルト値を設定する
            for (let key in this.radio_notice.list) {
                let item = this.radio_notice.list[key];
                item.disabled = (selected.disable_notice.indexOf(item.value) > -1);
            }
            if (selected.disable_notice.indexOf(this.radio_notice.selected) > -1) {
                this.radio_notice.selected = selected.disable_notice_default;
            }
        },
        //事前通知の活性／非活性
        disabledEnabledCustom(call, selected_period) {
            //this.radio_period.selected はイベントハンドラの終了後に値が設定される
            //（this.radio_period.selected は前回の選択結果のままになる）
            //このため、イベントの引数（selected.value）を使って処理する

            //アクションまでの期間から選択されている要素を取得する
            //※show() から呼ばれた場合
            if (selected_period == null) {
                selected_period = this.searchSelected(this.radio_period);
            }
            // let prefix = 'DialogFileInventoryOperation>>disabledEnabledCustom>> ';
            // console.log(prefix + 'call:' + call);
            // console.log(prefix + 'radio_period.selected:' + this.radio_period.selected);
            // console.log(prefix + 'value:' + selected_period.value);
            // console.log(prefix + 'radio_period.custom_value[' + selected_period.custom_value + ']');

            //アクションまでの期間 がカスタム以外はやらない
            if (selected_period.value != Codes.period.custom) {
                return;
            }

            //一旦全部、活性
            for (let key in this.radio_notice.list) {
                this.radio_notice.list[key].disabled = false;
            }

            //対象のみ非活性
            let target = [];
            try {
                //未入力
                if ((typeof selected_period.custom_value == 'undefined')
                 || (selected_period.custom_value == '')
                ) {
                    target.push(this.search(this.radio_notice.list, Codes.notice.week01));
                    target.push(this.search(this.radio_notice.list, Codes.notice.week02));
                    target.push(this.search(this.radio_notice.list, Codes.notice.week03));
                    return;
                }
                //1週間 → 非活性：1～3週間
                if (selected_period.custom_value <= Codes.notice.days.week01) {
                    target.push(this.search(this.radio_notice.list, Codes.notice.week01));
                    target.push(this.search(this.radio_notice.list, Codes.notice.week02));
                    target.push(this.search(this.radio_notice.list, Codes.notice.week03));
                    return;
                }
                //2週間 → 非活性：2～3週間
                if (selected_period.custom_value <= Codes.notice.days.week02) {
                    target.push(this.search(this.radio_notice.list, Codes.notice.week02));
                    target.push(this.search(this.radio_notice.list, Codes.notice.week03));
                    return;
                }
                //3週間 → 非活性：3週間
                if (selected_period.custom_value <= Codes.notice.days.week03) {
                    target.push(this.search(this.radio_notice.list, Codes.notice.week03));
                    return;
                }

            } finally {
                let isChangeSelected = false;
                for (let key in target) {
                    target[key].disabled = true;
                    if (target[key].value == this.radio_notice.selected) {
                        isChangeSelected = true;
                    }
                }
                if (isChangeSelected) {
                    this.radio_notice.selected = 1;
                }
            }
        },

/*
        //日付計算
        formatDate: function(date){
            let result = date.toLocaleDateString();
            console.log('DialogFileInventoryOperation>>formatDate result:' + result);
            return result;
        },
        calcPeriodDate: function() {
            let dt_now = new Date();

            switch(this.radio_period.selected) {
            case 1: dt_now.setDate(dt_now.getDate()   -  7); break;        //7日前
            case 2: dt_now.setMonth(dt_now.getMonth() -  1); break;        //1ヶ月
            case 3: dt_now.setMonth(dt_now.getMonth() -  2); break;        //2ヶ月
            case 4: dt_now.setMonth(dt_now.getMonth() -  6); break;        //6ヶ月
            case 5: dt_now.setMonth(dt_now.getMonth() - 12); break;        //1年
            case 6: dt_now.setMonth(dt_now.getMonth() - (12 *  3)); break; //3年
            case 7: dt_now.setMonth(dt_now.getMonth() - (12 * 10)); break; //10年
            case 0: {                                                               //カスタム
                    let day = this.searchCustom(this.radio_period.list).custom_value;
                    dt_now.setDate(dt_now.getDate() - day);
                    break;
                }
            }
            return this.formatDate(dt_now);
        },
        calcNoticeDate: function() {
            let dt_now = new Date();

            switch(this.radio_notice.selected) {
            case 1: return '';                                                  //なし
            case 2: dt_now.setDate(dt_now.getDate() -  7     ); break; //1週間
            case 3: dt_now.setDate(dt_now.getDate() - (7 * 2)); break; //2週間
            case 4: dt_now.setDate(dt_now.getDate() - (7 * 3)); break; //3週間
            case 0: {
                    let day = this.searchCustom(this.radio_notice.list).custom_value;
                    dt_now.setDate(dt_now.getDate() - day);
                    break;
                }
            }
            return this.formatDate(dt_now);
        },
*/
        //ダイアログの結果をデータ構造へ設定する
        createResult: function() {
            let result = JSON.parse(JSON.stringify(this.params.action_inf));

            //アクションまでの期間
            result.period.value  = this.radio_period.selected;
            result.period.custom = this.getRadioCustomValue(this.radio_period);
            if (this.radio_period.selected == 0) {
                result.period.text = this.getRadioCustomValue(this.radio_period) + this.radio_period.custom_text;
            } else {
                // result.period.text = this.calcPeriodDate();
                result.period.text = this.searchSelected(this.radio_period).text;
            }
            //事前通知
            result.notice.value  = this.radio_notice.selected;
            result.notice.custom = this.getRadioCustomValue(this.radio_notice);
            if (this.radio_notice.selected == 0) {
                result.notice.text = this.getRadioCustomValue(this.radio_notice) + this.radio_notice.custom_text;
            } else {
                // result.notice.text = this.calcNoticeDate();
                result.notice.text = this.searchSelected(this.radio_notice).text;
            }
            //アクション
            result.action_value     = this.radio_action.selected;
            //ボタン「選択」活性／非活性
            result.disabled.move_to = (this.radio_action.selected == Codes.action.delete);

            //結果返却
            return result;
        },
        //----------
        //イベントハンドラ
        //----------
        //ラジオボタン『アクションまでの期間』
        onClickRadioPeriod: function(item) {
            this.disabledEnabled(item);
            this.disabledEnabledCustom('onClickRadioPeriod', item);
        },
        //「事前通知／カスタム」キー入力チェック
        onKeyCustomPeriod: function(call, item) {
            this.disabledEnabledCustom(call, item);
        },
        //ボタン『キャンセル』
        onClickButtonCancel: function() {
            this.close();
        },
        //ボタン『ＯＫ』
        onClickButtonOK: function() {
            if (!this.validation()) {
                return;
            }
            //呼び出し元へ結果を返す
            let result = this.createResult();
            this.$emit('onSelected', this.params.idx, result);
            this.close();
        },
        //----------
        //外部I/F
        //----------
        types: function() {
            return Types;
        },
        //定数値：コード取得
        codes: function() {
            return Codes;
        },
        //ラジオボタンの値からテキストを返却
        getTextFromValue: function(type, value) {
            let result = null;

            switch(type) {
            case Types.period: result = this.search(this.radio_period.list, value); break;
            case Types.notice: result = this.search(this.radio_notice.list, value); break;
            case Types.action: result = this.search(this.radio_action.list, value); break;
            }
            return result.text;
        },
        //ダイアログ画面を閉じる
        close: function() {
            document.querySelector('#dialog-file-inventory-operation').close();
        },
        //ダイアログ画面を表示する
        show: function(idx, action_inf) {
            //自分自身を初期化
            this.initialize();
            //パラメータチェック
            this.checkArgs(idx, action_inf);
            //画面項目の活性／非活性
            this.disabledEnabled(null, null);
            this.disabledEnabledCustom('show', null);
            //ダイアログ表示
            document.querySelector('#dialog-file-inventory-operation').showModal();
        },
    }
}
</script>

<style>
/* ダイアログの外観：丸くする */
.dialog-file-inventory-operation {
  padding: 0;
  padding-bottom: 10px;
  border: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 1em black;
  width: 710px;
}
/* ダイアログの背景：半透明のブラック */
.dialog-file-inventory-operation::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}
/* ダイアログのタイトル */
.dialog-file-inventory-operation-title {
    /* color: white; */
    font-weight: bold;
    padding: 10px;
}
/* ダイアログの表示領域 */
.dialog-file-inventory-operation-window {
    padding-left:  20px;
    padding-right: 20px;
}
/* コマンド（ボタン） */
.dialog-file-inventory-operation-commands {
    /* padding-top: 15px; */
    width: 100%;
}

.input-day-action {
    padding-left: 3px;
    width: 70px;
}
.input-day-notice {
    padding-left: 3px;
    width: 50px;
}
</style>
<template>
    <div class="col-lg-auto ml-xl-auto d-flex m-0 p-0">
        <a
            data-e2e="update-process-status"
            class="btn btn-info btn-sm "
            role="button"
            v-on:click="onClickButtonUpdate()"
        >画面を更新する
        </a>
    </div>
</template>

<script>
export default {
    methods: {
        //ボタン『画面を更新する』
         onClickButtonUpdate: function() {
            // console.log('OperationLog>>onClickButtonUpdate');
            this.redirectSelf();
        },
        redirectSelf: function() {
            this.$router.go({path: this.$router.currentRoute.path, force: true});
        }
    },
};
</script>
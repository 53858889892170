<template>
  <main class="main-content content bgc-grey-200">
    <!-- ログインチェック -->
    <component-login-checker
      ref="loginChecker"
    />
    <component-login-checker-local
      ref="loginCheckerForLocal"
    />
    <div class="d-flex flex-row bd-highlight mb-3 align-items-center">
      <div class="p-2 bd-highlight">
        <img class="pt-4 pb-4 pr-4 img-fluid" :src="apps.logo_url" :alt="apps.name" />
      </div>
      <div class="p-2 bd-highlight"><h4 class="c-grey-900 mt-3" data-e2e="page_title_name">オプション機能選択</h4></div>
    </div>

    <div class="flex-row bd-highlight mb-3 card-deck" data-e2e="releasenotes_list_block">
        <!-- 各ボタン -->
        <a
          v-for="item in apps.items"
          :key="item.application_option_id"
          :class="item.color"
          class="card-content card"
          v-bind:href="item.url"
          :target="item.is_extarnal_site ? '_blank' : false"
          data-toggle="tooltip"
          data-placement="right"
          data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
          :title="item.tooltip"
        >
          <h3>{{ item.title }}</h3>
          <br />
          <p class="text-body" v-html="item.description"></p>
        </a>
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

import LoginChecker         from '@/pages/box/common/LoginChecker.vue';
import LoginCheckerForLocal from '@/pages/box/common/LoginCheckerForLocal.vue';

export default {
  name: 'BoxOptionMenu',
  mixins: [apiMixin, tooltipMixin],

  components: {
    'component-login-checker': LoginChecker,
    'component-login-checker-local': LoginCheckerForLocal,
  },

  data() {
    return {
      apps: [],
    };
  },

  mounted() {
    var code = this.$route.query.value;
    this.checkLogin(code);
    this.getApps();
  },
  updated() {
    this.initTooltip();
  },
  methods: {
    checkLogin: async function(code) {
      // console.log('AccessList>>checkLogin');
      this.isLocal = await this.getApi(`box/isLocal`);

      if (this.isLocal) {
        // console.log('AccessList>>isLocal: local');
        this.$refs.loginCheckerForLocal.createSessionFromDB(code);
      } else {
        // console.log('AccessList>>isLocal: other');
        this.$refs.loginChecker.createSessionFromDB(code);
      }
    },
    // 表示するクラウドサービス情報を取得
    async getApps() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.apps = (await this.getApi(`box/OptionMenu`)) || [];
      if (this.apps.length === 0) {
        this.$router.push({ path: '/404' });
      }
    },
  },
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
    url_name() {
      return this.$route.params.url_name;
    },
  },
};
</script>

<style scoped>
/* カード */

.card {
  color: #2c8fff;
  border-radius: 25px;
  border-color: #2c8fff;
}

.card-content {
  min-width: 460px;
  max-width: 460px;
  min-height: 123px;
}

.content-top {
  /* padding-top: 40px; */
}

.card-padding {
  padding: 10px;
}

.text-body {
  font-size: 1.1rem;
}

p {
  margin: 0;
  line-height: 1.3rem;
}

.traning {
  background-color: #2c8fff;
  color: white;
}
</style>

<template>
  <main class="main-content content bgc-grey-200">
    <div id="mainContent">
      <div class="container-fluid">
        <div>
          <h4 class="c-grey-900 mt-3" data-e2e="page_title_name">ユーザー設定</h4>
        </div>

        <!-- 現在の登録状況 -->
        <div class="card border-0 m-0 mb-3 mb-30px">
          <div class="card-body">
            <div class="row align-items-start align-items-lg-stretch flex-lg-nowrap">
              <div class="col-12 col-lg-auto">
                <h3 class="decoration text-16px text-md-20px font-weight-bold my-5px my-md-0px mt-md-10px">
                  現在の登録状況
                </h3>
              </div>
              <div class="col-12 d-lg-none p-0">
                <hr />
              </div>
              <div
                v-if="roleCnt"
                class="col-12 col-lg-auto flex-lg-shrink-1 d-flex flex-column d-md-block align-items-center"
              >
                <span class="d-inline-block my-1 mr-md-3 px-4 py-2 rounded-pill bg-light"
                  >オーナー：<strong class="h5 font-weight-bold text-primary">{{ roleCnt.owner }}人</strong></span
                >
                <span class="d-inline-block my-1 mr-md-3 px-4 py-2 rounded-pill bg-light"
                  >管理者：<strong class="h5 font-weight-bold text-primary">{{ roleCnt.admin }}人</strong></span
                >
                <span class="d-inline-block my-1 mr-md-3 px-4 py-2 rounded-pill bg-light"
                  >一般ユーザー：<strong class="h5 font-weight-bold text-primary">{{ roleCnt.general }}人</strong></span
                >
              </div>
              <div class="col-12 col-lg-auto ml-xl-auto d-flex align-items-center">
                <router-link
                  data-e2e="user_add"
                  class="btn btn-info btn-icon-left w-100 w-lg-auto px-4 btn-sm add-btn-txt"
                  data-toggle="modal"
                  data-target="#adduserModal"
                  to="/user/register"
                  role="button"
                >
                  <svg class="bi" fill="currentColor">
                    <use xlink:href="/img/bootstrap-icons.svg#person-plus" />
                  </svg>
                  ユーザーを追加する
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- データテーブル -->
        <div class="row">
          <div class="col-md-12">
            <div class="bgc-white bd bdrs-3 p-20 mB-20">
              <table
                id="dataTable"
                class="table table-striped table-bordered table-hover text-break"
                aria-hidden="true"
              >
                <thead>
                  <tr class="thead-light">
                    <th scope="col">名前</th>
                    <th scope="col">メールアドレス</th>
                    <th scope="col">権限</th>
                    <th scope="col">登録・編集の反映状況</th>
                    <th scope="col">編集</th>
                    <th scope="col" style="font-size: 0.7rem">パスワード</th>
                    <th scope="col">削除</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="user in users" :key="user.id" data-e2e="user_list">
                    <!-- 名前 -->
                    <td>{{ user.name }}</td>
                    <!-- メアド -->
                    <td>{{ user.email }}</td>
                    <!-- 権限 -->
                    <td>{{ user.role }}</td>
                    <!-- 登録・編集の反映状況 -->
                    <td>{{ user.status }}</td>

                    <!-- 編集 オーナー権限 -->
                    <td v-if="role === 1 && user.role === 'オーナー'"></td>
                    <td v-else-if="role === 1 && user.role === '管理者'">
                      <router-link
                        :to="`/user/${user.id}/edit`"
                        class="td-n c-deep-purple-500 cH-blue-500 fsz-md"
                        data-e2e="user_edit4"
                      >
                        <svg class="bi" width="26" height="26" fill="currentColor">
                          <use xlink:href="/img/bootstrap-icons.svg#pencil" />
                        </svg>
                      </router-link>
                    </td>

                    <!-- 編集 管理者権限 -->
                    <td
                      v-else-if="role === 2 && (user.role === 'オーナー' || user.role === '管理者')"
                      class="none"
                    ></td>

                    <!-- 編集 -->
                    <td v-else>
                      <router-link
                        :to="`/user/${user.id}/edit`"
                        class="td-n c-deep-purple-500 cH-blue-500 fsz-md"
                        data-e2e="user_edit4"
                      >
                        <svg class="bi" width="26" height="26" fill="currentColor">
                          <use xlink:href="/img/bootstrap-icons.svg#pencil" />
                        </svg>
                      </router-link>
                    </td>

                    <!-- パスワード （オーナー||管理者)&&リストが一般ユーザーの場合 -->
                    <td v-if="(role === 1 || role === 2) && user.role === '一般ユーザー'">
                      <router-link
                        :to="`/password/otherChange/${user.id}`"
                        class="td-n c-deep-purple-500 cH-blue-500 fsz-md"
                        data-e2e="user_password"
                      >
                        <svg class="bi" width="26" height="26" fill="currentColor">
                          <use xlink:href="/img/bootstrap-icons.svg#key" />
                        </svg>
                      </router-link>
                    </td>

                    <!-- パスワード -->
                    <td v-else></td>

                    <!-- 削除 オーナー権限 -->
                    <td v-if="role === 1 && user.role === 'オーナー'"></td>
                    <td v-else-if="role === 1 && user.role === '管理者'">
                      <span
                        class="td-n c-red-500 cH-blue-500 fsz-md"
                        style="cursor: pointer"
                        data-toggle="modal"
                        :data-target="`#deleteModal${user.id}`"
                        @click="deleteControl(user.id, user.role)"
                        data-e2e="user_delete4"
                      >
                        <svg class="bi" width="26" height="26" fill="currentColor">
                          <use xlink:href="/img/bootstrap-icons.svg#trash" />
                        </svg>
                      </span>
                      <!-- Modal -->
                      <div
                        class="modal fade"
                        :id="`deleteModal${user.id}`"
                        tabindex="-1"
                        role="dialog"
                        :aria-labelledby="`deleteModalLabel${user.id}`"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" :id="`deleteModalLabel${user.id}`">ユーザー削除</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body text-left">
                              本当にユーザーを削除しますか？
                              <div class="alert alert-danger text-left" role="alert">
                                名前 : {{ user.name }}<br />
                                メールアドレス : {{ user.email }}<br />
                                権限 : {{ user.role }}
                              </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-center">
                              <button type="button" class="btn btn-outline-info btn-sm btn-width" data-dismiss="modal">
                                戻る
                              </button>
                              <button
                                v-if="!isLoading"
                                type="button"
                                class="btn btn-danger btn-sm"
                                @click="deleteUser(user.id, user.updated_at)"
                                data-e2e="user_delete_confirmation4"
                              >
                                削除する
                              </button>
                              <button v-if="isLoading" class="btn btn-danger btn-sm" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                削除中…
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <!-- 削除 管理者権限 -->
                    <td v-else-if="role === 2 && (user.role === 'オーナー' || user.role === '管理者')"></td>

                    <td v-else>
                      <span
                        class="td-n c-red-500 cH-blue-500 fsz-md"
                        style="cursor: pointer"
                        data-toggle="modal"
                        :data-target="`#deleteModal${user.id}`"
                        @click="deleteControl(user.id, user.role)"
                        data-e2e="user_delete4"
                      >
                        <svg class="bi" width="26" height="26" fill="currentColor">
                          <use xlink:href="/img/bootstrap-icons.svg#trash" />
                        </svg>
                      </span>
                      <!-- Modal -->
                      <div
                        class="modal fade"
                        :id="`deleteModal${user.id}`"
                        tabindex="-1"
                        role="dialog"
                        :aria-labelledby="`deleteModalLabel${user.id}`"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" :id="`deleteModalLabel${user.id}`">ユーザー削除</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body text-left">
                              本当にユーザーを削除しますか？
                              <div class="alert alert-danger text-left" role="alert">
                                名前 : {{ user.name }}<br />
                                メールアドレス : {{ user.email }}<br />
                                権限 : {{ user.role }}
                              </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-center">
                              <button type="button" class="btn btn-outline-info btn-sm btn-width" data-dismiss="modal">
                                戻る
                              </button>
                              <button
                                v-if="!isLoading"
                                type="button"
                                class="btn btn-danger btn-sm"
                                @click="deleteUser(user.id, user.updated_at)"
                                data-e2e="user_delete_confirmation4"
                              >
                                削除する
                              </button>
                              <button v-if="isLoading" class="btn btn-danger btn-sm" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                削除中…
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import * as $ from 'jquery';

export default {
  name: 'UserList',
  mixins: [apiMixin],
  data() {
    return {
      users: [],
      roleCnt: null,
      isLoading: false,
    };
  },
  created() {
    this.getRoleCnt();
    this.getUsers();
  },
  updated() {
  },
  methods: {
    // 表示するユーザー一覧情報を取得
    async getUsers() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.users = (await this.getApi(`users/${this.id}/list`)) || [];

      setTimeout(
        () =>
          $('#dataTable').DataTable({
            destroy: true,
            order: [],
            autowidth: false,
            oLanguage: {
              sEmptyTable: '現在ユーザーデータが登録されていません',
              sInfo: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
              // sInfo:
              //   '<div class="px-4 py-1 bg-light rounded text-center text-md-left"><span class="h5 font-weight-bold">' +
              //   '_START_-_END_</span>│全 _TOTAL_件中</div>',
              sInfoEmpty: ' 0 件中 0 から 0 まで表示',
              sInfoFiltered: '（全 _MAX_ 件より抽出）',
              sInfoPostFix: '',
              sInfoThousands: ',',
              // sLengthMenu: '_MENU_ 件表示',
              sLengthMenu:
                '<div class="form-inline mb-3"><select class="form-control"><option>10</option><option>25</option><option>50</option><option>100</option></select>' +
                '<span class="my-1 ml-2">' +
                '件表示' +
                '</span></div>',
              sLoadingRecords: '読み込み中...',
              sProcessing: '処理中...',
              // sSearch: '検索:',
              sSearch:
                '<div class="input-group js-date-search" data-e2e="list_search">' +
                '_INPUT_' +
                '<div class="input-group-append"><span class="input-group-text" ><svg class="bi" width="26" height="20" fill="currentColor"><use xlink:href="/img/bootstrap-icons.svg#search"/></svg></div></div>',
              sSearchPlaceholder: 'コンテンツ内検索',
              sZeroRecords: '登録されたユーザーが0件です。',
              oPaginate: {
                sFirst: '先頭',
                sLast: '最終',
                sNext: '次',
                sPrevious: '前',
              },
            },
            columnDefs: [
              { targets: [0], width: '20%' },
              { targets: [1], width: '25%' },
              { targets: [2], width: '20%' },
              { targets: [3], width: '20%' },
              {
                targets: [4, 5, 6],
                orderable: false,
                width: '5%',
                className: 'edit-button-col text-center',
              },
            ],
          }),
        0
      );
    },
    // 表示する各権限数を取得
    async getRoleCnt() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.roleCnt = (await this.getApi(`users/${this.id}/roles`)) || null;
    },

    // 指定したユーザーを削除
    async deleteUser(id, updateAt) {
      this.isLoading = true;
      // API共通処理（apiMixin）のDELETE用メソッドを実行
      const response = (await this.deleteApi(`users/${id}?update_at=${updateAt}`)) || false;
      this.isLoading = false;
      $(`#deleteModal${id}`).modal('hide');
      $('.modal-backdrop').remove();

      if (response.result) {
        this.$toasted.success('削除要求が完了しました');
        this.getUsers();
        this.getRoleCnt();
      } else if (response.exclusive) {
        this.$toasted.error(response.exclusive);
      } else if (response.authError) {
        this.$store.commit('auth/clearUser');
        this.$router.push({ path: '/' });
      } else {
        this.$router.push({ path: '/500' });
      }
    },

    // 削除の遷移
    async deleteControl(userId, role) {
      // 権限がオーナーの場合
      if (`${this.role}` === '1' && role === 'オーナー') {
        this.$router.push({ path: '/403' });
      } else if ((`${this.role}` === '2' && role === 'オーナー') || (`${this.role}` === '2' && role === '管理者')) {
        this.$router.push({ path: '/403' });
      }
    },
  },
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
    role() {
      return this.$store.getters['auth/role'];
    },
    companyId() {
      return this.$store.getters['auth/companyId'];
    },
  },
};
</script>

<style scoped>
.display-owner {
  display: none;
}
/* font-awesomeの色 */
.question-font {
  color: #db7093;
}

/* 追加ボタン */
.add-btn-txt {
  font-size: 16px;
}

.btn-width {
  width: 96px;
}
</style>

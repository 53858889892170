<template>
  <main class="main-content content bgc-grey-200">
    <div class="d-flex flex-row bd-highlight mb-3 align-items-center" data-e2e="logo_items">
      <div class="p-2 bd-highlight">
        <img class="pt-4 pb-4 pr-4 img-fluid" :src="logo_items['logo_url']" :alt="logo_items['app_name']" />
      </div>
      <div class="p-2 bd-highlight">
        <h4 class="c-grey-900 mt-3" data-e2e="page_title_name">コンテンツ一覧</h4>
      </div>
    </div>

    <div v-for="(list_item, index) in list_items" :key="index" class="row row-cols-auto" data-e2e="list_items">
      <div v-for="(row_item, index) in list_item['items']" :key="index" class="col">
        <!-- 大項目の表示 -->
        <div class="row row-cols-auto" data-e2e="level1_block">
          <div v-if="row_item['children'][0]['items'][0]['children'][0]['title'] !== 'none'"
            class="col p-1 m-1 subtitle text-center text-white h4 font-weight-bold">
            {{ row_item['block_name_level1'] }}
          </div>
          <div v-else class="col p-1 m-1 subtitle text-center text-white h4 font-weight-bold any-contents-block">
            {{ row_item['block_name_level1'] }}
          </div>
        </div>
        <!-- 中項目以下の表示 -->
        <div v-if="row_item['children'][0]['items'][0]['children'][0]['title'] !== 'none'" class="row row-cols-auto">
          <div v-for="(level1_children, index) in row_item['children']" :key="index" class="col">
            <div v-for="(col_item, index) in level1_children['items']" :key="index">
              <!-- 中項目の表示 -->
              <ul data-e2e="level2_block">
                <li v-if="col_item['block_name_level2']" class="h4">{{ col_item['block_name_level2'] }}</li>
                <!-- コンテンツの表示 -->
                <ul data-e2e="content_items">
                  <li v-for="(col_item_child, index) in col_item['children']" :key="index">
                    <a class="content-link" :href="col_item_child['url']"
                      :target="col_item_child['is_extarnal_site'] ? '_blank' : false" data-toggle="tooltip"
                      data-placement="right"
                      data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                      :title="col_item_child['tooltip']"
                      :data-e2e="'row-' + list_item['row'] + ',block_name_level1-' + row_item['block_name_level1'] + ',col-' + level1_children['col'] + ',block_name_level2-' + col_item['block_name_level2'] + ',title-' + col_item_child['title']">
                      {{ col_item_child['title'] }}
                      <em v-if="col_item_child['is_extarnal_site']" class="ti-link"></em>
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
        <div v-else>
          <h6 class="any-contents"
            :data-e2e="'row-' + list_item['row'] + ',block_name_level1-' + row_item['block_name_level1'] + ',col-' + row_item['children'][0]['col'] + ',block_name_level2-,title-' + row_item['children'][0]['items'][0]['children'][0]['title']">
            {{ row_item['block_name_level1'] }}はありません</h6>
        </div>
      </div>
    </div>
    <div class="row justify-content-end" data-e2e="button_items">
      <a v-for="(button_title, index) in button_items[0]" :key="index"
        :class="(button_title['url'] == 'none' ? 'disabled ' : '') + 'btn btn-info m-2 btn-sm w-13'"
        v-html="button_title['title']" :href="button_title['url']"
        :target="button_title['is_extarnal_site'] ? '_blank' : false" data-toggle="tooltip" data-placement="right"
        data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        :title="button_title['tooltip']">
      </a>
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export default {
  name: 'ContentsList',
  mixins: [apiMixin, tooltipMixin],
  data() {
    return {
      apps: [],
      list_items: [],
      button_items: [],
      logo_items: [],
    };
  },
  beforeCreate() { },
  created() {
    this.initialization();
  },
  beforeMount() { },
  mounted() { },
  beforeUpdate() { },
  updated() {
    this.initTooltip();
  },
  methods: {
    async initialization() {
      await this.getApps();
      this.separateItems(this.apps);
    },
    // 表示するアプリ情報を取得
    async getApps() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.apps = (await this.getApi(`users/${this.id}/apps?group=master&app_name=${this.url_name}&request_from=contentsList`)) || [];
      if (this.apps.length === 0) {
        this.$router.push({ path: '/404' });
      }
    },
    // contents_itemsからボタン、ロゴ関係の情報を分離
    async separateItems(items) {
      this.list_items = items[0];
      this.button_items = items[1];
      this.logo_items = items[2];
    },
  },
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
    url_name() {
      return this.$route.params.url_name;
    },
  },
};
</script>

<style scoped>
/* カード */

.card {
  color: #2c8fff;
  border-radius: 25px;
  border-color: #2c8fff;
}

.card-content {
  min-width: 460px;
  max-width: 460px;
  min-height: 123px;
}

.content-top {
  /* padding-top: 40px; */
}

.card-padding {
  padding: 10px;
}

.text-body {
  font-size: 1.1rem;
}

p {
  margin: 0;
  line-height: 1.3rem;
}

.subtitle {
  background-color: #2c8fff;
  color: white;
}

.w-13 {
  width: 13% !important
}


/* PBI2023011708関係 */
.any-contents1 {
  padding: 0 0 0 80px;
}

.any-contents-block {
  background-color: #3e4450;
}

.any-contents {
  text-align: center;
}

.content-link {
  color: #2c8fff;
}
</style>

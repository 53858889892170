<template>
  <div>
    <div class="d-flex flex-column justify-content-center align-items-center top-container">
      <!-- ロゴ -->
      <img class="signin-logo" src="/images/symphonict-logo-bk-y.png" alt="" />
      <!-- <img class="w-160px w-md-200px mw-100 mx-auto mb-10px" src="../../public/img/logo_vertical_black.svg" alt="symphonict"> -->
      <h2 class="c-grey-900 font-weight-bold">サービスポータル</h2>

      <!-- ログインボタン -->
      <!-- ローカル用 ログインボタン -->
      <div v-if="this.host == 'localhost'">
        <!-- router-link class="btn btn-primary login-btn" to="/login" role="button">ログイン</router-link -->
        <a class="btn btn-info login-btn" href="http://localhost:8000/login" data-e2e="login" role="button">ログイン</a>
      </div>

      <!-- 本番用 ログインボタン -->
      <div v-if="this.host != 'localhost'">
        <div v-for="constant in this.constants" :key="constant.key">
          <div v-if="constant.key == 'loginUrl'">
            <a class="btn btn-info login-btn" :href="constant.value" data-e2e="login" role="button" target="_self">ログイン</a>
          </div>
        </div>
      </div>

      <!-- リンク -->
      <router-link to="/password/recovery" data-e2e="password-recovery" class="pass-forger">パスワードを忘れた方はこちら ＞</router-link>
    </div>

    <!-- フッター -->
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import { apiMixin } from '@/mixins/api.js';

export default {
  name: 'Top',
  mixins: [apiMixin],
  components: {
    Footer,
  },
  data() {
    return {
      constants: [],
      host: '',
    };
  },
  beforeCreate() {
    /* GA4 Googleアナリティクス 2021/10 */
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    // 基本値のセット
    var GtagSetArray = {};

    // 内部アクセス切り替え
    if (window.location.hostname == 'portal-stag.symphonict.com') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_stag';
    } else if (window.location.hostname == 'portal-dev.symphonict.com') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_dev';
    } else if (window.location.hostname == 'localhost') {
      GtagSetArray.debug_mode = true;
      GtagSetArray.traffic_type = 'internal_local';
    }

    // 送信
    gtag('config', 'G-Y9CV60BH7R', GtagSetArray);
    /* end GA4 Googleアナリティクス 2021/10 */
  },
  created() {
    this.getConstant();
  },
  beforeMount() {},
  mounted() {
    this.host = window.location.hostname;
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    // 定数情報を取得
    async getConstant() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.constants = (await this.getApi('constant')) || [];
    },
  },
  computed: {},
};
</script>

<style scoped>
/* ログインボタン */

.login-btn {
  width: 300px;
  margin-top: 20px;
}
/* ロゴ */

.signin-logo {
  width: 600px;
  height: auto;
}
/* 画面全体 */

.top-container {
  width: 100%;
  min-height: calc(100vh - 150px);
}
/* パスワードの変更はこちら */

.pass-forger {
  color: gray;
  margin-top: 20px;
}
/* フッター */

.footer-area {
  min-height: 20vh;
  width: 100%;
  background-color: black;
}
/* フッター画像 */

.footer-img {
  width: 40%;
}

.copy-right {
  color: white;
}
/* タブレット以下 */

@media screen and (max-width: 768px) {
  /* ログインボタン  */
  .login-btn {
    width: 80%;
  }
  /* ロゴ */
  .signin-logo {
    width: 50%;
    height: auto;
  }
  .copy-right {
    margin: 0 15px;
    word-break: break-all;
    margin-bottom: 10px;
  }
}
</style>
